import React from "react";
import Parallax from "../Parallax";

import Source from "./Source";

import "./style.css";

export default function Illustration() {
    const ua = window.navigator.userAgent;
    console.log(ua, ua.includes("Edg"), ua.includes("Trident"), ua.includes("Edg") || ua.includes("Trident"));

    console.log(`illustration ${ua.includes("Edg") || ua.includes("Trident") ? "" : "notIE"}`);

    return (
        <Parallax
            className={`illustrationContainer ${ua.includes("Edg") || ua.includes("Trident") ? "" : "notIE"}`}
            speed="-4"
        >
            <Source />
        </Parallax>
    );
}
