import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "./style.css";

export default class AnimateExample extends Component {
    state = {
        animate: false,
        latlng: {
            lat: 45.991385,
            lng: 2.97209
        },
        marker: [45.991385, 2.97209]
    };

    handleClick = e => {
        this.setState({
            latlng: e.latlng
        });
    };

    render() {
        return (
            <div className="Location" style={{ textAlign: "center", width: `${window.innerWidth}px` }}>
                <Map
                    animate={true}
                    center={this.state.latlng}
                    length={4}
                    onClick={this.handleClick}
                    zoom={14}
                    maxZoom={16}
                    minZoom={10}
                    style={{ height: "500px" }}
                    maxBounds={[
                        [45.491385, 2.47209],
                        [46.491385, 3.47209]
                    ]}
                >
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                    />
                    <Marker position={this.state.marker}>
                        <Popup>Le moulin des Desniers</Popup>
                    </Marker>
                </Map>
            </div>
        );
    }
}
