import React from "react";
import { withFirebase } from "../Firebase";

const withTextLoader = Component => {
    class WithTextLoader extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                text: false
            };
        }

        componentDidMount() {
            this.loadText
                .then(text => {
                    this.setState({ text: text });
                })
                .catch(error => console.log(error));
        }

        // asyncForEach //////////////////////////////////////////////////////////////////////////////////////////
        //ForEach asynchrone pour attendre que chaque callback soit résolu
        //On lui donne un tableau et une fonction à executer pour chaque élément du tableau
        ForEach = function(array, callback) {
            return new Promise((resolveAll, rejectAll) => {
                const promises = [];

                for (let index = 0; index < array.length; index++) {
                    promises.push(
                        new Promise((resolve, reject) => callback(array[index], index, array, resolve, reject))
                    );
                }

                Promise.all(promises)
                    .then(results => resolveAll(results))
                    .catch(error => rejectAll(error));
            });
        };

        /////////////////////////////////////////////////////////////////////////////////////////
        //Load Text

        //Récupération du texte via la base de données firebase
        loadText = new Promise((resolveLoad, rejectLoad) => {
            //On accède à la collection "textes"
            this.props.firebase.db
                .collection("textes")
                .get()
                .then(({ docs: pages }) => {
                    //Pour chaque page dans la collection "textes"...
                    this.ForEach(pages, (page, index, pages, resolve, reject) => {
                        this.props.firebase.db
                            .collection("textes")
                            .doc(page.id)
                            .collection("textes")
                            .get()
                            .then(({ docs }) => {
                                resolve(docs);
                            })
                            .catch(function(error) {
                                rejectLoad(error);
                            });
                    })
                        .then(docsTable => {
                            const text = {};

                            docsTable.forEach((docs, index, table) => {
                                text[pages[index].id] = {};

                                docs.forEach(doc => {
                                    text[pages[index].id][doc.id] = doc.data().texte;
                                });
                            });

                            resolveLoad({ ...text });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        });

        /////////////////////////////////////////////////////////////////////////////////////////
        //Render

        render() {
            return <Component {...this.props} text={this.state.text} />;
        }
    }

    return withFirebase(WithTextLoader);
};

export default withTextLoader;
