import React, { Component } from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import ImagesTable from "../ImagesTable";
import Loader from "../Loader";

import "./style.css";

class Informations extends Component {
    render() {
        return (
            <React.Fragment>
                <Loader />
                <header>
                    <Nav />
                </header>
                <div className="galerieContainer">
                    <ImagesTable min={3} uid="galerie" uploader={this.props.uploader} className="galerie" />
                </div>

                <Footer />
            </React.Fragment>
        );
    }
}

export default Informations;
