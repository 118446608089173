import React, { Component } from "react";

class EditableNonAuth extends Component {
    render() {
        //La référence du tagname doit être en majuscules pour être traitée comme une variable
        const TagName = this.props.tagName;

        if (typeof TagName === "string") {
            return (
                <TagName className={this.props.className ? this.props.className : ""}>
                    {this.props.currentText &&
                        this.props.currentText.split("\n").map(function(item, key, array) {
                            return (
                                <span key={key}>
                                    {item}
                                    {array.length > 1 && <br />}
                                </span>
                            );
                        })}
                </TagName>
            );
        } else {
            return (
                <TagName {...this.props} className={this.props.className ? this.props.className : ""}>
                    {this.props.currentText &&
                        this.props.currentText.split("\n").map(function(item, key, array) {
                            return (
                                <span key={key}>
                                    {item}
                                    {array.length > 1 && <br />}
                                </span>
                            );
                        })}
                </TagName>
            );
        }
    }
}

export default EditableNonAuth;
