import React, { Component } from "react";
import missingImage from "../../assets/illustrations/missingImage.jpg";
import move from "../../assets/icons/move.svg";
import crop from "../../assets/icons/crop.svg";
import remove from "../../assets/icons/bin.svg";
import picture from "../../assets/icons/picture.svg";
import "./style.css";

class FramedImageNonAuth extends Component {
    icons = {
        move: { backgroundImage: `url(${move})` },
        crop: { backgroundImage: `url(${crop})` },
        picture: { backgroundImage: `url(${picture})` },
        remove: { backgroundImage: `url(${remove})` }
    };

    state = {
        action: "none",
        frameRef: React.createRef(),
        imageRef: React.createRef(),
        width: 0,
        height: 0
    };

    componentDidMount() {
        this.props.sizing();
    }

    // RENDER ///////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div
                className="Frame"
                ref={this.props.frameReactRef}
                style={{ order: this.props.order }}
            >
                <div
                    ref={this.props.framedImageReactRef}
                    className="FramedImage"
                    style={{ left: this.props.left, top: this.props.top }}
                >
                    <img
                        onLoad={this.props.sizing}
                        style={{
                            left: `${this.props.offsetX}px`,
                            top: `${this.props.offsetY}px`,
                            width: this.props.width,
                            height: this.props.height
                        }}
                        ref={this.props.imageReactRef}
                        src={
                            this.props.downloadURL
                                ? this.props.downloadURL
                                : missingImage
                        }
                        alt="moulin"
                        draggable={false}
                    />
                </div>
            </div>
        );
    }
}

export default FramedImageNonAuth;
