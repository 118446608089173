import React from "react";

import imageCompression from "browser-image-compression";

import missingImage from "../../assets/illustrations/missingImage.jpg";
import BubbleButton from "../BubbleButton";
import more from "../../assets/icons/more.svg";
import picture from "../../assets/icons/picture.svg";
import remove from "../../assets/icons/bin.svg";
import { withFirebase } from "../Firebase";

import "./Uploader.css";

//////////////////////////////////////////////////////////////////////////////////////////
const withUploader = Component => {
    class Uploader extends React.Component {
        _isMounted = false;

        style = {
            picture: { backgroundImage: `url(${picture})` },
            bin: { backgroundImage: `url(${remove})` }
        };

        //Référence du document Firestore
        FILES = this.props.firebase.db.collection("files");

        STORAGE = this.props.firebase.storage();

        OPTIONS = {
            maxSizeMB: 0.25,
            maxWidthOrHeight: 512,
            useWebWorker: true
        };

        state = {
            files: [],
            callback: null,
            displayed: false,
            authUser: null
        };
        // COMPENENT DID & WILL MOUNT ////////////////////////////////////////////////////////////////////////

        componentDidMount() {
            this._isMounted = true;
            this.loadFiles();
        }

        componentWillUnmount() {
            this.setState({ callback: null, displayed: false });
            this._isMounted = false;
        }

        // OPEN UPLOADER ///////////////////////////////////////////////////////////////////
        //Enregistre quelle image est en train d'utiliser l'uploader
        uploading(callback) {
            console.log(callback, this.state.callback, this.state.displayed);
            this.state.callback === null
                ? this.setState({ callback: callback, displayed: true })
                : console.log("Erreur, la source d'une autre image est en cours de modifications");
        }

        //getAuthUser
        getAuthUser(authUser) {
            if (this.state.authUser !== authUser) this.setState({ authUser });
        }

        // CHOOSE PICTURE
        choosePicture(fileRef) {
            this.state.callback(fileRef);
            this.setState({ callback: null, displayed: false });
        }

        //BACK
        back() {
            this.state.callback(null);
            this.setState({ callback: null, displayed: false });
        }

        // LOAD TEXT OR CREATE DOCUMENT //////////////////////////////////////////////////////////////////////////////////////////

        async loadFiles() {
            const _this = this;

            await this.FILES.orderBy("date", "desc")
                .get()
                .then(snapshot => {
                    let filesTable = [];

                    //Creation du premier tableau d'images
                    snapshot.forEach(function(doc) {
                        if (doc.exists) {
                            filesTable.push({
                                fileRef: doc.id,
                                extension: doc.data().extension,
                                downloadURL: doc.data().downloadURL
                            });
                        } else {
                            console.log("table introuvable");
                        }
                    });

                    _this.setState({ files: filesTable });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });
        }

        // asyncForEach //////////////////////////////////////////////////////////////////////////////////////////
        async asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }

        // UPLOAD FILE //////////////////////////////////////////////////////////////////////////////////////////

        async upload(files) {
            const _this = this;

            //POUR CHAQUE FICHIER UPLOADé
            await this.asyncForEach([...files], async file => {
                const extension = file.type.replace("image/", "");

                try {
                    const compressedFile = await imageCompression(file, this.OPTIONS);

                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                    //ON AJOUTE UN DOCUMENT A LA COLLECTION FILES DANS LA BDD
                    await _this.props.firebase.db
                        .collection("files")
                        .add({
                            date: new Date(),
                            extension: extension
                        })
                        .then(function(doc) {
                            const fileName = `${doc.id}.${extension}`;

                            //ON UPLOAD LE FICHIER AVEC LE NOM DU DOCUMENT NOUVELLEMENT CREE (clé unique)
                            _this.STORAGE.ref()
                                .child(fileName)
                                .put(compressedFile)
                                .then(function(snapshot) {
                                    //ON RECUPERE L'URL DE TELECHARGEMENT DU FICHIER NOUVELLEMENT UPLOADE
                                    snapshot.ref.getDownloadURL().then(function(downloadURL) {
                                        //ON ENVOIE CET URL AU DOCUMENT NOUVELLEMENT CREE DANS LA COLLECTION FILES
                                        _this.props.firebase.db
                                            .collection("files")
                                            .doc(doc.id)
                                            .update({
                                                downloadURL: downloadURL
                                            })
                                            .then(function(snapshot) {
                                                const filesTable = _this.state.files;

                                                filesTable.unshift({
                                                    fileRef: doc.id,
                                                    extension: extension,
                                                    downloadURL: downloadURL
                                                });

                                                _this.setState({
                                                    files: filesTable
                                                });
                                            });
                                    });
                                });
                        })
                        .catch(function(error) {
                            console.error("Error adding document: ", error);
                        });
                } catch (error) {
                    console.log(error);
                }
            });
        }

        // DELETE FILE //////////////////////////////////////////////////////////////////////////////////////////

        deleteFile(index) {
            const _this = this;
            const files = [...this.state.files];
            const file = files[index];
            const fileRef = file.fileRef;
            const fileName = `${fileRef}.${file.extension}`;

            this.STORAGE.ref()
                .child(fileName)
                .delete()
                .then(function() {
                    console.log(`Fichier ${fileName} supprimé`);

                    _this.FILES.doc(fileRef)
                        .delete()
                        .then(function() {
                            const newFilesArray = files.filter(function(file, index, files) {
                                return file.fileRef !== fileRef;
                            });

                            _this.setState({ files: newFilesArray });

                            console.log(`Référence ${fileRef} du fichier ${fileName} supprimée avec succès`);
                        })
                        .catch(function(error) {
                            console.log(`Impossible de supprimer la référence du fichier ${fileRef} \n`, error);
                        });
                })
                .catch(function(error) {
                    console.log(`Impossible de supprimer le fichier ${fileName} \n`, error);
                });
        }

        // RENDER //////////////////////////////////////////////////////////////////////////////////////////

        render() {
            return (
                <React.Fragment>
                    <Component
                        {...this.props}
                        uploader={{
                            uploading: this.uploading.bind(this),
                            getAuthUser: this.getAuthUser.bind(this)
                        }}
                    />
                    {this.state.authUser && (
                        <React.Fragment>
                            <div className={"uploader " + (this.state.displayed ? "displayed" : "hidden")}>
                                <div className="uploaderImagesContainer">
                                    <form className="uploaderInput">
                                        <label htmlFor="uploaderInput">
                                            <img src={more} alt="ajouter" />
                                        </label>
                                        <input
                                            onChange={e => this.upload(e.target.files)}
                                            className="inputfile"
                                            type="file"
                                            name="uploaderInput"
                                            id="uploaderInput"
                                            accept=".jpg, .jpeg, .png, .tiff, image/*"
                                            required
                                            multiple
                                        />
                                    </form>

                                    {this.state.files &&
                                        this.state.files.map(({ fileRef, downloadURL }, index) => (
                                            <div className="uploaderImage" key={index}>
                                                <img src={downloadURL ? downloadURL : missingImage} alt="upload" />
                                                <div className="uploaderImageButtonsContainer">
                                                    <button
                                                        onClick={() => this.choosePicture(fileRef)}
                                                        className="uploaderImageButton picture"
                                                    />
                                                    <button
                                                        onClick={() => this.deleteFile(index)}
                                                        className="uploaderImageButton remove"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className="uploaderButtonsContainer">
                                    <BubbleButton className="backButton" onClick={() => this.back()}>
                                        Retour
                                    </BubbleButton>
                                </div>
                            </div>
                            <div
                                className={"uploaderBackground " + (this.state.displayed ? "displayed" : "hidden")}
                                onClick={() => this.back()}
                            ></div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        }
    }
    return withFirebase(Uploader);
};
// EXPORT ET PROP TYPES /////////////////////////////////////////////////////////////////////////////////////

export default withUploader;
