import React, { Component } from "react";

import PropTypes from "prop-types";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

import SubmitCancelButtons from "../SubmitCancelButtons";
import { FramedImageAuth, FramedImageNonAuth } from "../FramedImage";

import more from "../../assets/icons/more.svg";

import minimize from "../../utils/minimize.js";

import "./ImagesTable.css";

//////////////////////////////////////////////////////////////////////////////////////////

class ImagesTable extends Component {
	_isMounted = false;

	//Référence du document Firestore
	TABLEREF = this.props.firebase.db
		.collection("images-tables")
		.doc(this.props.uid ? this.props.uid : "accueil")
		.collection("table");

	state = {
		isThereChangesInProgress: false,
		isDoingAnAction: null,
		isUploading: null,
		newTable: [],
		oldTable: [],
	};
	// COMPENENT DID & WILL MOUNT ////////////////////////////////////////////////////////////////////////

	async componentDidMount() {
		this._isMounted = true;
		window.addEventListener("resize", this.sizingAll);

		const addImageToTable = (image) => {
			const newTable = [...this.state.newTable, image];
			const oldTable = [...newTable];

			this.setState({ newTable, oldTable });
		};

		this.TABLEREF.get()
			.then((snapshot) => {
				let n = 0;
				const length = snapshot.docs.length;

				console.log("received docs", this.props.uid, snapshot.docs);

				//Creation du tableau d'images à partir de la base de données
				snapshot.docs.forEach(async (image, i) => {
					if (image.exists) {
						const data = image.data();
						let downloadURL = null;

						console.log(this.props.uid, i, data);

						//Récupération de l'URL de download de la source de l'image
						if (data.fileRef) {
							await this.props.firebase.db
								.collection("files")
								.doc(data.fileRef)
								.get()
								.then((file) => {
									if (file.data()) {
										downloadURL = file.data().downloadURL;
									} else {
										downloadURL = undefined;
									}
								})
								.catch((error) => {
									console.log(
										new Error(
											`Error getting file data :
                                        ${error}`
										)
									);
								});
						}

						console.log(this.props.uid, i, "ADD");
						addImageToTable({
							imageRef: image.id,
							fileRef: data.fileRef,
							downloadURL: downloadURL,
							extension: data.extension,
							offsetX: data.offsetX,
							offsetY: data.offsetY,
							order: data.order,
							imageReactRef: React.createRef(),
							frameReactRef: React.createRef(),
							framedImageReactRef: React.createRef(),
							width: 0,
							height: 0,
							top: 0,
							left: 0,
						});

						n++;

						if (n === length) {
							const formattedTable = this.formatTable(this.state.newTable);
							this.setState({ newTable: formattedTable, oldTable: formattedTable });
						}
					} else {
						console.log("image introuvable");
					}
				});
			})
			.catch((error) => {
				console.log(`Impossible de trouver le tableau d'image ${this.props.uid} :`, error);
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener("resize", this.sizingAll);
	}

	// Non Saved Changes ///////////////////////////////////////////////////////////////////

	//Le state "non saved changes" permet de savoir si des modifications sont en cours,
	//et ainsi afficher les bouttons "enregistrer" et "annuler"
	changesInProgress(isThere) {
		isThere !== this.state.isThereChangesInProgress && this.setState({ isThereChangesInProgress: isThere });
	}

	// DOING AN ACTION //////////////////////////////////////////////////////////////////////

	//Enregistre quelle image est en train d'effectuer une action
	doingAnAction(isDoingAnAction) {
		this.setState({ isDoingAnAction: isDoingAnAction });
	}

	// UPLOADING //////////////////////////////////////////////////////////////////////

	uploading(indexInTable) {
		if ((indexInTable && !this.state.isUploading) || (!indexInTable && this.state.isUploading))
			this.setState({ isUploading: indexInTable });
	}

	// asyncForEach //////////////////////////////////////////////////////////////////////////////////////////
	async asyncForEach(array, callback) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}

	// CREATE TABLE /////////////////////////////////////////////////////
	//Si l'UID ne renvoit à aucune table, on la crée

	createTable() {}

	//Add frame
	addFrame() {
		const _this = this;
		const table = [...this.state.newTable];

		let orders = [];
		table.map(({ order }) => orders.push(order));

		//On crée un index unique dans le tableau
		let newOrder = 1;
		while (orders.includes(newOrder)) {
			newOrder++;
		}

		//On s'assure que les numéros d'ordre ne sautent pas de numéro
		orders = minimize(orders);

		const newImage = {
			imageRef: null,
			fileRef: null,
			extension: null,
			offsetX: 0,
			offsetY: 0,
			order: newOrder,
		};

		this.TABLEREF.add(newImage)
			.then(function (image) {
				newImage.imageRef = image.id;

				newImage.downloadURL = undefined;
				newImage.frameReactRef = React.createRef();
				newImage.framedImageReactRef = React.createRef();
				newImage.imageReactRef = React.createRef();
				newImage.width = "auto";
				newImage.height = "auto";
				newImage.left = 0;
				newImage.top = 0;

				table.push(newImage);

				_this.setState({ newTable: [...table] }, () => {
					console.log(`Nouvelle image enregistrée dans la table ${_this.props.uid}: ${image.id}`);
					_this.changesInProgress(true);
				});
			})
			.catch(function (error) {
				console.error("Error adding document: ", error);
			});
	}

	//Remove Frame
	removeFrame(indexInTable) {
		const _this = this;
		const table = [...this.state.newTable];

		this.TABLEREF.doc(this.state.newTable[indexInTable].imageRef)
			.delete()
			.then(() => {
				console.log([...table]);
				table.splice(indexInTable, 1);

				//On remet toutes les frame dans l'ordre en ne laissant pas de numéro inutilisé
				let orders = [];
				table.map(({ order }) => orders.push(order));

				orders = minimize(orders);
				orders.forEach((order, index) => {
					table[index].order = order;
				});
				console.log([...table]);
				_this.setState({ newTable: [...table] }, () => {
					console.log("Image supprimée");
					_this.changesInProgress(true);
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// SAVE /////////////////////////////////////////////

	save() {
		console.log("save");

		this.changesInProgress(false);

		const _this = this;
		const table = [...this.state.newTable];

		// /!\ AJOUTER LA FONCTIONNALITE SUIVANTE :
		//L'image ne s'update que si la nouvelle est différente de l'ancienne
		//this.state.newTable[x] !== this.state.oldTable [x]
		//Impossible de le faire ainsi puisque l'opérateur comparera les référence et non le contenu

		table.forEach(async function ({ imageRef, fileRef, downloadURL, extension, offsetX, offsetY, order }) {
			await _this.TABLEREF.doc(imageRef)
				.update({
					fileRef: fileRef,
					order: order,
					imageRef: imageRef,
					offsetX: offsetX,
					offsetY: offsetY,
				})
				.then(function () {
					console.log(`Image ${imageRef} mise à jour avec succès`);
				})
				.catch(function (error) {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error);
				});
		});
	}

	// SIZING ALL //////////////////////////////////
	sizingAll = (index) => {
		if (this.state.newTable && this.state.newTable.length > 0) {
			this.state.newTable.forEach((image, index) => {
				this.sizing(index);
			});
		}
	};

	// SIZE pour faire correspondre la taille de l'image à celle de la frame

	sizing(index) {
		const table = [...this.state.newTable];

		const frame = table[index].frameReactRef.current.getBoundingClientRect();

		const image = table[index].imageReactRef.current.getBoundingClientRect();

		const frameRatio = frame.width / frame.height;
		const imageRatio = image.width / image.height;

		if (frameRatio > imageRatio) {
			table[index].width = `${frame.width}px`;
			table[index].height = "auto";

			this.setState({ newTable: [...table] });
		} else {
			table[index].height = `${frame.height}px`;
			table[index].width = "auto";

			this.setState({ newTable: [...table] });
		}
	}

	// CHANGE OFFESETS /////////////////////////////////////////////:
	changeOffsets(index, x, y) {
		const table = this.state.newTable;

		table[index].offsetX = x;
		table[index].offsetY = y;

		this.setState({ newTable: [...table] });
	}

	// CHANGE POSITION /////////////////////////////////////////////:
	changePosition(index, left, top) {
		const table = [...this.state.newTable];

		table[index].left = left;
		table[index].top = top;

		this.setState({ newTable: [...table] });
		this.changeIndex(index);
	}

	// CHANGE INDEX /////////////////////////////////////////////:
	changeIndex(imageIndex) {
		const table = [...this.state.newTable];
		const frames = [];

		//On liste tout les "bouding client rect" des frames du tableau d'image
		table.forEach((frame) => frames.push(frame.frameReactRef.current.getBoundingClientRect()));

		//On récupère le "bounding client rect" de la framedImage en mouvement
		const image = table[imageIndex].framedImageReactRef.current.getBoundingClientRect();

		//On calcule le centre de l'image en mouvement
		const imageCenter = {
			left: image.left + image.width / 2,
			top: image.top + image.height / 2,
		};

		//On vérifie si le centre de l'image en mouvement entre dans une frame
		//i = index de la frame que l'on vérifié actuellement, et sera si la condition
		//est remplie, l'index de la frame qui sera remplacée par l'image en mouvement
		for (let i = 0; i < frames.length; i++) {
			if (
				imageCenter.left >= frames[i].left &&
				imageCenter.left <= frames[i].left + frames[i].width &&
				imageCenter.top >= frames[i].top &&
				imageCenter.top <= frames[i].top + frames[i].width &&
				i !== imageIndex
			) {
				//On enregistre l'ordre de chaque image
				const orders = [];
				table.forEach((frame) => orders.push(frame.order));

				//Si l'image qu'on a déplacé est descendu dans l'ordre
				if (orders[imageIndex] > orders[i]) {
					//On affecte à l'image en mouvement sa nouvelle position

					table[imageIndex].order = orders[i];

					//On fait avancer chaque autre image de 1 jusqu'à l'ancienne position
					//de l'image qui vient d'être déplacé
					//j = ordre d'image
					for (let j = orders[i]; j < orders[imageIndex]; j++) {
						console.log(j, orders.indexOf(j));
						table[orders.indexOf(j)].order++;
					}
				}

				//Si l'image qu'on a déplacé est remonté dans l'ordre
				if (orders[imageIndex] < orders[i]) {
					//On affecte à l'image en mouvement sa nouvelle position

					table[imageIndex].order = orders[i];

					//On fait avancer chaque autre image de 1 jusqu'à l'ancienne position
					//de l'image qui vient d'être déplacé
					//j = ordre d'image
					for (let j = orders[i]; j > orders[imageIndex]; j--) {
						table[orders.indexOf(j)].order--;
					}
				}
			}
		}

		this.setState({ newTable: [...table] });
	}

	// CHANGE SOURCE /////////////////////////////////////////////
	//L'uploader change le fichier source d'une image
	changeSource(imageIndex, fileRef) {
		if (fileRef) {
			const _this = this;
			const table = [...this.state.newTable];
			table[imageIndex].fileRef = fileRef;

			fileRef &&
				this.props.firebase.db
					.collection("files")
					.doc(fileRef)
					.get()
					.then((file) => {
						table[imageIndex].downloadURL = file.data().downloadURL;

						_this.setState({ newTable: [...table] }, () => {
							_this.uploading(null);
							_this.changesInProgress(true);

							//Reset la position/offset/cadrage car c'est une nouvelle image
							//dont les dimensions sont différentes de la précédente
							_this.changeOffsets(imageIndex, 0, 0);
						});
					})
					.catch(function (error) {
						console.log(
							"Erreur lors du changement de la source de l'image.\nImage : ",
							table[imageIndex],
							"\nNouvelle source : ",
							fileRef,
							"\nErreur : ",
							error
						);
					});
		} else {
			this.uploading(null);
		}
	}

	// FORMAT TABLE /////////////////////////////////////////////////////
	//Format table permet de s'assurer que notre tableau d'images respecte les consignes
	//de longueur maximale, minimale ou fixe désignées dans les props
	formatTable(table) {
		console.log("formatTable", this.props.uid, table);
		let hadToFormat = false;

		let { min, max } = this.props;

		min = parseInt(min);
		max = parseInt(max);

		//On évite que min soit supérieur à max
		if (min > max) {
			console.log(
				`ERREUR dans le talbeau d'image ${this.props.uid} : la propriété min est supérieure à la propriété max`
			);
			min = max;
		}

		//Si le tableau est trop petit pour les règles spécifiées dans les props,
		//on l'agrandi avec des images vides
		if (min && table.length < min) {
			hadToFormat = true;

			//Tableau contenant les nouvelles images vides et variable suivant
			//La longueur du vrai tableau
			const newImages = [];
			let length = table.length;

			// INDEX //////////////////////////////////////
			//On cherche les index déjà utilisés pour en utiliser un autre
			//L'index n'est pas un uid ou une ref. C'est simplement le placement
			//Dans le tableau (flex-index)
			const orders = [];
			table.map(({ order }) => orders.push(order));

			//Création des nouvelles images vides du tableau
			while (length < min) {
				//On crée un index unique dans le tableau
				let newOrder = 1;
				while (orders.includes(newOrder)) {
					newOrder++;
				}
				orders.push(newOrder);
				//Dans un tableau qui ne contient que les nouvelles images créées par
				//le formattage, on  ajoute les chaque nouvelle image avec un index unique
				newImages.push({
					imageRef: null,
					fileRef: null,
					extension: null,
					offsetX: 0,
					offsetY: 0,
					order: newOrder,
				});

				length++;
			}

			//Envoie des données des nouvelles images à la BDD qui nous renvoie
			//Un identifiant unique pour chacune d'entre elles (imageRef)
			const _this = this;
			newImages.map(
				async (newImage) =>
					await this.TABLEREF.add(newImage)
						.then(function (image) {
							newImage.imageRef = image.id;
							console.log(`Nouvelle image enregistrée dans la table ${_this.props.uid}: ${image.id}`);
						})
						.catch(function (error) {
							console.error("Error adding document: ", error);
						})
			);

			newImages.forEach(function (newImage) {
				newImage.downloadURL = undefined;
				newImage.frameReactRef = React.createRef();
				newImage.framedImageReactRef = React.createRef();
				newImage.imageReactRef = React.createRef();
				newImage.width = "auto";
				newImage.height = "auto";
				newImage.left = 0;
				newImage.top = 0;

				table.push(newImage);
			});
		}

		//Si le tableau est trop grand, on retire des images
		if (max && table.length > max) {
			console.log("> MAX !", this.props.uid);
			let newTable = [...table];
			console.log("before", this.props.uid, newTable);
			hadToFormat = true;

			while (newTable.length > max) {
				const imageWithoutRefIndex = newTable.findIndex((image) => !image.fileRef);

				if (imageWithoutRefIndex >= 0) {
					console.log(this.props.uid, "delete index", imageWithoutRefIndex);
					newTable.splice(imageWithoutRefIndex, 1);
				} else {
					console.log(this.props.uid, "pop");
					newTable.pop();
				}
			}

			console.log("after", this.props.uid, newTable);
			table = newTable;
		}

		if (hadToFormat) {
			//Sauvegarder le tableau dans la BDD
		}

		return table;
	}

	// CANCEL //////////////////////////////////////////////////
	cancel() {
		const oldTable = [...this.state.oldTable];

		console.log("CANCELLING", "\nNew Table : ", this.state.newTable, "\nOld Table : ", oldTable);

		this.setState({ newTable: oldTable });

		this.changesInProgress(false);
	}

	// RENDER //////////////////////////////////////////////////////////////////////////////////////////

	render() {
		return (
			<AuthUserContext.Consumer>
				{(authUser) =>
					authUser ? (
						<React.Fragment>
							<div className={`ImagesTable ${this.props.className ? this.props.className : ""}`}>
								{this.state.newTable &&
									this.state.newTable.map(
										(
											{
												imageRef,
												fileRef,
												downloadURL,
												extension,
												order,
												offsetX,
												offsetY,
												imageReactRef,
												frameReactRef,
												framedImageReactRef,
												width,
												height,
												left,
												top,
											},
											indexInTable,
											table
										) => (
											<FramedImageAuth
												key={indexInTable}
												indexInTable={indexInTable}
												order={order}
												offsetX={offsetX}
												offsetY={offsetY}
												imageRef={imageRef}
												fileRef={fileRef}
												downloadURL={downloadURL}
												extension={extension}
												firebase={this.props.firebase}
												uploading={() => {
													this.props.uploader.uploading((fileRef) =>
														this.changeSource(indexInTable, fileRef)
													);
													this.uploading(indexInTable);
												}}
												getAuthUser={this.props.uploader.getAuthUser}
												authUser={authUser}
												isThereChangesInProgress={this.isThereChangesInProgress}
												changesInProgress={this.changesInProgress.bind(this)}
												doingAnAction={() => this.doingAnAction(indexInTable)}
												isDoingAnAction={this.state.isDoingAnAction}
												isUploading={this.state.isUploading}
												changeOffsets={(x, y) => this.changeOffsets(indexInTable, x, y)}
												sizing={() => this.sizing(indexInTable)}
												imageReactRef={imageReactRef}
												frameReactRef={frameReactRef}
												framedImageReactRef={framedImageReactRef}
												width={width}
												height={height}
												left={left}
												top={top}
												changePosition={this.changePosition.bind(this)}
												canMove={!this.props.max || this.props.max > 1}
												canRemove={table.length > this.props.min}
												removeFrame={() => this.removeFrame(indexInTable)}
											/>
										)
									)}

								{this.state.newTable &&
									(this.state.newTable.length < this.props.max || !this.props.max) && (
										<div className="Frame addFrame">
											<button
												style={{ backgroundImage: `url(${more})` }}
												onClick={() => this.addFrame()}
											/>
										</div>
									)}

								<SubmitCancelButtons
									submit={() => this.save()}
									cancel={() => this.cancel()}
									visible={this.state.isThereChangesInProgress}
								/>
							</div>
						</React.Fragment>
					) : (
						<div className={`ImagesTable ${this.props.className}`}>
							{this.state.newTable &&
								this.state.newTable.map(
									(
										{
											imageRef,
											fileRef,
											downloadURL,
											extension,
											order,
											offsetX,
											offsetY,
											imageReactRef,
											frameReactRef,
											framedImageReactRef,
											width,
											height,
											left,
											top,
										},
										indexInTable
									) => (
										<FramedImageNonAuth
											key={indexInTable}
											indexInTable={indexInTable}
											order={order}
											offsetX={offsetX}
											offsetY={offsetY}
											imageRef={imageRef}
											fileRef={fileRef}
											downloadURL={downloadURL}
											extension={extension}
											firebase={this.props.firebase}
											isThereChangesInProgress={this.isThereChangesInProgress}
											changesInProgress={this.changesInProgress.bind(this)}
											doingAnAction={() => this.doingAnAction(indexInTable)}
											isDoingAnAction={this.state.isDoingAnAction}
											changeOffsets={(x, y) => this.changeOffsets(indexInTable, x, y)}
											sizing={() => this.sizing(indexInTable)}
											imageReactRef={imageReactRef}
											frameReactRef={frameReactRef}
											framedImageReactRef={framedImageReactRef}
											width={width}
											height={height}
											left={left}
											top={top}
											changePosition={this.changePosition.bind(this)}
										/>
									)
								)}
						</div>
					)
				}
			</AuthUserContext.Consumer>
		);
	}
}

// EXPORT ET PROP TYPES /////////////////////////////////////////////////////////////////////////////////////

export default withFirebase(ImagesTable);

ImagesTable.propTypes = {
	uid: PropTypes.string.isRequired,
};
