import BubbleButton from "../BubbleButton";

//Retour à la ligne interdit pour ces élément
const forbiddenLineBreak = ["h1", "h2", "h3", "h4", "h5", "h6", "button", "BubbleButton", "a"];

const componentsEditable = {
    BubbleButton: BubbleButton
};

export { forbiddenLineBreak, componentsEditable };
