import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

import EditableAuth from "./EditableAuth";
import EditableNonAuth from "./EditableNonAuth";
import { forbiddenLineBreak, componentsEditable } from "./config";

import "./style.css";

//////////////////////////////////////////////////////////////////////////////////////////

class Editable extends Component {
    _isMounted = false;
    _textReceived = false;

    //Référence du document Firestore
    DOC = this.props.firebase.db
        .collection("textes")
        .doc(this.props.page)
        .collection("textes")
        .doc(this.props.uid);

    state = {
        currentText: "",
        oldText: ""
    };

    verifyProps;

    // COMPENENT DID & WILL MOUNT ////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        this._isMounted = true;
        this.loadText();
    }

    componentDidUpdate() {
        this.loadText();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this._textReceived = false;
    }

    loadText() {
        if (!this._textReceived && this.props.text) {
            this._textReceived = true;
            const text = this.props.text[this.props.page][this.props.uid];

            this.setState({
                currentText: text,
                oldText: text
            });
        }
    }

    // SAVE TEXT //////////////////////////////////////////////////////////////////////////////////////////

    saveText() {
        const _this = this;
        this.DOC.set({ texte: this.state.currentText })
            .then(function() {
                _this.setState({ oldText: _this.state.currentText });
                console.log(
                    `Document ${_this.props.uid} de la page ${_this.props.page} sauvegardé : ${_this.state.text}`
                );
            })
            .catch(function(error) {
                console.error(
                    `Impossible d'écrire le document ${_this.props.uid} de la page ${_this.props.page} :`,
                    error
                );
            });
    }

    // HANDLE CHANGE & SETTEXT //////////////////////////////////////////////////////////////////////////////////////////

    handleChange = ({ target: { value } }) => {
        //Impossibilité de saut à la ligne pour les éléments du tableau this.forbiddenLineBread
        if (forbiddenLineBreak.includes(this.props.tag)) value = value.replace("\n", "");

        this.setText(value);
    };

    setText(text) {
        if (this._isMounted) this.setState({ currentText: text });
    }

    cancel() {
        this.setState({ currentText: this.state.oldText });
    }

    // RENDER //////////////////////////////////////////////////////////////////////////////////////////

    render() {
        let tagName = this.props.tag || "p";

        if (/^[A-Z]/.test(tagName)) {
            tagName = componentsEditable[tagName];
            if (tagName === undefined) {
                console.log(
                    `React Components ${this.props.tag} has to be imported in config.js in componentsEditable
                    \nCurrent componentsEditable :`,
                    componentsEditable
                );
            }
        }

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    authUser ? (
                        <EditableAuth
                            {...this.props}
                            tagName={tagName}
                            setText={() => this.setText()}
                            saveText={() => this.saveText()}
                            handleChange={event => this.handleChange(event)}
                            cancel={this.cancel.bind(this)}
                            submit={this.saveText.bind(this)}
                            currentText={this.state.currentText}
                        />
                    ) : (
                        <EditableNonAuth {...this.props} tagName={tagName} currentText={this.state.currentText} />
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

// EXPORT ET PROP TYPES /////////////////////////////////////////////////////////////////////////////////////

export default withFirebase(Editable);
