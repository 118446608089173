import React, { Component } from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Editable from "../Editable/Editable";
import Location from "../Location";

import "./style.css";

class Informations extends Component {
    render() {
        return (
            <React.Fragment>
                <header className="informations">
                    <Nav />
                </header>
                <div className="infosContainer">
                    <div className="infosColumn">
                        <Editable
                            tag="h3"
                            page="informations"
                            uid="titre1"
                            text={this.props.text}
                            className="informations titre"
                        />
                        <Editable
                            tag="p"
                            page="informations"
                            uid="paragraphe1"
                            text={this.props.text}
                            className="informations paragraphe"
                        />
                        <Editable
                            tag="h3"
                            page="informations"
                            uid="titre4"
                            text={this.props.text}
                            className="informations titre"
                        />
                        <Editable
                            tag="p"
                            page="informations"
                            uid="paragraphe4"
                            text={this.props.text}
                            className="informations paragraphe"
                        />
                    </div>
                    <div className="infosColumn">
                        <Editable
                            tag="h3"
                            page="informations"
                            uid="titre2"
                            text={this.props.text}
                            className="informations titre"
                        />
                        <Editable
                            tag="p"
                            page="informations"
                            uid="paragraphe2"
                            text={this.props.text}
                            className="informations paragraphe"
                        />
                        <Editable
                            tag="h3"
                            page="informations"
                            uid="titre3"
                            text={this.props.text}
                            className="informations titre"
                        />
                        <Editable
                            tag="p"
                            page="informations"
                            uid="paragraphe3"
                            text={this.props.text}
                            className="informations paragraphe"
                        />
                    </div>
                </div>

                <Location />

                <Footer />
            </React.Fragment>
        );
    }
}

export default Informations;
