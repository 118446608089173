import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Accueil from "../Accueil";
import Activites from "../Activites";
import Galerie from "../Galerie";
import Informations from "../Informations";
import Admin from "../Admin";
import { withAuthentication } from "../Session";
import { withTextLoader } from "../Editable";
import { withUploader } from "../ImagesTable";

import * as ROUTES from "../../constants/routes";

import "./style.css";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route path={ROUTES.ADMIN}>
                            <Admin text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                        <Route path={ROUTES.ACTIVITES}>
                            <Activites text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                        <Route path={ROUTES.GALERIE}>
                            <Galerie text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                        <Route path={ROUTES.INFORMATIONS}>
                            <Informations text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                        <Route path={ROUTES.ACCUEIL}>
                            <Accueil text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                        <Route path="/">
                            <Accueil text={this.props.text} uploader={this.props.uploader} />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default withUploader(withTextLoader(withAuthentication(App)));
