import React from "react";
import { Link } from "react-router-dom";

import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes.js";

import { AuthUserContext } from "../Session";

import "./style.css";

const Nav = () => (
    <nav className="headerNav">
        <h1>
            <Link to={ROUTES.ACCUEIL}>
                Le moulin des
                <br />
                <span>Desniers</span>
            </Link>
        </h1>
        <AuthUserContext.Consumer>
            {authUser => (authUser ? <NavAuth /> : <NavNonAuth />)}
        </AuthUserContext.Consumer>
    </nav>
);

//NAV AUTH
const NavAuth = () => (
    <ul>
        <li>
            <Link to={ROUTES.ACTIVITES}>Activités</Link>
        </li>
        <li>
            <Link to={ROUTES.GALERIE}>Galerie</Link>
        </li>
        <li>
            <Link to={ROUTES.INFORMATIONS}>Informations</Link>
        </li>
        <li>
            <SignOutButton />
        </li>
    </ul>
);

//NAV NON AUTH
const NavNonAuth = () => (
    <ul>
        <li>
            <Link to={ROUTES.ACTIVITES}>Activités</Link>
        </li>
        <li>
            <Link to={ROUTES.GALERIE}>Galerie</Link>
        </li>
        <li>
            <Link to={ROUTES.INFORMATIONS}>Informations</Link>
        </li>
    </ul>
);

export default Nav;
