// minimize([9, 2, 5, 4, 7, 10])
// retourne [4, 0, 2, 1, 3, 5]

//Retourne la position de la valeur dans l'ordre ascendant

export default function minimize(array) {
    if (array !== undefined && array[0] !== undefined) {
        const length = array.length;
        const oldArray = [...array];
        const newArray = new Array(length);

        //array.map pour répéter l'opération suivante
        //autant de fois qu'ie le tableau contient d'éléments
        let i = 0;
        array.forEach(element => {
            //On trouve l'index de la valeur minimum dans le tableau
            const min = Math.min(...oldArray);
            const index = array.indexOf(min);
            newArray[index] = i;

            //On retire l'élément minimum pour qu'il ne soit plus
            //pris en compte dans le calcul de la valeur minimum du tableau
            oldArray.splice(oldArray.indexOf(min), 1);

            //incrémantation de la valeur à entrer dans le nouveau tableau
            i++;
        });

        return newArray;
    } else {
        throw new Error("La fonction minimize doit recevoir un tableau en argument");
    }
}
