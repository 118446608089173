import React, { Component } from "react";
import "./style.css";
import { Link } from "react-router-dom";

export default class BubbleButton extends Component {
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();
        this.circleRef = React.createRef();

        this.state = {
            circleStyle: {
                top: 0,
                left: 0,
                transform: "scale(0)"
            }
        };
    }

    setCirclePosition(e, scale) {
        const circle = window.getComputedStyle(this.circleRef.current, null);

        let width = circle.getPropertyValue("width");
        let height = circle.getPropertyValue("height");

        if (width.search(/[px]/g) >= 0 && height.search(/[px]/g) >= 0) {
            width = parseInt(width.replace(/\D/g, ""), 10);
            height = parseInt(height.replace(/\D/g, ""), 10);

            this.setState({
                circleStyle: {
                    left: e.clientX - this.buttonRef.current.getBoundingClientRect().left - width / 2,
                    top: e.clientY - this.buttonRef.current.getBoundingClientRect().top - height / 2,
                    transform: "scale(" + scale + ")"
                }
            });
        } else {
            console.log("Size of a BubbleButton has to be in px", this.buttonRef.current);
        }
    }

    handleMouseEnter(e) {
        this.setCirclePosition(e, 1);
    }

    handleMouseLeave(e) {
        this.setCirclePosition(e, 0);
    }

    render() {
        //J'ai remplacé le tag button par des span pour avoir un HTML correct dans le
        //cas où un boutton serait éditable

        if (this.props.link) {
            return (
                <span
                    ref={this.buttonRef}
                    className={this.props.className ? `BubbleButton ${this.props.className}` : "BubbleButton"}
                    onMouseEnter={this.handleMouseEnter.bind(this)}
                    onMouseLeave={this.handleMouseLeave.bind(this)}
                    onClick={this.props.onClick}
                >
                    <Link to={this.props.link}>
                        <span className="circleContainer">
                            <span ref={this.circleRef} style={this.state.circleStyle} className="circle"></span>
                        </span>

                        <span className="bubbleButtonText">{this.props.children}</span>
                    </Link>
                </span>
            );
        } else {
            return (
                <span
                    ref={this.buttonRef}
                    className={this.props.className ? `BubbleButton ${this.props.className}` : "BubbleButton"}
                    onMouseEnter={this.handleMouseEnter.bind(this)}
                    onMouseLeave={this.handleMouseLeave.bind(this)}
                    onClick={this.props.onClick}
                >
                    <span className="circleContainer">
                        <span ref={this.circleRef} style={this.state.circleStyle} className="circle"></span>
                    </span>

                    <span className="bubbleButtonText">{this.props.children}</span>
                </span>
            );
        }
    }
}
