import React, { Component } from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Editable from "../Editable/Editable";
import ImagesTable from "../ImagesTable";
import Loader from "../Loader";

import "./style.css";

class Activites extends Component {
	render() {
		return (
			<React.Fragment>
				<Loader />
				<header>
					<Nav />
				</header>

				{[1, 2, 3, 4].map((index) => (
					<article key={index} className="activite">
						<div className="firstContainer">
							<div className="secondContainer">
								<Editable tag="h3" page="activites" uid={`titre${index}`} text={this.props.text} />
								<ImagesTable min={1} max={1} uid={`table${index}1`} uploader={this.props.uploader} />
							</div>
							<Editable tag="p" page="activites" uid={`paragraphe${index}`} text={this.props.text} />
						</div>
						<ImagesTable min={1} max={1} uid={`table${index}2`} uploader={this.props.uploader} />
					</article>
				))}

				<Footer />
			</React.Fragment>
		);
	}
}

export default Activites;
