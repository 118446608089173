import React, { Component } from "react";

//PARALLAX
class Parallax extends Component {
    state = {
        ref: React.createRef(),
        scrollPosition: window.innerHeight / 2,
        translate: 0
    };

    static defaultProps = {
        speed: 1
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.calculTranslate(0);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    handleScroll = e => {
        const scrollPosition = parseInt(e.target.scrollingElement.scrollTop); //+ window.innerHeight ;
        this.calculTranslate(scrollPosition);
    };

    calculTranslate(scrollPosition) {
        const verticalPosition = parseInt(
            this.state.ref.current.getBoundingClientRect().top + window.scrollY - this.state.translate
        );
        const translate = parseInt(((verticalPosition - scrollPosition) * this.props.speed) / 20);

        this.setState({ translate: translate });
    }

    render() {
        return (
            <div
                ref={this.state.ref}
                className={this.props.className}
                style={{ transform: `translateY(${this.state.translate}px)` }}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Parallax;
