import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parallax from "../Parallax";
import "./style.css";

import * as ROUTES from "../../constants/routes";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
        return 0;
    });
    return images;
}

const partenaires = importAll(require.context("../../assets/logos", false));

//FOOTER
class Footer extends Component {
    state = {
        footerNav: false
    };

    render() {
        return (
            <footer>
                <Parallax className="colline" speed="-4" />
                <h1>
                    <Link to={ROUTES.ACCUEIL}>
                        Le moulin des
                        <br />
                        <span>Desniers</span>
                    </Link>
                </h1>
                <div>
                    <nav className="footerNav">
                        <ul>
                            <li>
                                <Link to={ROUTES.ACTIVITES}>Activités</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.GALERIE}>Galerie</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.INFORMATIONS}>Informations</Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="partenaires -wrap w1">
                        {Object.keys(partenaires).map((key, index) => (
                            <img key={key} alt="moulin" src={partenaires[key]} />
                        ))}
                    </div>
                    <p className="-wrap w1">
                        Site web réalisé par&nbsp;<a href="h.lc">Harmony Bertrand</a>&nbsp;- Tous droits réservés © 2019
                    </p>
                </div>
            </footer>
        );
    }
}

export default Footer;
