import React, { Component } from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Editable from "../Editable";
import ImagesTable from "../ImagesTable";
import * as ROUTES from "../../constants/routes.js";
import Loader from "../Loader";
import Illustration from "../Illustration";

import "./style.css";

class Accueil extends Component {
    render() {
        return (
            <React.Fragment>
                <Loader />
                <div className="blueBackground">
                    <header className="accueil">
                        <Nav />
                        <div className="headerWrapper">
                            <Editable
                                tag="h1"
                                page="accueil"
                                uid="sous-titre"
                                text={this.props.text}
                                className="accueil sousTitre"
                            />
                            <Editable
                                tag="aside"
                                page="accueil"
                                uid="infos"
                                text={this.props.text}
                                className="accueil infos"
                            />
                        </div>
                    </header>
                </div>
                <Illustration />
                <div className="greenBackground">
                    <Editable tag="h3" page="accueil" uid="titre1" text={this.props.text} className="accueil titre" />
                    <Editable
                        tag="p"
                        page="accueil"
                        uid="paragraphe1"
                        text={this.props.text}
                        className="accueil  paragraphe"
                    />
                    <Editable
                        tag="BubbleButton"
                        page="accueil"
                        uid="boutton1"
                        text={this.props.text}
                        className="accueil"
                        link={ROUTES.ACTIVITES}
                    />
                </div>

                <div className="whiteBackground">
                    <div className="round">
                        <div></div>
                    </div>
                    <Editable tag="h3" page="accueil" uid="titre2" text={this.props.text} className="accueil titre" />
                    <ImagesTable
                        min={6}
                        max={6}
                        uid="accueil"
                        className="accueil apercuGalerie"
                        uploader={this.props.uploader}
                    />
                    <Editable
                        tag="BubbleButton"
                        page="accueil"
                        uid="boutton2"
                        text={this.props.text}
                        className="accueil"
                        link={ROUTES.GALERIE}
                    />
                </div>

                <Editable tag="h3" page="accueil" uid="titre3" text={this.props.text} className="accueil titre" />
                <Editable
                    tag="p"
                    page="accueil"
                    uid="paragraphe3"
                    text={this.props.text}
                    className="accueil paragraphe"
                />
                <Editable
                    tag="BubbleButton"
                    page="accueil"
                    uid="boutton3"
                    text={this.props.text}
                    className="accueil"
                    link={ROUTES.INFORMATIONS}
                />

                <Footer />
            </React.Fragment>
        );
    }
}

export default Accueil;
