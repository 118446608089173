import React from "react";
import "./Source.css";
import "./animations.css";

console.log("autre");

export default function Source(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 813.3">
            <defs />

            <g id="illustration">
                <path id="ciel" d="M0 0h1921.1v534.8H0z" className="st0" />
                <g id="nuages">
                    <g id="nuage_2_">
                        <path
                            id="n_5_"
                            d="M857.4 187.4c0 8.2-6.6 14.8-14.8 14.8H722.3a14.8 14.8 0 110-29.6h120.3c8.2 0 14.8 6.6 14.8 14.8z"
                            className="st1"
                        />
                        <path
                            id="n_4_"
                            d="M818.9 166.9a12 12 0 01-12 12h-97.4a12 12 0 01-12-12 12 12 0 0112-12h97.4a12 12 0 0112 12z"
                            className="st1"
                        />
                    </g>
                    <g id="nuage_1_">
                        <path
                            id="n_3_"
                            d="M1049.3 213.6c0 10.4-8.4 18.8-18.8 18.8h-78.6a18.8 18.8 0 110-37.6h78.6c10.4 0 18.8 8.4 18.8 18.8z"
                            className="st1"
                        />
                        <path
                            id="n_2_"
                            d="M1103.7 230.6a13 13 0 01-13.1 13.1H962.1a13 13 0 110-26.2h128.6c7.2.1 13 5.9 13 13.1z"
                            className="st1"
                        />
                        <path
                            id="n_1_"
                            d="M1134.2 194c0 7.7-6.2 13.9-13.9 13.9H983.7a13.9 13.9 0 110-27.8h136.7c7.6 0 13.8 6.2 13.8 13.9z"
                            className="st1"
                        />
                        <path
                            id="n"
                            d="M1116.4 177.3a12 12 0 01-12 12H1007a12 12 0 01-12-12 12 12 0 0112-12h97.4a12 12 0 0112 12z"
                            className="st1"
                        />
                    </g>
                    <g id="nuage_3_">
                        <path
                            id="n_14_"
                            d="M1670 332h-150.9a10.6 10.6 0 010-21.2H1670a10.6 10.6 0 010 21.2z"
                            className="st1"
                        />
                        <path
                            id="n_9_"
                            d="M1636.7 298.3h-69.9a10.8 10.8 0 010-21.6h69.9a10.8 10.8 0 010 21.6z"
                            className="st1"
                        />
                        <path
                            id="n_8_"
                            d="M1624.1 313.5h-80a8.3 8.3 0 110-16.6h80a8.3 8.3 0 110 16.6z"
                            className="st1"
                        />
                        <path
                            id="n_7_"
                            d="M1678.2 301.6h-69.9a7 7 0 01-7-7 7 7 0 017-7h69.9a7 7 0 017 7 7 7 0 01-7 7z"
                            className="st1"
                        />
                        <path
                            id="n_6_"
                            d="M1655.1 281.9h-45.5a7.1 7.1 0 01-7.1-7.1c0-3.9 3.2-7.1 7.1-7.1h45.5c3.9 0 7.1 3.2 7.1 7.1a7 7 0 01-7.1 7.1z"
                            className="st1"
                        />
                    </g>
                    <g id="nuage_4_">
                        <path
                            id="n_13_"
                            d="M299.9 125.6c0 10.4-8.4 18.8-18.8 18.8h-78.6a18.8 18.8 0 110-37.6h78.6a18.7 18.7 0 0118.8 18.8z"
                            className="st1"
                        />
                        <path
                            id="n_12_"
                            d="M252.7 138a13 13 0 01-13.1 13.1H111.1a13 13 0 110-26.2h128.6a13 13 0 0113 13.1z"
                            className="st1"
                        />
                        <path
                            id="n_11_"
                            d="M272.2 103.5c0 7.7-6.2 13.9-13.9 13.9H121.6a13.9 13.9 0 110-27.8h136.7c7.7 0 13.9 6.2 13.9 13.9z"
                            className="st1"
                        />
                        <path
                            id="n_10_"
                            d="M185.7 86.7a12 12 0 01-12 12H76.3a12 12 0 01-12-12 12 12 0 0112-12h97.4a12 12 0 0112 12z"
                            className="st1"
                        />
                    </g>
                    <path
                        d="M1831.7 349.4h-109.2a11.7 11.7 0 010-23.4h109.2c6.4 0 11.7 5.2 11.7 11.7a11.8 11.8 0 01-11.7 11.7z"
                        className="st1"
                    />
                    <path
                        d="M1871.6 337.7h-113.5a13.2 13.2 0 110-26.4h113.5c7.3 0 13.2 5.9 13.2 13.2-.1 7.3-6 13.2-13.2 13.2zM1891.8 378.9h-155.9a9.9 9.9 0 01-9.9-9.9c0-5.5 4.4-9.9 9.9-9.9h155.9c5.5 0 9.9 4.4 9.9 9.9a10 10 0 01-9.9 9.9z"
                        className="st1"
                    />
                </g>
                <g id="montagne">
                    <g id="végétation_second_plan">
                        <g id="arbre_51_">
                            <path id="branches_54_" d="M1320.8 426h3.6v13.8h-3.6z" className="st2" />
                            <g id="feuilles_162_">
                                <path
                                    d="M1331.5 424.6c0 1.6-.9 3-2 3h-14.2c-.4 0-.7-.1-1-.4a35 35 0 0014.2-28.1l-.1-3.1c1.8 9.1 2.9 18.7 3.1 28.6z"
                                    className="st3"
                                />
                                <path
                                    d="M1328.6 399.1c0 11.5-5.6 21.8-14.2 28.1a3.1 3.1 0 01-1-2.6c.3-16.1 2.9-31.3 7.3-45.1.2-.7.6-1.2 1-1.4.9-.6 2.2-.1 2.7 1.4l2.1 7.1 2.2 9.3c-.2 1.1-.1 2.2-.1 3.2z"
                                    className="st4"
                                />
                                <g id="fruits_6_">
                                    <circle id="fruit_41_" cx="1319.4" cy="396.1" r=".7" className="st5" />
                                    <circle id="fruit_40_" cx="1321.9" cy="411.2" r=".7" className="st5" />
                                    <circle id="fruit_39_" cx="1328.6" cy="418.6" r=".7" className="st5" />
                                    <circle id="fruit_38_" cx="1325.5" cy="402.4" r=".7" className="st5" />
                                    <circle id="fruit_37_" cx="1323.3" cy="387.5" r=".7" className="st5" />
                                    <circle id="fruit_36_" cx="1317.6" cy="423" r=".7" className="st5" />
                                </g>
                            </g>
                        </g>
                        <g id="arbre_31_">
                            <g id="branches_53_">
                                <path d="M1204.4 384.4h2.4v16.2h-2.4z" className="st6" />
                                <path
                                    d="M1207.9 383.4h1.6v7.8h-1.6z"
                                    className="st6"
                                    transform="rotate(52.4 1208.7 387.4)"
                                />
                                <path
                                    d="M1208.9 381.1h.6v7.3h-.6z"
                                    className="st6"
                                    transform="rotate(30 1209.2 384.7)"
                                />
                                <path
                                    d="M1201.1 384.2h.8v4.8h-.8z"
                                    className="st6"
                                    transform="rotate(-11.4 1201.6 386.8)"
                                />
                                <path
                                    d="M1201.7 386.2h1.5v7h-1.5z"
                                    className="st6"
                                    transform="rotate(-48.4 1202.5 389.7)"
                                />
                                <path
                                    d="M1206.3 393.1h.5v3.1h-.5z"
                                    className="st6"
                                    transform="rotate(52.4 1206.6 394.7)"
                                />
                            </g>
                            <g id="feuilles_161_">
                                <path
                                    d="M1201.8 384.2a5 5 0 01-8.8 3h.1c3.6 0 6.5-2.9 6.5-6.5v-.6a5 5 0 012.2 4.1z"
                                    className="st7"
                                />
                                <path d="M1199.6 380.7c0 3.6-2.9 6.5-6.5 6.5h-.1a5 5 0 016.6-7.1v.6z" className="st8" />
                            </g>
                            <g id="feuilles_160_">
                                <path d="M1213.1 377.2a7.8 7.8 0 01-15 3 7.8 7.8 0 1115-3z" className="st7" />
                                <path
                                    d="M1209 372.9a8 8 0 01-7.9 7.9c-1 0-2-.2-2.9-.6a7.8 7.8 0 0110.4-10.1c.2.9.4 1.8.4 2.8z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_159_">
                                <path
                                    d="M1216.8 382.4a4 4 0 01-4 4c-1.3 0-2.4-.6-3.1-1.6h.1c2.9 0 5.3-2.4 5.3-5.3v-.5c.9.8 1.7 2 1.7 3.4z"
                                    className="st10"
                                />
                                <path
                                    d="M1215 379.5c0 2.9-2.4 5.3-5.3 5.3h-.1a4 4 0 013.2-6.4c.8 0 1.5.2 2.2.6v.5z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_25_">
                            <g id="branches_52_">
                                <path d="M1090.5 363.3h2.2v15.2h-2.2z" className="st6" />
                                <path
                                    d="M1093.7 362.7h1.5v7.3h-1.5z"
                                    className="st6"
                                    transform="rotate(52.4 1094.5 366.4)"
                                />
                                <path
                                    d="M1084.2 362.5h1.1v7.2h-1.1z"
                                    className="st6"
                                    transform="rotate(-48.4 1084.8 366.1)"
                                />
                                <path
                                    d="M1087.4 361.4h.8v4.5h-.8z"
                                    className="st6"
                                    transform="rotate(27.7 1087.7 363.6)"
                                />
                                <path
                                    d="M1087.4 362.1h1.4v16.6h-1.4z"
                                    className="st6"
                                    transform="rotate(-17.3 1088 370.4)"
                                />
                                <path
                                    d="M1087 368.6h.4v2.9h-.4z"
                                    className="st6"
                                    transform="rotate(-57.3 1087.2 370)"
                                />
                                <path d="M1095 361.9h.8v4.5h-.8z" className="st6" transform="rotate(27.7 1095.3 364)" />
                            </g>
                            <g id="feuilles_158_">
                                <path
                                    d="M1090.8 358.8c0 2.6-2.1 4.6-4.6 4.6a4.8 4.8 0 01-4.3-2.8c-.2-.6-.4-1.2-.4-1.8 0-2.6 2.1-4.6 4.6-4.6a4 4 0 011.9.4c1.6.7 2.8 2.3 2.8 4.2z"
                                    className="st11"
                                />
                                <path
                                    d="M1088.3 356.3a4.7 4.7 0 01-6.4 4.4c-.2-.6-.4-1.2-.4-1.8 0-2.6 2.1-4.6 4.6-4.6a4 4 0 011.9.4c.2.4.3 1 .3 1.6z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_157_">
                                <path
                                    d="M1083.8 362.6c0 1.7-1.4 3.1-3.1 3.1a3 3 0 01-2.8-1.9c-.2-.4-.2-.8-.2-1.2 0-1.7 1.4-3.1 3.1-3.1.4 0 .9.1 1.3.3 1 .5 1.7 1.6 1.7 2.8z"
                                    className="st11"
                                />
                                <path
                                    d="M1082.2 360.9c0 1.7-1.4 3.1-3.1 3.1a5 5 0 01-1.2-.2c-.2-.4-.2-.8-.2-1.2 0-1.7 1.4-3.1 3.1-3.1.4 0 .9.1 1.3.3l.1 1.1z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_156_">
                                <path
                                    d="M1099.2 356.5c0 4.2-3.4 7.6-7.6 7.6a7.4 7.4 0 01-6.2-3.3c5.5-.8 9.7-5.4 9.9-11a7.7 7.7 0 013.9 6.7z"
                                    className="st11"
                                />
                                <path
                                    d="M1095.3 349.8a11.6 11.6 0 01-9.9 11 7.1 7.1 0 01-1.4-4.3c0-4.2 3.4-7.6 7.6-7.6 1.3 0 2.6.3 3.7.9z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_155_">
                                <path
                                    d="M1105.1 361c0 2.7-2.2 4.8-4.8 4.8-1.6 0-3-.7-3.8-1.9h.1c3.5 0 6.4-2.9 6.4-6.4v-.6c1.2.9 2.1 2.4 2.1 4.1z"
                                    className="st11"
                                />
                                <path
                                    d="M1102.9 357.5c0 3.5-2.9 6.4-6.4 6.4h-.1a4.8 4.8 0 013.8-7.7c1 0 1.9.3 2.6.8l.1.5z"
                                    className="st12"
                                />
                            </g>
                        </g>
                        <g id="arbre_23_">
                            <g id="branches_51_">
                                <path d="M1257.1 399.9h2.4v15.5h-2.4z" className="st13" />
                                <path
                                    d="M1259.4 399.9h1.7v9.6h-1.7z"
                                    className="st13"
                                    transform="rotate(23.4 1260.3 404.8)"
                                />
                                <path
                                    d="M1259.1 410h.9v3.2h-.9z"
                                    className="st13"
                                    transform="rotate(52.4 1259.6 411.7)"
                                />
                                <path
                                    d="M1255.7 405h1.5v5.1h-1.5z"
                                    className="st13"
                                    transform="rotate(135 1256.5 407.6)"
                                />
                            </g>
                            <g id="feuilles_154_">
                                <path
                                    d="M1263.6 390v5.3a5.3 5.3 0 01-9.4 3.4 13 13 0 009.2-10.2c.2.5.2 1 .2 1.5z"
                                    className="st7"
                                />
                                <path
                                    d="M1263.4 388.6c-.9 4.9-4.5 8.8-9.2 10.2a5.1 5.1 0 01-1.3-3.4V390c0-2.9 2.4-5.3 5.3-5.3 2.5 0 4.6 1.6 5.2 3.9z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_153_">
                                <path
                                    d="M1267.4 397a4 4 0 01-7.7 1.6c-.2-.5-.3-1-.3-1.6a4 4 0 014-4c.6 0 1.1.1 1.6.3a4 4 0 012.4 3.7z"
                                    className="st7"
                                />
                                <path
                                    d="M1265.3 394.7c0 2.2-1.8 4.1-4.1 4.1a4 4 0 01-1.5-.3c-.2-.5-.3-1-.3-1.6a4 4 0 014-4c.6 0 1.1.1 1.6.3l.3 1.5z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_152_">
                                <path
                                    d="M1256.3 404.1c0 1.8-1.5 3.3-3.3 3.3-1.1 0-2.1-.6-2.7-1.4a5.1 5.1 0 004.4-4.8c.9.5 1.6 1.6 1.6 2.9z"
                                    className="st7"
                                />
                                <path
                                    d="M1254.6 401.2a5 5 0 01-4.4 4.8 3 3 0 01-.6-1.9c0-1.8 1.5-3.3 3.3-3.3.7-.1 1.2.1 1.7.4z"
                                    className="st8"
                                />
                            </g>
                        </g>
                        <g id="arbre_22_">
                            <g id="branches_7_">
                                <path d="M1126.4 364.8h3.5v19.9h-3.5z" className="st6" />
                                <path
                                    d="M1123.5 366.7h2.1v9.3h-2.1z"
                                    className="st6"
                                    transform="rotate(-45 1124.6 371.4)"
                                />
                                <path
                                    d="M1130.6 374.3h1.4v6.4h-1.4z"
                                    className="st6"
                                    transform="rotate(-135 1131.3 377.6)"
                                />
                            </g>
                            <g id="feuilles_151_">
                                <path d="M1135.8 359.4a8.4 8.4 0 01-16.1 3.3 8.4 8.4 0 1116.1-3.3z" className="st9" />
                                <path
                                    d="M1131.4 354.8a8.5 8.5 0 01-11.7 7.9 8.4 8.4 0 0111.1-11c.4 1 .6 2 .6 3.1z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_150_">
                                <path
                                    d="M1124.9 366.2a5.2 5.2 0 01-9.3 3.1h.1a7 7 0 006.9-6.9v-.6c1.3.9 2.3 2.5 2.3 4.4z"
                                    className="st9"
                                />
                                <path
                                    d="M1122.5 362.4a7 7 0 01-6.9 6.9h-.1c-.7-.9-1.1-2-1.1-3.2 0-2.9 2.3-5.2 5.2-5.2 1 0 2 .3 2.8.8l.1.7z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_149_">
                                <path
                                    d="M1138.3 374a3.6 3.6 0 01-6.5 2.1 5.5 5.5 0 004.7-5.2c1.1.5 1.8 1.7 1.8 3.1z"
                                    className="st9"
                                />
                                <path
                                    d="M1136.5 370.8a5.5 5.5 0 01-4.7 5.2 3.6 3.6 0 013-5.6c.6 0 1.1.1 1.7.4z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_32_">
                            <g id="branches_50_">
                                <path d="M1195.1 388.4h2.4v10.1h-2.4z" className="st6" />
                                <path
                                    d="M1197.5 392.2h.6v3.1h-.6z"
                                    className="st6"
                                    transform="rotate(37 1197.8 393.6)"
                                />
                            </g>
                            <g id="feuilles_148_">
                                <path
                                    d="M1201.6 388.7l-.1.2-.3.3-.1.1-.2.2-.2.2-.3.3c-.7.5-1.5.9-2.2 1.2l-.3.1-.2.1-.3.1-.4.1h-.2H1195.9l-.5-.1h-.1l-.3-.1-.3-.1-.7-.3-.1-.1-.6-.3-.2-.1-.1-.1-.1-.1-.3-.2-.1-.1-.2-.2-.2-.2-.1-.1 1-.6a8 8 0 002-2.1l.4-.1c1.6-.3 3.1-1 4.3-2.1l1.1-1.3c.5-.8 1-1.7 1.2-2.7l.4.3c1.7 1.6 1.4 5.1-.8 7.8z"
                                    className="st15"
                                />
                                <path
                                    d="M1201.8 380.5a8.3 8.3 0 01-2.3 4 7.6 7.6 0 01-4.3 2.1l-.4.1c-.6.8-1.3 1.5-2 2.1l-1 .6-.1-.1-.2-.2-.2-.3a7 7 0 01-1.9-3.6l-.1-.7c-.2-1.5.2-2.8 1.2-3.7l.3-.2.1-.1.1-.1h.1l.1-.1h.2l.1-.1.2-.1.2-.1v-.2-.2-.1-.2-.1l.1-.2.1-.2.1-.2.2-.5.1-.1.2-.3.1-.1.1-.1.1-.1v-.1l.1-.2.1-.1.1-.1.1-.2.1-.1.1-.2.1-.1.2-.1.1-.1.1-.1.1-.1.1-.1.2-.1.1-.1.2-.1.2-.1.2-.1.2-.1h2.1l.2.1H1198l.2.1.2.1.1.1v.6c0 1.5-.4 2.9-1 4.1 1.2-.5 2.4-.6 3.4-.3l.2.1.2.1.1.1h.1l.1.1.1.1h.1z"
                                    className="st9"
                                />
                                <path
                                    d="M1200.9 380.1c-1-.3-2.2-.2-3.4.3a8 8 0 001-4.1v-.6l.5.4.1.1.1.1.1.1.1.1.2.2.1.1.1.1v.1l.1.2.1.1.1.2.1.1.1.1.3.8.1.2.1.2v.8l.1.4z"
                                    className="st15"
                                />
                            </g>
                        </g>
                        <g id="arbre_20_">
                            <g id="branches_49_">
                                <path d="M718.9 316.3h2.4V333h-2.4z" className="st6" />
                                <path
                                    d="M721.9 321.2h.8v5.2h-.8z"
                                    className="st6"
                                    transform="rotate(52.4 722.3 323.8)"
                                />
                                <path d="M721.5 314.4h.6v7.5h-.6z" className="st6" transform="rotate(30 721.8 318.1)" />
                                <path d="M717.5 319h.9v5h-.9z" className="st6" transform="rotate(-37.6 717.8 321.5)" />
                                <path d="M718 325.8h.9v3.1h-.9z" className="st6" transform="rotate(-60 718.4 327.4)" />
                            </g>
                            <g id="feuilles_147_">
                                <path
                                    d="M727.2 310.2a7.4 7.4 0 01-14.2 2.9 7.4 7.4 0 016.8-10.3c1.1 0 2.1.2 3 .6a7.5 7.5 0 014.4 6.8z"
                                    className="st7"
                                />
                                <path
                                    d="M723.3 306.1a7.6 7.6 0 01-10.3 7 7.4 7.4 0 016.8-10.3c1.1 0 2.1.2 3 .6.3.9.5 1.8.5 2.7z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_146_">
                                <path
                                    d="M718.5 317.6a4 4 0 01-4 4 4 4 0 01-3.2-1.6h.1c2.9 0 5.3-2.4 5.3-5.3v-.5c1 .7 1.8 2 1.8 3.4z"
                                    className="st15"
                                />
                                <path
                                    d="M716.7 314.7c0 2.9-2.4 5.3-5.3 5.3h-.1a4 4 0 013.2-6.4c.8 0 1.5.2 2.2.6v.5z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_145_">
                                <path
                                    d="M729.7 320.2c0 2-1.7 3.7-3.7 3.7-1.2 0-2.3-.6-2.9-1.5h.1a5 5 0 004.9-4.9v-.4c.9.6 1.6 1.8 1.6 3.1z"
                                    className="st11"
                                />
                                <path
                                    d="M728 317.5a5 5 0 01-4.9 4.9h-.1c-.5-.6-.8-1.4-.8-2.2 0-2 1.7-3.7 3.7-3.7.7 0 1.4.2 2 .6l.1.4z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_17_">
                            <g id="branches_48_">
                                <path d="M1216 388.2h2.5v16.3h-2.5z" className="st13" />
                                <path
                                    d="M1218.4 388.2h1.8v10.1h-1.8z"
                                    className="st13"
                                    transform="rotate(23.4 1219.3 393.3)"
                                />
                                <path
                                    d="M1218.2 398.8h.9v3.3h-.9z"
                                    className="st13"
                                    transform="rotate(52.4 1218.6 400.6)"
                                />
                                <path
                                    d="M1214.5 393.6h1.6v5.4h-1.6z"
                                    className="st13"
                                    transform="rotate(135 1215.3 396.3)"
                                />
                            </g>
                            <g id="feuilles_144_">
                                <path
                                    d="M1222.9 377.8v5.6a5.6 5.6 0 01-9.9 3.6c5-1.4 8.8-5.6 9.7-10.7l.2 1.5z"
                                    className="st16"
                                />
                                <path
                                    d="M1222.7 376.3c-.9 5.1-4.7 9.3-9.7 10.7-.8-1-1.3-2.2-1.3-3.6v-5.6a5.6 5.6 0 0111-1.5z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_143_">
                                <path
                                    d="M1226.9 385.1c0 2.3-1.9 4.2-4.2 4.2a4.4 4.4 0 01-3.9-2.6 4.2 4.2 0 013.9-5.8c.6 0 1.2.1 1.7.4a4 4 0 012.5 3.8z"
                                    className="st9"
                                />
                                <path
                                    d="M1224.7 382.8c0 2.4-1.9 4.3-4.3 4.3-.6 0-1.1-.1-1.6-.3a4.2 4.2 0 013.9-5.8c.6 0 1.2.1 1.7.4.2.3.3.8.3 1.4z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_142_">
                                <path
                                    d="M1215.2 392.6c0 1.9-1.6 3.5-3.5 3.5-1.2 0-2.3-.6-2.9-1.5a5.4 5.4 0 004.6-5.1c1.1.6 1.8 1.8 1.8 3.1z"
                                    className="st17"
                                />
                                <path
                                    d="M1213.4 389.5a5.4 5.4 0 01-4.6 5.1 3.5 3.5 0 012.9-5.5c.6 0 1.2.1 1.7.4z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_16_">
                            <g id="branches_5_">
                                <path d="M944.1 345.8h2.7v15.4h-2.7z" className="st6" />
                                <path
                                    d="M941.9 347.3h1.6v7.2h-1.6z"
                                    className="st6"
                                    transform="rotate(-45 942.7 350.9)"
                                />
                                <path d="M947.3 353.2h1.1v5h-1.1z" className="st6" transform="rotate(-135 948 355.6)" />
                            </g>
                            <g id="feuilles_141_">
                                <path
                                    d="M951.4 341.6a6.5 6.5 0 01-12.5 2.5 6.5 6.5 0 016-9c.9 0 1.8.2 2.6.6 2.3 1 3.9 3.3 3.9 5.9z"
                                    className="st11"
                                />
                                <path
                                    d="M948 338c0 3.6-2.9 6.6-6.6 6.6a6 6 0 01-2.4-.5 6.5 6.5 0 016-9c.9 0 1.8.2 2.6.6.2.7.4 1.5.4 2.3z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_140_">
                                <path
                                    d="M942.9 346.8a4 4 0 01-7.2 2.4h.1c3 0 5.4-2.4 5.4-5.4v-.5c1 .9 1.7 2.1 1.7 3.5z"
                                    className="st7"
                                />
                                <path
                                    d="M941.1 343.9c0 3-2.4 5.4-5.4 5.4h-.1a4 4 0 013.2-6.4c.8 0 1.6.2 2.2.7l.1.3z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_139_">
                                <path
                                    d="M953.3 352.9a2.8 2.8 0 01-5.1 1.6c2-.3 3.5-2 3.6-4 .9.4 1.5 1.3 1.5 2.4z"
                                    className="st18"
                                />
                                <path d="M951.9 350.4a4.2 4.2 0 01-3.6 4 2.8 2.8 0 012.3-4.4l1.3.4z" className="st12" />
                            </g>
                        </g>
                        <g id="arbre_38_">
                            <g id="branches_47_">
                                <path d="M1136.4 370.8h2.7v15.3h-2.7z" className="st13" />
                                <path
                                    d="M1133.6 376h4.9v1.1h-4.9z"
                                    className="st13"
                                    transform="rotate(39 1136 376.5)"
                                />
                                <path
                                    d="M1137.3 380.4h4.9v1.1h-4.9z"
                                    className="st13"
                                    transform="rotate(-39.4 1139.9 380.9)"
                                />
                            </g>
                            <g id="feuilles_138_">
                                <path
                                    d="M1145.2 356.6v7.6a7.6 7.6 0 01-13.4 4.9 18.3 18.3 0 0013-14.5c.3.7.4 1.3.4 2z"
                                    className="st17"
                                />
                                <path
                                    d="M1144.9 354.6a18.7 18.7 0 01-13 14.5 7.6 7.6 0 01-1.8-4.9v-7.6c0-4.2 3.4-7.6 7.6-7.6 3.4.1 6.3 2.4 7.2 5.6z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_137_">
                                <path
                                    d="M1135.2 373.8a3 3 0 01-3 3c-1 0-1.9-.5-2.5-1.3a4.5 4.5 0 003.9-4.3c1 .5 1.6 1.5 1.6 2.6z"
                                    className="st17"
                                />
                                <path
                                    d="M1133.7 371.2c-.1 2.2-1.8 4-3.9 4.3-.3-.5-.5-1.1-.5-1.7a3 3 0 013-3c.5 0 1 .1 1.4.4z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_50_">
                            <g id="branches_46_">
                                <path d="M1120.8 372.1h2.7v11.7h-2.7z" className="st6" />
                                <path
                                    d="M1123.4 375.4h.7v3.6h-.7z"
                                    className="st6"
                                    transform="rotate(37 1123.7 377.1)"
                                />
                                <path
                                    d="M1120.2 376.4h.7v3.6h-.7z"
                                    className="st6"
                                    transform="rotate(-19.2 1120.6 378.2)"
                                />
                            </g>
                            <g id="feuilles_136_">
                                <path
                                    d="M1121.2 375.5c0 .9-.7 1.6-1.6 1.6-.5 0-1-.3-1.3-.7 1.2-.2 2.1-1.1 2.1-2.3.5.3.8.8.8 1.4z"
                                    className="st7"
                                />
                                <path
                                    d="M1120.4 374.1c0 1.2-.9 2.2-2.1 2.3l-.3-.9c0-.9.7-1.6 1.6-1.6l.8.2z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_135_">
                                <path
                                    d="M1128.3 372.4l-.2.2-.3.4-.2.2-.3.3-.3.2-.4.3c-.8.6-1.7 1.1-2.6 1.4l-.3.1-.3.1-.3.1-.5.1h-.2-1l-.5-.1h-.2l-.3-.1-.3-.1-.8-.3-.2-.1-.6-.3-.3-.2-.1-.1-.2-.1-.4-.3-.2-.1-.2-.2-.3-.2-.1-.1 1.1-.7c.9-.7 1.7-1.5 2.4-2.4l.5-.1a9.5 9.5 0 005-2.5 10.1 10.1 0 002.7-4.7l.4.3c2.5 1.7 2.2 5.8-.5 9z"
                                    className="st7"
                                />
                                <path
                                    d="M1128.6 362.9a11 11 0 01-2.7 4.7 9.2 9.2 0 01-5.5 2.6c-.6.9-1.5 1.8-2.4 2.4l-1.1.7-.1-.1-.2-.2-.3-.3a10 10 0 01-2.3-4.1l-.1-.8c-.2-1.7.2-3.3 1.3-4.3l.3-.3.1-.1h.1l.1-.1.1-.1.1-.1h.1l.1-.1.2-.1.2-.1.2-.1v-.3-.2-.1-.2-.1l.1-.2.1-.3.1-.3.2-.6.1-.2.2-.4.1-.1.1-.2.1-.1v-.1l.1-.2v-.1l.1-.2.1-.1.1-.2.1-.2.2-.2.1-.1.2-.2.2-.1.2-.1.1-.1.1-.1.2-.1.2-.1.3-.1.2-.1.2-.1.2-.1h.1l.2-.1.2-.1H1122.3l.5.1h.5l.2.1.1.1H1123.9l.2.1.2.1.2.1v.7c0 1.7-.4 3.4-1.2 4.8 1.4-.6 2.8-.7 4-.3l.2.1.2.1.1.1.1.1.1.1.1.1.5.2z"
                                    className="st11"
                                />
                                <path
                                    d="M1127.5 362.4a6.2 6.2 0 00-4 .3 9.8 9.8 0 001.2-4.8v-.7l.6.5.2.2.1.1.1.1.1.1.2.3.1.2.1.1v.1l.1.2.1.2.1.2.1.1.1.2.4.9.1.2.1.3V362.1c.2.1.2.2.2.3z"
                                    className="st7"
                                />
                            </g>
                            <g id="feuilles_134_">
                                <path
                                    d="M1127.5 374.3c0 1.2-1 2.2-2.2 2.2-.7 0-1.4-.4-1.8-.9a3.1 3.1 0 002.8-3.1c.8.2 1.2.9 1.2 1.8z"
                                    className="st7"
                                />
                                <path
                                    d="M1126.4 372.3a3.3 3.3 0 01-2.8 3.1c-.2-.4-.4-.8-.4-1.2 0-1.2 1-2.2 2.2-2.2l1 .3z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_37_">
                            <g id="branches_45_">
                                <path d="M749 317.9h3.2v22.2H749z" className="st6" />
                                <path d="M753 324.3h1v7h-1z" className="st6" transform="rotate(52.4 753.5 327.9)" />
                                <path d="M752.5 315.3h.8v10h-.8z" className="st6" transform="rotate(30 753 320.3)" />
                                <path
                                    d="M747.1 321.4h1.2v6.6h-1.2z"
                                    className="st6"
                                    transform="rotate(-37.6 747.6 324.7)"
                                />
                                <path
                                    d="M747.8 330.5h1.2v4.2h-1.2z"
                                    className="st6"
                                    transform="rotate(-60 748.4 332.6)"
                                />
                            </g>
                            <g id="feuilles_133_">
                                <path
                                    d="M760 309.7a9.8 9.8 0 01-18.8 3.8 9.8 9.8 0 019-13.6 9 9 0 014 .9 9.6 9.6 0 015.8 8.9z"
                                    className="st8"
                                />
                                <path
                                    d="M754.9 304.2a10 10 0 01-13.7 9.3 9.8 9.8 0 019-13.6 9 9 0 014 .9c.4 1 .7 2.2.7 3.4z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_132_">
                                <path
                                    d="M748.4 319.5c0 2.9-2.4 5.3-5.3 5.3-1.7 0-3.2-.8-4.2-2.1h.1a7 7 0 007-7v-.6c1.5.9 2.4 2.6 2.4 4.4z"
                                    className="st8"
                                />
                                <path
                                    d="M746 315.7a7 7 0 01-7 7h-.1c-.7-.9-1.1-2-1.1-3.2 0-2.9 2.4-5.3 5.3-5.3 1.1 0 2.1.3 2.9.9v.6z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_131_">
                                <path
                                    d="M763.4 323a5 5 0 01-8.8 3h.1c3.6 0 6.6-2.9 6.6-6.6v-.6c1.2 1 2.1 2.5 2.1 4.2z"
                                    className="st8"
                                />
                                <path
                                    d="M761.2 319.5c0 3.6-2.9 6.6-6.6 6.6h-.1a5 5 0 016.6-7.1l.1.5z"
                                    className="st12"
                                />
                            </g>
                        </g>
                        <g id="arbre_36_">
                            <g id="branches_10_">
                                <path d="M1283.9 407.7h3v17.2h-3z" className="st6" />
                                <path
                                    d="M1287.1 414.5h.9v4.8h-.9z"
                                    className="st6"
                                    transform="rotate(-135 1287.6 417)"
                                />
                                <path
                                    d="M1288.4 401.9h1.3v10.6h-1.3z"
                                    className="st6"
                                    transform="rotate(34.3 1289 407.1)"
                                />
                                <path
                                    d="M1288.4 402.3h1.2v5.3h-1.2z"
                                    className="st6"
                                    transform="rotate(4.8 1289 405) scale(.99996)"
                                />
                                <path
                                    d="M1288.9 406.6h.8v3.6h-.8z"
                                    className="st6"
                                    transform="scale(-1) rotate(55.1 783 -2472.2)"
                                />
                                <path d="M1281 405.4h2v11.3h-2z" className="st6" transform="rotate(-33.2 1282 411)" />
                                <path
                                    d="M1278.8 407.9h1.2v5.3h-1.2z"
                                    className="st6"
                                    transform="rotate(-69.5 1279.4 410.6)"
                                />
                            </g>
                            <g id="feuilles_130_">
                                <path
                                    d="M1281.6 402.9c0 2.5-2 4.5-4.5 4.5-1.5 0-2.8-.7-3.6-1.8h.1a6 6 0 006-6v-.5c1.2.8 2 2.2 2 3.8z"
                                    className="st19"
                                />
                                <path
                                    d="M1279.6 399.6a6 6 0 01-6 6h-.1a4.5 4.5 0 013.6-7.2c.9 0 1.7.3 2.5.7v.5z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_129_">
                                <path
                                    d="M1299 402c0 2.6-2.1 4.7-4.7 4.7-2 0-3.6-1.2-4.3-2.9-.2-.6-.4-1.2-.4-1.8a4.7 4.7 0 016.6-4.3c1.7.7 2.8 2.4 2.8 4.3z"
                                    className="st19"
                                />
                                <path
                                    d="M1296.6 399.4c0 2.7-2.1 4.8-4.8 4.8l-1.8-.3c-.2-.6-.4-1.2-.4-1.8a4.7 4.7 0 016.6-4.3c.2.4.4 1 .4 1.6z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_128_">
                                <path
                                    d="M1295.6 398.4a10.1 10.1 0 01-18.4 5.8c7.3-1 12.9-7.1 13.2-14.6 3 1.6 5.2 5 5.2 8.8z"
                                    className="st19"
                                />
                                <path
                                    d="M1290.3 389.5a15.3 15.3 0 01-13.2 14.6 10 10 0 0113.2-14.6z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_127_">
                                <path
                                    d="M1292.1 414.5c0 1.1-.9 2.1-2.1 2.1-.7 0-1.3-.3-1.6-.8 1.5 0 2.8-1.2 2.8-2.8v-.2c.5.3.9 1 .9 1.7z"
                                    className="st19"
                                />
                                <path
                                    d="M1291.2 413c0 1.5-1.2 2.8-2.8 2.8-.3-.3-.4-.8-.4-1.3a2.1 2.1 0 013.2-1.8v.3z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_126_">
                                <path
                                    d="M1294.3 406.6c0 1.3-1 2.3-2.3 2.3-.8 0-1.4-.4-1.8-.9 1.7 0 3.1-1.4 3.1-3.1v-.3c.6.5 1 1.2 1 2z"
                                    className="st19"
                                />
                                <path
                                    d="M1293.3 404.9c0 1.7-1.4 3.1-3.1 3.1-.3-.4-.5-.9-.5-1.4 0-1.3 1-2.3 2.3-2.3.5 0 .9.1 1.3.4v.2z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_125_">
                                <path
                                    d="M1278.1 408.8c0 1.4-1.1 2.5-2.5 2.5-.8 0-1.6-.4-2-1.1a3.7 3.7 0 003.2-3.6c.8.4 1.3 1.2 1.3 2.2z"
                                    className="st19"
                                />
                                <path
                                    d="M1276.8 406.6a4 4 0 01-3.2 3.6c-.3-.4-.4-.9-.4-1.4 0-1.4 1.1-2.5 2.5-2.5.4 0 .8.1 1.1.3z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_49_">
                            <g id="branches_44_">
                                <path d="M898 346.4h2.7v11.7H898z" className="st6" />
                                <path d="M900.6 349.7h.7v3.6h-.7z" className="st6" transform="rotate(37 900.9 351.5)" />
                                <path
                                    d="M897.4 350.7h.7v3.6h-.7z"
                                    className="st6"
                                    transform="rotate(-19.2 897.8 352.6)"
                                />
                            </g>
                            <g id="feuilles_124_">
                                <path
                                    d="M898.4 349.9c0 .9-.7 1.6-1.6 1.6-.5 0-1-.3-1.3-.7 1.2-.2 2.1-1.1 2.1-2.3.5.2.8.8.8 1.4z"
                                    className="st19"
                                />
                                <path
                                    d="M897.6 348.4c0 1.2-.9 2.2-2.1 2.3l-.3-.9c0-.9.7-1.6 1.6-1.6l.8.2z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_123_">
                                <path
                                    d="M905.5 346.7l-.2.2-.3.4-.2.2-.3.3-.3.2-.4.3c-.8.6-1.7 1.1-2.6 1.4l-.3.1-.3.1-.3.1-.5.1h-.2-1l-.5-.1h-.2l-.3-.1-.3-.1-.8-.3-.2-.1-.6-.3-.3-.2-.1-.1-.2-.1-.4-.3-.2-.1-.2-.2-.3-.2-.1-.1 1.1-.7c.9-.7 1.7-1.5 2.4-2.4l.5-.1a9.5 9.5 0 005-2.5 10.1 10.1 0 002.7-4.7l.4.3c2.5 1.8 2.1 5.9-.5 9z"
                                    className="st19"
                                />
                                <path
                                    d="M905.7 337.2a11 11 0 01-2.7 4.7 9.2 9.2 0 01-5.5 2.6c-.6.9-1.5 1.8-2.4 2.4l-1.1.7-.1-.1-.2-.2-.3-.3a10 10 0 01-2.3-4.1l-.1-.8c-.2-1.7.2-3.3 1.3-4.3l.3-.3.1-.1h.1l.1-.1.1-.1.1-.1h.1l.1-.1.2-.1.2-.1.2-.1v-.3-.2-.1-.2-.1l.1-.2.1-.3.1-.3.2-.6.1-.2.2-.4.1-.1.1-.2.1-.1v-.1l.1-.2v-.1l.1-.2.1-.1.1-.2.1-.2.2-.2.1-.1.2-.2.2-.1.2-.1.1-.1.1-.1.2-.1.2-.1.3-.1.2-.1.2-.1.2-.1h.1l.2-.1.2-.1H899.4l.5.1h.5l.2.1.1.1H901l.2.1.2.1.2.1v.7c0 1.7-.4 3.4-1.2 4.8 1.4-.6 2.8-.7 4-.3l.2.1.2.1.1.1.1.1.1.1.1.1.5.2z"
                                    className="st11"
                                />
                                <path
                                    d="M904.7 336.8a6.2 6.2 0 00-4 .3 9.8 9.8 0 001.2-4.8v-.7l.6.5.2.2.1.1.1.1.1.1.2.3.1.2.1.1v.1l.1.2.1.2.1.2.1.1.1.2.4.9.1.2.1.3V336.5c.2.1.2.2.2.3z"
                                    className="st19"
                                />
                            </g>
                            <g id="feuilles_122_">
                                <path
                                    d="M904.7 348.6c0 1.2-1 2.2-2.2 2.2-.7 0-1.4-.4-1.8-.9a3.1 3.1 0 002.8-3.1 2 2 0 011.2 1.8z"
                                    className="st19"
                                />
                                <path
                                    d="M903.6 346.7a3.3 3.3 0 01-2.8 3.1c-.2-.4-.4-.8-.4-1.2 0-1.2 1-2.2 2.2-2.2.3 0 .7.1 1 .3z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_35_">
                            <g id="branches_43_">
                                <path d="M950.9 342.2h3.5v20.6h-3.5z" className="st6" />
                                <path
                                    d="M955.9 342.1h2.2v15.3h-2.2z"
                                    className="st6"
                                    transform="rotate(32.7 957 349.7)"
                                />
                                <path
                                    d="M956.6 341.2h1.3v8.7h-1.3z"
                                    className="st6"
                                    transform="rotate(6.7 957.4 345.6)"
                                />
                                <path
                                    d="M947.3 338.5h2.2v12.7h-2.2z"
                                    className="st6"
                                    transform="rotate(-37.6 948.3 344.8)"
                                />
                            </g>
                            <g id="feuilles_121_">
                                <path
                                    d="M948.8 337.1a5.8 5.8 0 01-10.4 3.5h.1c4.3 0 7.7-3.5 7.7-7.7v-.7a6 6 0 012.6 4.9z"
                                    className="st15"
                                />
                                <path
                                    d="M946.2 332.9c0 4.3-3.5 7.7-7.7 7.7h-.1c-.7-1-1.2-2.2-1.2-3.5 0-3.2 2.6-5.8 5.8-5.8 1.2 0 2.3.3 3.2.9v.7z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_120_">
                                <path
                                    d="M967.6 338.6c0 3.5-2.8 6.3-6.3 6.3a6.4 6.4 0 01-5.8-3.8 6.3 6.3 0 015.8-8.7 6 6 0 016.3 6.2z"
                                    className="st16"
                                />
                                <path
                                    d="M964.3 335.1c0 3.5-2.9 6.4-6.4 6.4-.8 0-1.6-.2-2.4-.5a6.3 6.3 0 015.8-8.7c.9 0 1.8.2 2.6.5.2.7.4 1.5.4 2.3z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_119_">
                                <path
                                    d="M963 333.9a9.8 9.8 0 01-17.6 5.9h.2c7.2 0 13.1-5.9 13.1-13.1l-.1-1.2c2.6 1.9 4.4 4.9 4.4 8.4z"
                                    className="st15"
                                />
                                <path
                                    d="M958.6 326.8c0 7.2-5.9 13.1-13.1 13.1h-.2a9.8 9.8 0 0113.2-14.2l.1 1.1z"
                                    className="st9"
                                />
                            </g>
                        </g>
                        <g id="arbre_34_">
                            <g id="branches_42_">
                                <path d="M977.8 347.9h2.7v18.5h-2.7z" className="st6" />
                                <path
                                    d="M981.8 347.1h1.8v8.9h-1.8z"
                                    className="st6"
                                    transform="rotate(52.4 982.7 351.6)"
                                />
                                <path
                                    d="M970.2 346.9h1.4v8.7h-1.4z"
                                    className="st6"
                                    transform="rotate(-48.4 970.9 351.3)"
                                />
                                <path d="M974.1 345.6h1v5.5h-1z" className="st6" transform="rotate(27.7 974.5 348.3)" />
                                <path
                                    d="M974.1 346.4h1.7v20.2h-1.7z"
                                    className="st6"
                                    transform="rotate(-17.3 974.9 356.5)"
                                />
                                <path
                                    d="M973.6 354.4h.5v3.5h-.5z"
                                    className="st6"
                                    transform="rotate(-57.3 973.9 356.1)"
                                />
                                <path d="M983.3 346.1h1v5.5h-1z" className="st6" transform="rotate(27.7 983.7 348.9)" />
                            </g>
                            <g id="feuilles_118_">
                                <path
                                    d="M978.2 342.4c0 3.1-2.5 5.6-5.6 5.6a5.7 5.7 0 01-5.2-3.4c-.3-.7-.4-1.4-.4-2.2a5.6 5.6 0 1111.2 0z"
                                    className="st7"
                                />
                                <path
                                    d="M975.2 339.3a5.7 5.7 0 01-7.8 5.3c-.3-.7-.4-1.4-.4-2.2a5.6 5.6 0 017.9-5.1c.2.7.3 1.3.3 2z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_117_">
                                <path
                                    d="M969.8 347.1c0 2.1-1.7 3.7-3.7 3.7-1.6 0-2.9-.9-3.5-2.3-.2-.4-.3-.9-.3-1.5 0-2.1 1.7-3.7 3.7-3.7.5 0 1.1.1 1.5.3a4 4 0 012.3 3.5z"
                                    className="st7"
                                />
                                <path
                                    d="M967.8 345c0 2.1-1.7 3.8-3.8 3.8-.5 0-1-.1-1.4-.3-.2-.4-.3-.9-.3-1.5 0-2.1 1.7-3.7 3.7-3.7.5 0 1.1.1 1.5.3.2.5.3.9.3 1.4z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_116_">
                                <path
                                    d="M988.4 339.6a9.2 9.2 0 01-16.8 5.2 14 14 0 0012-13.4 9.5 9.5 0 014.8 8.2z"
                                    className="st18"
                                />
                                <path
                                    d="M983.7 331.5a14.2 14.2 0 01-12 13.4 9.2 9.2 0 017.5-14.5c1.6-.1 3.1.4 4.5 1.1z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_115_">
                                <path
                                    d="M995.6 345a6 6 0 01-10.6 3.6h.1c4.3 0 7.8-3.5 7.8-7.8v-.7a6 6 0 012.7 4.9z"
                                    className="st11"
                                />
                                <path
                                    d="M992.9 340.8c0 4.3-3.5 7.8-7.8 7.8h-.1a6 6 0 014.7-9.5c1.2 0 2.3.3 3.2.9v.8z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_33_">
                            <g id="branches_9_">
                                <path d="M1065.9 358.6h3v17.2h-3z" className="st6" />
                                <path
                                    d="M1069 365.4h.9v4.8h-.9z"
                                    className="st6"
                                    transform="rotate(-135 1069.5 367.8)"
                                />
                                <path
                                    d="M1070.3 352.8h1.3v10.6h-1.3z"
                                    className="st6"
                                    transform="rotate(34.3 1070.9 358)"
                                />
                                <path
                                    d="M1070.3 353.2h1.2v5.3h-1.2z"
                                    className="st6"
                                    transform="rotate(4.8 1070.9 355.9) scale(.99996)"
                                />
                                <path
                                    d="M1070.8 357.5h.8v3.6h-.8z"
                                    className="st6"
                                    transform="scale(-1) rotate(55.1 689 -2054)"
                                />
                                <path d="M1062.9 356.3h2v11.3h-2z" className="st6" transform="rotate(-33.2 1064 362)" />
                                <path
                                    d="M1060.7 358.8h1.2v5.3h-1.2z"
                                    className="st6"
                                    transform="rotate(-69.5 1061.3 361.5)"
                                />
                            </g>
                            <g id="feuilles_114_">
                                <path
                                    d="M1063.5 353.8c0 2.5-2 4.5-4.5 4.5-1.5 0-2.8-.7-3.6-1.8h.1a6 6 0 006-6v-.5c1.2.8 2 2.2 2 3.8z"
                                    className="st15"
                                />
                                <path
                                    d="M1061.5 350.5a6 6 0 01-6 6h-.1a4.5 4.5 0 013.6-7.2c.9 0 1.7.3 2.5.7v.5z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_113_">
                                <path
                                    d="M1080.9 352.9c0 2.6-2.1 4.7-4.7 4.7-2 0-3.6-1.2-4.3-2.9-.2-.6-.4-1.2-.4-1.8a4.7 4.7 0 016.6-4.3c1.7.7 2.8 2.4 2.8 4.3z"
                                    className="st16"
                                />
                                <path
                                    d="M1078.5 350.3c0 2.7-2.1 4.8-4.8 4.8l-1.8-.3c-.2-.6-.4-1.2-.4-1.8a4.7 4.7 0 016.6-4.3c.2.4.4 1 .4 1.6z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_112_">
                                <path
                                    d="M1077.5 349.3a10.1 10.1 0 01-18.4 5.8c7.3-1 12.9-7.1 13.2-14.6 3 1.6 5.2 5 5.2 8.8z"
                                    className="st17"
                                />
                                <path d="M1072.2 340.4A15.3 15.3 0 011059 355a10 10 0 0113.2-14.6z" className="st14" />
                            </g>
                            <g id="feuilles_111_">
                                <path
                                    d="M1074 365.4c0 1.1-.9 2.1-2.1 2.1-.7 0-1.3-.3-1.6-.8 1.5 0 2.8-1.2 2.8-2.8v-.2c.5.3.9 1 .9 1.7z"
                                    className="st11"
                                />
                                <path
                                    d="M1073.1 363.9c0 1.5-1.2 2.8-2.8 2.8-.3-.3-.4-.8-.4-1.3a2.1 2.1 0 013.2-1.8v.3z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_110_">
                                <path
                                    d="M1076.2 357.5c0 1.3-1 2.3-2.3 2.3-.8 0-1.4-.4-1.8-.9 1.7 0 3.1-1.4 3.1-3.1v-.3c.6.5 1 1.2 1 2z"
                                    className="st15"
                                />
                                <path
                                    d="M1075.2 355.8c0 1.7-1.4 3.1-3.1 3.1-.3-.4-.5-.9-.5-1.4 0-1.3 1-2.3 2.3-2.3.5 0 .9.1 1.3.4v.2z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_109_">
                                <path
                                    d="M1060 359.7c0 1.4-1.1 2.5-2.5 2.5-.8 0-1.6-.4-2-1.1a3.7 3.7 0 003.2-3.6c.8.4 1.3 1.2 1.3 2.2z"
                                    className="st17"
                                />
                                <path
                                    d="M1058.7 357.5a4 4 0 01-3.2 3.6c-.3-.4-.4-.9-.4-1.4 0-1.4 1.1-2.5 2.5-2.5.4 0 .8.1 1.1.3z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_48_">
                            <path id="branches_41_" d="M796.3 334.2h4.2v10.7h-4.2z" className="st2" />
                            <g id="feuilles_108_">
                                <path
                                    d="M805.3 333.3c0 1.2-.7 2.1-1.6 2.1h-10.9c-.3 0-.5-.1-.8-.3 6.7-4.4 11-11.6 11-19.7l-.1-2.2c1.4 6.5 2.2 13.2 2.4 20.1z"
                                    className="st19"
                                />
                                <path
                                    d="M803 315.5c0 8.1-4.3 15.2-11 19.7-.5-.4-.8-1-.8-1.8a100 100 0 015.6-31.5c.2-.5.4-.8.8-1 .7-.4 1.7-.1 2.1 1a73 73 0 013.3 11.5v2.1z"
                                    className="st11"
                                />
                                <g id="fruits_5_">
                                    <circle id="fruit_35_" cx="796.5" cy="312.4" r=".6" className="st14" />
                                    <circle id="fruit_34_" cx="802.1" cy="324.2" r=".6" className="st14" />
                                    <circle id="fruit_33_" cx="800.3" cy="332.3" r=".6" className="st14" />
                                    <circle id="fruit_32_" cx="798.9" cy="318.5" r=".6" className="st14" />
                                    <circle id="fruit_31_" cx="798.6" cy="306.6" r=".6" className="st14" />
                                    <circle id="fruit_30_" cx="794.5" cy="327.9" r=".6" className="st14" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <path
                        id="vert_clair"
                        d="M585.1 299.4c249.9 76.5 489.6 45.3 708.7 126.5 268.5 99.5 323.5 74.7 481.8 74.8v24.7H587.4l-2.3-226z"
                        className="st11"
                    />
                    <g id="végétation_premier_plan">
                        <g id="arbre_30_">
                            <g id="branches_40_">
                                <path d="M755.3 319.4h3.8v21.7h-3.8z" className="st13" />
                                <path
                                    d="M751.4 326.7h6.9v1.5h-6.9z"
                                    className="st13"
                                    transform="rotate(39 754.9 327.5)"
                                />
                                <path
                                    d="M756.7 332.9h6.9v1.5h-6.9z"
                                    className="st13"
                                    transform="rotate(-39.4 760.2 333.7)"
                                />
                            </g>
                            <g id="feuilles_107_">
                                <path
                                    d="M767.8 299.3V310a10.7 10.7 0 01-18.9 6.9 26 26 0 0018.5-20.5c.3.9.4 1.9.4 2.9z"
                                    className="st7"
                                />
                                <path
                                    d="M767.4 296.4a26 26 0 01-18.5 20.5 10.6 10.6 0 01-2.5-6.9v-10.7a10.7 10.7 0 0121-2.9z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_106_">
                                <path
                                    d="M753.7 323.6a4.2 4.2 0 01-7.7 2.4c3-.4 5.4-3 5.5-6.1a4 4 0 012.2 3.7z"
                                    className="st20"
                                />
                                <path
                                    d="M751.6 319.9a6.4 6.4 0 01-5.5 6.1 4 4 0 01-.8-2.4c0-2.3 1.9-4.2 4.2-4.2.8 0 1.4.1 2.1.5z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_29_">
                            <g id="branches_39_">
                                <path d="M1169.8 361.8h4.7v32.1h-4.7z" className="st6" />
                                <path
                                    d="M1176.7 359.9h3.1v15.4h-3.1z"
                                    className="st6"
                                    transform="rotate(52.4 1178.3 367.7)"
                                />
                                <path
                                    d="M1178.9 355.2h1.2v14.5h-1.2z"
                                    className="st6"
                                    transform="rotate(30 1179.5 362.4)"
                                />
                                <path
                                    d="M1163.4 361.4h1.7v9.5h-1.7z"
                                    className="st6"
                                    transform="rotate(-11.4 1164.3 366.3)"
                                />
                                <path
                                    d="M1164.5 365.4h3v13.8h-3z"
                                    className="st6"
                                    transform="rotate(-48.4 1166 372.3)"
                                />
                                <path
                                    d="M1173.7 379.1h.9v6.1h-.9z"
                                    className="st6"
                                    transform="rotate(52.4 1174.2 382.2)"
                                />
                            </g>
                            <g id="feuilles_105_">
                                <path
                                    d="M1164.7 361.5a9.6 9.6 0 01-17.3 5.8h.2c7.1 0 12.8-5.7 12.8-12.8v-1.1a9.8 9.8 0 014.3 8.1z"
                                    className="st19"
                                />
                                <path
                                    d="M1160.4 354.6c0 7.1-5.7 12.8-12.8 12.8h-.2a9.6 9.6 0 0112.9-13.8l.1 1z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_104_">
                                <path d="M1187.1 347.7a15.4 15.4 0 01-29.6 6 15.4 15.4 0 1129.6-6z" className="st19" />
                                <path
                                    d="M1179 339.1a15.7 15.7 0 01-21.5 14.6 15.4 15.4 0 0120.5-20.1c.7 1.7 1 3.6 1 5.5z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_103_">
                                <path
                                    d="M1194.3 357.9a7.8 7.8 0 01-14 4.7h.1c5.7 0 10.4-4.6 10.4-10.4v-.9a7.9 7.9 0 013.5 6.6z"
                                    className="st19"
                                />
                                <path
                                    d="M1190.8 352.2c0 5.7-4.6 10.4-10.4 10.4h-.1a7.8 7.8 0 0110.5-11.2v.8z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_28_">
                            <g id="branches_38_">
                                <path d="M1056.8 350.4h4.7v26.5h-4.7z" className="st13" />
                                <path
                                    d="M1052 359.3h8.5v1.9h-8.5z"
                                    className="st13"
                                    transform="rotate(39 1056.3 360.3)"
                                />
                                <path
                                    d="M1058.5 367h8.5v1.9h-8.5z"
                                    className="st13"
                                    transform="rotate(-39.4 1062.8 367.9)"
                                />
                            </g>
                            <g id="feuilles_102_">
                                <path
                                    d="M1072.1 325.8v13.1a13.1 13.1 0 01-23.1 8.5 31.8 31.8 0 0022.6-25.1c.4 1.1.5 2.3.5 3.5z"
                                    className="st17"
                                />
                                <path
                                    d="M1071.7 322.3a31.8 31.8 0 01-22.6 25.1 13.2 13.2 0 01-3.1-8.5v-13.1c0-7.2 5.9-13.1 13.1-13.1 6 0 11 4.1 12.6 9.6z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_101_">
                                <path
                                    d="M1054.9 355.6c0 2.9-2.3 5.2-5.2 5.2-1.8 0-3.3-.9-4.3-2.2a8 8 0 006.8-7.5 5 5 0 012.7 4.5z"
                                    className="st17"
                                />
                                <path
                                    d="M1052.2 351c-.1 3.8-3 7-6.8 7.5a5 5 0 01-.9-3c0-2.9 2.3-5.2 5.2-5.2.9.1 1.8.3 2.5.7z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_47_">
                            <g id="branches_37_">
                                <path d="M1081.6 358.8h4.8v20.3h-4.8z" className="st6" />
                                <path
                                    d="M1086.1 364.5h1.2v6.3h-1.2z"
                                    className="st6"
                                    transform="rotate(37 1086.6 367.6)"
                                />
                                <path
                                    d="M1080.6 366.2h1.2v6.3h-1.2z"
                                    className="st6"
                                    transform="rotate(-19.2 1081.2 369.4)"
                                />
                            </g>
                            <g id="feuilles_100_">
                                <path
                                    d="M1082.3 364.7c0 1.5-1.3 2.8-2.8 2.8-1 0-1.8-.5-2.3-1.2 2-.3 3.6-2 3.7-4 .9.4 1.4 1.3 1.4 2.4z"
                                    className="st7"
                                />
                                <path
                                    d="M1080.9 362.2a4.1 4.1 0 01-3.7 4 3 3 0 01-.5-1.6c0-1.5 1.3-2.8 2.8-2.8l1.4.4z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_99_">
                                <path
                                    d="M1094.6 359.3l-.3.3-.6.6-.3.3-.5.4-.5.4-.6.5c-1.4 1.1-3 1.9-4.5 2.4l-.6.2-.4.1-.6.1-.8.1h-2l-.9-.2-.3-.1-.5-.1-.5-.2-1.4-.6-.3-.1-1.1-.6-.5-.3-.2-.1-.3-.2-.6-.5-.3-.2-.4-.3-.4-.4-.2-.1h-.1a16.4 16.4 0 006.1-5.4l.8-.1a16 16 0 008.6-4.3 16.5 16.5 0 004.7-8.1l.7.5c4 3.4 3.4 10.5-1.2 16z"
                                    className="st7"
                                />
                                <path
                                    d="M1095.1 342.8a16.5 16.5 0 01-4.7 8.1 15.4 15.4 0 01-8.6 4.3l-.8.1a16.4 16.4 0 01-4.1 4.2l-2 1.2-.3-.2-.4-.4-.5-.5a16.5 16.5 0 01-4.1-8.5c-.4-3 .4-5.7 2.3-7.5l.5-.4.2-.2.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1.3-.1.4-.1.4-.1.1-.5v-.1l.1-.4v-.2l.1-.4v-.1l.1-.4.1-.5.2-.5.4-1 .1-.3.3-.7.1-.2.2-.3.1-.2.1-.1.2-.3.1-.1.2-.3.2-.2.2-.3.2-.3.3-.3.1-.1.3-.3.3-.3.3-.2.2-.2.2-.1.3-.2.1-.1.3-.2.5-.2.3-.2.4-.2.4-.1h.1l.4-.1.4-.1h.1l.4-.1H1084.5l.8.1h.1l.3.1h.1l.3.1.4.1.2.1.2.1h.1l.2.1.4.2.4.3.3.2v1.2c0 3-.8 5.9-2.1 8.3 2.4-1 4.8-1.2 6.9-.6l.3.1.3.1.2.1.2.1.2.1.2.1.6.2z"
                                    className="st11"
                                />
                                <path
                                    d="M1093.3 342c-2.1-.6-4.5-.4-6.9.6a18 18 0 002.1-8.3v-1.2l1 .8.3.3.1.1.2.2.2.2.2.2.4.5.2.3.2.2.1.1.2.3.1.1.2.3.2.4.1.3.1.3.6 1.6.1.4.1.5.1.3.1.2.1.3v.2l.1.4c-.3 0-.2.2-.2.4z"
                                    className="st7"
                                />
                            </g>
                            <g id="feuilles_98_">
                                <path
                                    d="M1093.2 362.5a3.8 3.8 0 01-6.9 2.2 5.8 5.8 0 004.9-5.5c1.3.7 2 1.9 2 3.3z"
                                    className="st7"
                                />
                                <path
                                    d="M1091.3 359.2a5.8 5.8 0 01-4.9 5.5 3.8 3.8 0 013.1-6c.6.1 1.3.2 1.8.5z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_27_">
                            <g id="branches_36_">
                                <path d="M1490.9 456.1h6V491h-6z" className="st6" />
                                <path
                                    d="M1499.3 455.8h3.8v25.9h-3.8z"
                                    className="st6"
                                    transform="rotate(32.7 1501.2 468.9)"
                                />
                                <path
                                    d="M1500.5 454.4h2.2v14.7h-2.2z"
                                    className="st6"
                                    transform="rotate(6.7 1501.9 461.8)"
                                />
                                <path
                                    d="M1484.6 449.7h3.8v21.6h-3.8z"
                                    className="st6"
                                    transform="rotate(-37.6 1486.4 460.5)"
                                />
                            </g>
                            <g id="feuilles_97_">
                                <path
                                    d="M1487.3 447.5c0 5.5-4.4 9.9-9.9 9.9a10 10 0 01-7.9-3.9h.2c7.2 0 13.1-5.9 13.1-13.1l-.1-1.2a9.7 9.7 0 014.6 8.3z"
                                    className="st7"
                                />
                                <path
                                    d="M1482.9 440.3c0 7.2-5.9 13.1-13.1 13.1h-.2a9.6 9.6 0 01-2-6 9.9 9.9 0 0115.3-8.3v1.2z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_96_">
                                <path
                                    d="M1519.1 449.9a10.6 10.6 0 01-20.4 4.1 10.6 10.6 0 1120.4-4.1z"
                                    className="st7"
                                />
                                <path
                                    d="M1513.5 444a10.8 10.8 0 01-14.8 10 10.6 10.6 0 0114.1-13.8c.5 1.2.7 2.4.7 3.8z"
                                    className="st8"
                                />
                            </g>
                            <g id="feuilles_95_">
                                <path
                                    d="M1511.4 442a16.7 16.7 0 01-30 10.1h.3c12.3 0 22.2-9.9 22.2-22.2l-.1-2c4.5 3.1 7.6 8.2 7.6 14.1z"
                                    className="st7"
                                />
                                <path
                                    d="M1503.8 429.9c0 12.3-9.9 22.2-22.2 22.2h-.3a16.7 16.7 0 0122.4-24.1l.1 1.9z"
                                    className="st8"
                                />
                            </g>
                        </g>
                        <g id="arbre_26_">
                            <g id="branches_35_">
                                <path d="M1296.2 392.9h5.6v38.5h-5.6z" className="st6" />
                                <path
                                    d="M1303.2 404.1h1.8v12.1h-1.8z"
                                    className="st6"
                                    transform="rotate(52.4 1304 410.2)"
                                />
                                <path
                                    d="M1302.3 388.4h1.4v17.4h-1.4z"
                                    className="st6"
                                    transform="rotate(30 1303 397)"
                                />
                                <path
                                    d="M1292.9 399.1h2v11.5h-2z"
                                    className="st6"
                                    transform="rotate(-37.6 1293.8 404.8)"
                                />
                                <path
                                    d="M1294.2 414.8h2v7.2h-2z"
                                    className="st6"
                                    transform="rotate(-60 1295.2 418.4)"
                                />
                            </g>
                            <g id="feuilles_94_">
                                <path d="M1315.4 378.7a17 17 0 01-32.7 6.6 17 17 0 1132.7-6.6z" className="st8" />
                                <path
                                    d="M1306.4 369.2a17.3 17.3 0 01-23.7 16.1 17 17 0 0122.6-22.1c.7 1.8 1.1 3.9 1.1 6z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_93_">
                                <path
                                    d="M1295.3 395.7a9.2 9.2 0 01-16.5 5.6h.2c6.7 0 12.2-5.5 12.2-12.2V388a9.5 9.5 0 014.1 7.7z"
                                    className="st8"
                                />
                                <path
                                    d="M1291.1 389.1c0 6.7-5.5 12.2-12.2 12.2h-.2A9.2 9.2 0 011291 388l.1 1.1z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_92_">
                                <path
                                    d="M1321.3 401.8a8.6 8.6 0 01-15.4 5.2h.1c6.3 0 11.4-5.1 11.4-11.4v-1a8.8 8.8 0 013.9 7.2z"
                                    className="st8"
                                />
                                <path
                                    d="M1317.4 395.6c0 6.3-5.1 11.4-11.4 11.4h-.1a8.6 8.6 0 0111.5-12.4v1z"
                                    className="st12"
                                />
                            </g>
                        </g>
                        <g id="arbre_24_">
                            <g id="branches_8_">
                                <path d="M703.9 302.3h5.2v29.8h-5.2z" className="st6" />
                                <path
                                    d="M709.4 314.2h1.6v8.3h-1.6z"
                                    className="st6"
                                    transform="rotate(-135 710.2 318.3)"
                                />
                                <path
                                    d="M711.6 292.2h2.3v18.3h-2.3z"
                                    className="st6"
                                    transform="rotate(34.3 712.7 301.4)"
                                />
                                <path
                                    d="M711.7 293h2.1v9.2h-2.1z"
                                    className="st6"
                                    transform="rotate(4.8 712.7 297.5) scale(.99996)"
                                />
                                <path
                                    d="M712.5 300.3h1.4v6.3h-1.4z"
                                    className="st6"
                                    transform="scale(-1) rotate(55.1 582 -1367.5)"
                                />
                                <path
                                    d="M698.8 298.3h3.4v19.5h-3.4z"
                                    className="st6"
                                    transform="rotate(-33.2 700.5 308.1)"
                                />
                                <path d="M695 302.6h2.1v9.2H695z" className="st6" transform="rotate(-69.5 696 307.2)" />
                            </g>
                            <g id="feuilles_91_">
                                <path
                                    d="M699.8 293.9a7.8 7.8 0 01-14 4.7h.1c5.7 0 10.4-4.6 10.4-10.4v-.9a8.1 8.1 0 013.5 6.6z"
                                    className="st19"
                                />
                                <path
                                    d="M696.3 288.3c0 5.7-4.6 10.4-10.4 10.4h-.1a7.8 7.8 0 0110.5-11.2v.8z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_90_">
                                <path
                                    d="M730.1 292.4c0 4.5-3.7 8.2-8.2 8.2a8.1 8.1 0 01-7.5-5 8.2 8.2 0 0110.9-10.7 8.3 8.3 0 014.8 7.5z"
                                    className="st19"
                                />
                                <path
                                    d="M725.8 287.9a8.3 8.3 0 01-11.4 7.7 8.2 8.2 0 0110.9-10.7c.3.9.5 1.9.5 3z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_89_">
                                <path
                                    d="M724.1 286.1a17.5 17.5 0 01-31.9 10 26.7 26.7 0 0022.9-25.4c5.3 3 9 8.8 9 15.4z"
                                    className="st19"
                                />
                                <path
                                    d="M715 270.8a26.7 26.7 0 01-22.9 25.4 17.5 17.5 0 0114.4-27.5c3.1-.1 6 .7 8.5 2.1z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_88_">
                                <path
                                    d="M718.1 314.1c0 2-1.6 3.6-3.6 3.6-1.2 0-2.2-.6-2.9-1.4h.1c2.6 0 4.8-2.1 4.8-4.8v-.4c.9.6 1.6 1.7 1.6 3z"
                                    className="st19"
                                />
                                <path
                                    d="M716.4 311.5c0 2.6-2.1 4.8-4.8 4.8h-.1c-.5-.6-.7-1.4-.7-2.2a3.6 3.6 0 015.6-3v.4z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_87_">
                                <path
                                    d="M721.9 300.4a4 4 0 01-7.2 2.4h.1c3 0 5.4-2.4 5.4-5.4v-.5c1 .9 1.7 2.1 1.7 3.5z"
                                    className="st19"
                                />
                                <path
                                    d="M720.1 297.5c0 3-2.4 5.4-5.4 5.4h-.1a4 4 0 013.2-6.4 4 4 0 012.2.6l.1.4z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_86_">
                                <path
                                    d="M693.8 304.2c0 2.4-1.9 4.3-4.3 4.3a4 4 0 01-3.5-1.8 6.5 6.5 0 005.6-6.2 4 4 0 012.2 3.7z"
                                    className="st19"
                                />
                                <path
                                    d="M691.6 300.4a6.4 6.4 0 01-5.6 6.2c-.5-.7-.8-1.6-.8-2.5 0-2.4 1.9-4.3 4.3-4.3.8.1 1.5.3 2.1.6z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_46_">
                            <g id="branches_34_">
                                <path d="M775.1 324.2h4.8v20.3h-4.8z" className="st6" />
                                <path d="M779.6 329.9h1.2v6.3h-1.2z" className="st6" transform="rotate(37 780.1 333)" />
                                <path
                                    d="M774.1 331.6h1.2v6.3h-1.2z"
                                    className="st6"
                                    transform="rotate(-19.2 774.7 334.8)"
                                />
                            </g>
                            <g id="feuilles_85_">
                                <path
                                    d="M775.8 330.1c0 1.5-1.3 2.8-2.8 2.8-1 0-1.8-.5-2.3-1.2 2-.3 3.6-2 3.7-4a3 3 0 011.4 2.4z"
                                    className="st19"
                                />
                                <path
                                    d="M774.4 327.7a4.1 4.1 0 01-3.7 4 3 3 0 01-.5-1.6c0-1.5 1.3-2.8 2.8-2.8.5 0 1 .2 1.4.4z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_84_">
                                <path
                                    d="M788.1 324.7l-.3.3-.6.6-.3.3-.5.4-.5.4-.6.5c-1.4 1.1-3 1.9-4.5 2.4l-.6.2-.4.1-.6.1-.8.1h-2l-.9-.2-.3-.1-.5-.1-.5-.2-1.4-.6-.3-.1-1.1-.6-.5-.3-.2-.1-.3-.2-.6-.5-.3-.2-.4-.3-.4-.4-.2-.1h-.1a16.4 16.4 0 006.1-5.4l.8-.1a16 16 0 008.6-4.3 16.5 16.5 0 004.7-8.1l.7.5c4 3.4 3.4 10.6-1.2 16z"
                                    className="st19"
                                />
                                <path
                                    d="M788.6 308.2a16.5 16.5 0 01-4.7 8.1 15.4 15.4 0 01-8.6 4.3l-.8.1a16.4 16.4 0 01-4.1 4.2l-2 1.2-.3-.2-.4-.4-.5-.5a16.5 16.5 0 01-4.1-8.5c-.4-3 .4-5.7 2.3-7.5l.5-.4.2-.2.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1.3-.1.4-.1.4-.1.1-.5v-.1l.1-.4v-.2l.1-.4v-.1l.1-.4.1-.5.2-.5.4-1 .1-.3.3-.7.1-.2.2-.3.1-.2.1-.1.2-.3.1-.1.2-.3.2-.2.2-.3.2-.3.3-.3.1-.1.3-.3.3-.3.3-.2.2-.2.2-.1.3-.2.1-.1.3-.2.5-.2.3-.2.4-.2.4-.1h.1l.4-.1.4-.1h.1l.4-.1H778l.8.1h.1l.3.1h.1l.3.1.4.1.2.1.2.1h.1l.2.1.4.2.4.3.3.2v1.2c0 3-.8 5.9-2.1 8.3 2.4-1 4.8-1.2 6.9-.6l.3.1.3.1.2.1.2.1.2.1.2.1.6.2z"
                                    className="st11"
                                />
                                <path
                                    d="M786.8 307.4c-2.1-.6-4.5-.4-6.9.6a18 18 0 002.1-8.3v-1.2l1 .8.3.3.1.1.2.2.2.2.2.2.4.5.2.3.2.2.1.1.2.3.1.1.2.3.2.4.1.3.1.3.6 1.6.1.4.1.5.1.3.1.2.1.3v.2l.1.4c-.3 0-.3.2-.2.4z"
                                    className="st19"
                                />
                            </g>
                            <g id="feuilles_83_">
                                <path
                                    d="M786.7 328a3.8 3.8 0 01-6.9 2.2 5.8 5.8 0 004.9-5.5c1.2.6 2 1.9 2 3.3z"
                                    className="st19"
                                />
                                <path
                                    d="M784.8 324.7a5.8 5.8 0 01-4.9 5.5 3.8 3.8 0 013.1-6 4 4 0 011.8.5z"
                                    className="st11"
                                />
                            </g>
                        </g>
                        <g id="arbre_21_">
                            <g id="branches_33_">
                                <path d="M1109.9 348.3h6.1V384h-6.1z" className="st6" />
                                <path
                                    d="M1118.5 348.1h3.9v26.5h-3.9z"
                                    className="st6"
                                    transform="rotate(32.7 1120.5 361.4)"
                                />
                                <path
                                    d="M1119.8 346.6h2.2v15h-2.2z"
                                    className="st6"
                                    transform="rotate(6.7 1121 354.2)"
                                />
                                <path
                                    d="M1103.6 341.9h3.9V364h-3.9z"
                                    className="st6"
                                    transform="rotate(-37.6 1105.4 352.9)"
                                />
                            </g>
                            <g id="feuilles_82_">
                                <path
                                    d="M1106.3 339.5a10 10 0 01-18.1 6.1h.2c7.4 0 13.4-6 13.4-13.4l-.1-1.2c2.7 1.8 4.6 5 4.6 8.5z"
                                    className="st15"
                                />
                                <path
                                    d="M1101.7 332.2c0 7.4-6 13.4-13.4 13.4h-.2a10 10 0 0113.5-14.6l.1 1.2z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_81_">
                                <path
                                    d="M1138.8 342a11 11 0 01-20.9 4.3 9.9 9.9 0 01-.9-4.2 11 11 0 0121.8-.1z"
                                    className="st16"
                                />
                                <path
                                    d="M1133.1 336a11.1 11.1 0 01-15.2 10.3 9.9 9.9 0 01-.9-4.2 11 11 0 0115.3-10c.5 1.2.8 2.5.8 3.9z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_80_">
                                <path
                                    d="M1130.9 333.9a17 17 0 01-30.7 10.4h.3a22.7 22.7 0 0022.7-22.7l-.1-2a17 17 0 017.8 14.3z"
                                    className="st15"
                                />
                                <path
                                    d="M1123.2 321.6a22.7 22.7 0 01-22.7 22.7h-.3a17 17 0 0122.9-24.6l.1 1.9z"
                                    className="st9"
                                />
                            </g>
                        </g>
                        <g id="arbre_43_">
                            <path id="branches_32_" d="M959.6 350.5h5.8V365h-5.8z" className="st2" />
                            <g id="feuilles_79_">
                                <path
                                    d="M971.8 349.3c0 1.6-.9 2.9-2.1 2.9h-14.9c-.4 0-.7-.1-1-.4 9.1-6.1 15-15.8 15-26.8l-.1-3c1.8 8.7 3 17.9 3.1 27.3z"
                                    className="st3"
                                />
                                <path
                                    d="M968.8 325c0 11-5.9 20.8-15 26.8-.7-.5-1.1-1.4-1.1-2.5.3-15.3 3-29.9 7.7-43 .2-.7.6-1.1 1-1.4 1-.6 2.3-.1 2.8 1.4l2.2 6.8 2.3 8.9.1 3z"
                                    className="st4"
                                />
                                <g id="fruits_4_">
                                    <circle id="fruit_29_" cx="959.8" cy="320.8" r=".8" className="st5" />
                                    <circle id="fruit_27_" cx="967.5" cy="336.8" r=".8" className="st5" />
                                    <circle id="fruit_28_" cx="965" cy="347.9" r=".8" className="st5" />
                                    <circle id="fruit_26_" cx="963.2" cy="329" r=".8" className="st5" />
                                    <circle id="fruit_25_" cx="962.8" cy="312.8" r=".8" className="st5" />
                                    <circle id="fruit_24_" cx="957.2" cy="342" r=".8" className="st5" />
                                </g>
                            </g>
                        </g>
                        <g id="arbre_19_">
                            <g id="branches_31_">
                                <path d="M1183.6 364.7h4.7v32.1h-4.7z" className="st6" />
                                <path
                                    d="M1190.5 363.3h3.1v15.4h-3.1z"
                                    className="st6"
                                    transform="rotate(52.4 1192.1 371.1)"
                                />
                                <path
                                    d="M1170.4 363.1h2.4v15.1h-2.4z"
                                    className="st6"
                                    transform="rotate(-48.4 1171.6 370.6)"
                                />
                                <path
                                    d="M1177.1 360.7h1.7v9.5h-1.7z"
                                    className="st6"
                                    transform="rotate(27.7 1178 365.5)"
                                />
                                <path
                                    d="M1177.2 362.2h3v35h-3z"
                                    className="st6"
                                    transform="rotate(-17.3 1178.5 379.7)"
                                />
                                <path
                                    d="M1176.3 375.9h.9v6.1h-.9z"
                                    className="st6"
                                    transform="rotate(-57.3 1176.7 379)"
                                />
                                <path
                                    d="M1193.1 361.7h1.7v9.5h-1.7z"
                                    className="st6"
                                    transform="rotate(27.7 1193.9 366.4)"
                                />
                            </g>
                            <g id="feuilles_78_">
                                <path
                                    d="M1184.3 355.3c0 5.4-4.4 9.7-9.7 9.7a10 10 0 01-9-5.9 9.7 9.7 0 018.9-13.5 9.7 9.7 0 019.8 9.7z"
                                    className="st7"
                                />
                                <path
                                    d="M1179.1 349.9a9.9 9.9 0 01-13.6 9.2 9.7 9.7 0 018.9-13.5c1.4 0 2.8.3 4 .8.5 1.1.7 2.2.7 3.5z"
                                    className="st11"
                                />
                            </g>
                            <g id="feuilles_77_">
                                <path d="M1169.6 363.3a6.5 6.5 0 01-12.5 2.5 6.5 6.5 0 1112.5-2.5z" className="st7" />
                                <path
                                    d="M1166.2 359.7a6.6 6.6 0 01-9.1 6.1 6.5 6.5 0 018.7-8.4c.2.7.4 1.5.4 2.3z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_76_">
                                <path
                                    d="M1202 350.3a16 16 0 01-29.2 9.1 24.4 24.4 0 0020.9-23.2c5 2.8 8.3 8 8.3 14.1z"
                                    className="st18"
                                />
                                <path
                                    d="M1193.7 336.2a24.5 24.5 0 01-20.9 23.2 16 16 0 0120.9-23.2z"
                                    className="st12"
                                />
                            </g>
                            <g id="feuilles_75_">
                                <path
                                    d="M1214.4 359.8c0 5.6-4.6 10.2-10.2 10.2-3.3 0-6.2-1.6-8.1-4h.2c7.5 0 13.5-6.1 13.5-13.5l-.1-1.2a10 10 0 014.7 8.5z"
                                    className="st11"
                                />
                                <path
                                    d="M1209.8 352.4c0 7.5-6.1 13.5-13.5 13.5h-.2a10.2 10.2 0 0113.6-14.8l.1 1.3z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_18_">
                            <g id="branches_6_">
                                <path d="M1247.8 384.3h5.2v29.8h-5.2z" className="st6" />
                                <path
                                    d="M1253.3 396.2h1.6v8.3h-1.6z"
                                    className="st6"
                                    transform="rotate(-135 1254 400.4)"
                                />
                                <path
                                    d="M1255.5 374.3h2.3v18.3h-2.3z"
                                    className="st6"
                                    transform="rotate(34.3 1256.5 383.4)"
                                />
                                <path
                                    d="M1255.6 375h2.1v9.2h-2.1z"
                                    className="st6"
                                    transform="rotate(4.8 1256.5 379.6) scale(.99996)"
                                />
                                <path
                                    d="M1256.3 382.4h1.4v6.3h-1.4z"
                                    className="st6"
                                    transform="scale(-1) rotate(55.1 739.3 -2410.3)"
                                />
                                <path
                                    d="M1242.6 380.4h3.4v19.5h-3.4z"
                                    className="st6"
                                    transform="rotate(-33.2 1244.4 390.1)"
                                />
                                <path
                                    d="M1238.9 384.7h2.1v9.2h-2.1z"
                                    className="st6"
                                    transform="rotate(-69.5 1239.9 389.3)"
                                />
                            </g>
                            <g id="feuilles_74_">
                                <path
                                    d="M1243.7 376a7.8 7.8 0 01-14 4.7h.1c5.7 0 10.4-4.6 10.4-10.4v-.9a7.9 7.9 0 013.5 6.6z"
                                    className="st15"
                                />
                                <path
                                    d="M1240.1 370.3c0 5.7-4.6 10.4-10.4 10.4h-.1a7.8 7.8 0 0110.5-11.2v.8z"
                                    className="st9"
                                />
                            </g>
                            <g id="feuilles_73_">
                                <path
                                    d="M1274 374.4c0 4.5-3.7 8.2-8.2 8.2a8.1 8.1 0 01-7.5-5 8.2 8.2 0 0110.9-10.7 8.3 8.3 0 014.8 7.5z"
                                    className="st16"
                                />
                                <path
                                    d="M1269.6 369.9a8.3 8.3 0 01-11.4 7.7 8.2 8.2 0 0110.9-10.7c.4 1 .5 2 .5 3z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_72_">
                                <path
                                    d="M1267.9 368.2a17.5 17.5 0 01-31.9 10 26.7 26.7 0 0022.9-25.4c5.3 3 9 8.7 9 15.4z"
                                    className="st17"
                                />
                                <path
                                    d="M1258.8 352.8a26.7 26.7 0 01-22.9 25.4 17.5 17.5 0 0114.4-27.5c3.1-.1 6 .7 8.5 2.1z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_71_">
                                <path
                                    d="M1261.9 396.1c0 2-1.6 3.6-3.6 3.6-1.2 0-2.2-.6-2.9-1.4h.1c2.6 0 4.8-2.1 4.8-4.8v-.4a4 4 0 011.6 3z"
                                    className="st11"
                                />
                                <path
                                    d="M1260.3 393.5c0 2.6-2.1 4.8-4.8 4.8h-.1c-.5-.6-.7-1.4-.7-2.2a3.6 3.6 0 015.6-3v.4z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_70_">
                                <path
                                    d="M1265.8 382.5a4 4 0 01-7.2 2.4h.1c3 0 5.4-2.4 5.4-5.4v-.5c.9.8 1.7 2 1.7 3.5z"
                                    className="st15"
                                />
                                <path
                                    d="M1263.9 379.5c0 3-2.4 5.4-5.4 5.4h-.1a4 4 0 013.2-6.4 4 4 0 012.2.6l.1.4z"
                                    className="st14"
                                />
                            </g>
                            <g id="feuilles_69_">
                                <path
                                    d="M1237.6 386.2c0 2.4-1.9 4.3-4.3 4.3a4 4 0 01-3.5-1.8 6.5 6.5 0 005.6-6.2c1.3.7 2.2 2.1 2.2 3.7z"
                                    className="st17"
                                />
                                <path
                                    d="M1235.4 382.4a6.4 6.4 0 01-5.6 6.2c-.5-.7-.8-1.6-.8-2.5 0-2.4 1.9-4.3 4.3-4.3.8.1 1.5.3 2.1.6z"
                                    className="st14"
                                />
                            </g>
                        </g>
                        <g id="arbre_41_">
                            <g id="arbre_42_">
                                <path d="M1151.1 371.1h4.8v20.3h-4.8z" className="st6" />
                                <path
                                    d="M1156 378.6h1.2v6.3h-1.2z"
                                    className="st6"
                                    transform="rotate(37 1156.6 381.7)"
                                />
                            </g>
                            <g id="feuilles_68_">
                                <path
                                    d="M1164.2 371.6l-.3.3-.6.6-.3.3-.5.4-.5.4-.6.5c-1.4 1.1-3 1.9-4.5 2.4l-.6.2-.4.1-.6.1-.8.1h-2l-.9-.2-.3-.1-.5-.1-.5-.2-1.4-.6-.3-.1-1.1-.6-.5-.3-.2-.1-.3-.2-.6-.5-.3-.2-.4-.3-.4-.4-.2-.1h-.1a16.4 16.4 0 006.1-5.4l.8-.1a16 16 0 008.6-4.3 16.5 16.5 0 004.7-8.1l.7.5c3.9 3.4 3.4 10.6-1.2 16z"
                                    className="st19"
                                />
                                <path
                                    d="M1164.6 355.1a16.5 16.5 0 01-4.7 8.1 15.4 15.4 0 01-8.6 4.3l-.8.1a16.4 16.4 0 01-4.1 4.2l-2 1.2-.3-.2-.4-.4-.5-.5a16.5 16.5 0 01-4.1-8.5c-.4-3 .4-5.7 2.3-7.5l.5-.4.2-.2.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1.3-.1.4-.1.4-.1.1-.5v-.1l.1-.4v-.2l.1-.4v-.1l.1-.4.1-.5.2-.5.4-1 .1-.3.3-.7.1-.2.2-.3.1-.2.1-.1.2-.3.1-.1.2-.3.2-.2.2-.3.2-.3.3-.3.1-.1.3-.3.3-.3.3-.2.2-.2.2-.1.3-.2.1-.1.3-.2.5-.2.3-.2.4-.2.4-.1h.1l.4-.1.4-.1h.1l.4-.1H1154l.8.1h.1l.3.1h.1l.3.1.4.1.2.1.2.1h.1l.2.1.4.2.4.3.3.2v1.2c0 3-.8 5.9-2.1 8.3 2.4-1 4.8-1.2 6.9-.6l.3.1.3.1.2.1.2.1.2.1.2.1.6.2z"
                                    className="st11"
                                />
                                <path
                                    d="M1162.8 354.3c-2.1-.6-4.5-.4-6.9.6a18 18 0 002.1-8.3v-1.2l1 .8.3.3.1.1.2.2.2.2.2.2.4.5.2.3.2.2.1.1.2.3.1.1.2.3.2.4.1.3.1.3.6 1.6.1.4.1.5.1.3.1.2.1.3v.2l.1.4c-.2 0-.2.2-.2.4z"
                                    className="st19"
                                />
                            </g>
                        </g>
                        <g id="arbre_44_">
                            <path id="branhes" d="M737.9 320.2h7.3v18.5h-7.3z" className="st2" />
                            <g id="feuilles_67_">
                                <path
                                    d="M753.5 318.7c0 2-1.2 3.6-2.7 3.6h-19c-.5 0-.9-.2-1.3-.5a41 41 0 0019.1-34.1l-.2-3.8c2.4 11.2 3.8 22.8 4.1 34.8z"
                                    className="st19"
                                />
                                <path
                                    d="M749.6 287.8a41 41 0 01-19.1 34.1 4 4 0 01-1.4-3.2c.4-19.5 3.8-38 9.7-54.7.3-.8.8-1.4 1.3-1.7 1.3-.8 2.9-.2 3.6 1.7l2.8 8.6c1.1 3.7 2 7.5 2.9 11.3l.2 3.9z"
                                    className="st11"
                                />
                                <g id="fruits_3_">
                                    <circle id="fruit_23_" cx="738.2" cy="282.4" r="1" className="st14" />
                                    <circle id="fruit_22_" cx="747.9" cy="302.8" r="1" className="st14" />
                                    <circle id="fruit_21_" cx="744.8" cy="316.9" r="1" className="st14" />
                                    <circle id="fruit_20_" cx="742.5" cy="292.9" r="1" className="st14" />
                                    <circle id="fruit_19_" cx="742" cy="272.3" r="1" className="st14" />
                                    <circle id="fruit_18_" cx="734.8" cy="309.4" r="1" className="st14" />
                                </g>
                            </g>
                        </g>
                        <g id="arbre_45_">
                            <path id="branches_30_" d="M917.3 345.4h3.6v13.8h-3.6z" className="st2" />
                            <g id="feuilles_66_">
                                <path
                                    d="M928 344c0 1.6-.9 3-2 3h-14.2c-.4 0-.7-.1-1-.4a35 35 0 0014.2-28.1l-.1-3.1c1.9 9.1 3 18.7 3.1 28.6z"
                                    className="st19"
                                />
                                <path
                                    d="M925.1 318.5c0 11.5-5.6 21.8-14.2 28.1a3.1 3.1 0 01-1-2.6c.3-16.1 2.9-31.3 7.3-45.1.2-.7.6-1.2 1-1.4.9-.6 2.2-.1 2.7 1.4l2.1 7.1 2.2 9.3-.1 3.2z"
                                    className="st11"
                                />
                                <g id="fruits_2_">
                                    <circle id="fruit_17_" cx="915.9" cy="315.5" r=".7" className="st14" />
                                    <circle id="fruit_16_" cx="918.4" cy="330.6" r=".7" className="st14" />
                                    <circle id="fruit_15_" cx="925.1" cy="338" r=".7" className="st14" />
                                    <circle id="fruit_14_" cx="922.1" cy="321.8" r=".7" className="st14" />
                                    <circle id="fruit_13_" cx="919.9" cy="306.9" r=".7" className="st14" />
                                    <circle id="fruit_12_" cx="914.1" cy="342.4" r=".7" className="st14" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <path
                        id="vert_foncé"
                        d="M595.5 303.4c249.9 76.5 489.6 45.3 708.7 126.5 268.5 99.5 323.5 74.7 481.8 74.8v8.1H597.9l-2.4-209.4z"
                        className="st7"
                    />
                    <path
                        id="marron"
                        fill="#c99877"
                        d="M601 310c249.9 76.5 489.6 45.3 708.7 126.5 268.5 99.5 323.5 74.7 481.8 74.8v8.1H603.4L601 310z"
                    />
                </g>
                <path id="eau_1_" d="M1153.9 505h77.1v29.8h-77.1z" className="st22" />
                <g id="buisson_9_">
                    <path
                        d="M1609.9 482.6a6 6 0 00.5 3l-11.6 1.6-4.1.6-4.5.6c1.2-.7 2.2-1.5 3-2.5l.3.4h.2a17 17 0 0017.9-15.9v-1.5a13 13 0 014.7 7.2c-3.3.1-6.1 2.9-6.4 6.5z"
                        className="st7"
                    />
                    <path
                        d="M1611.7 470.3a17.1 17.1 0 01-17.9 15.9h-.2l-.3-.4c-.8 1-1.9 1.9-3 2.5l-6.4.9a7.3 7.3 0 01-1-4.6c.3-4.5 4.2-8 8.7-7.7h.2a12.7 12.7 0 0120.1-8.1c-.2.5-.2 1-.2 1.5z"
                        className="st11"
                    />
                    <path
                        d="M1635.8 478.6a8.4 8.4 0 01-1.1 3.6l-11 1.5-1.6.2-4.2.6c1-.9 1.8-2.1 2.4-3.4l.8 1.4h.1c.9.1 1.7 0 2.6-.1 4.8-.8 8.5-4.9 8.8-10v-1a8.7 8.7 0 013.2 7.2z"
                        className="st7"
                    />
                    <path
                        d="M1632.6 472.5c-.3 5.1-4.1 9.1-8.8 10-.8.1-1.7.2-2.6.1h-.1l-.8-1.4a9.2 9.2 0 01-2.4 3.4l-2.5.3-5 .7a7 7 0 016-9.5h.8c.9.1 1.8.3 2.5.7a8 8 0 0112.8-5.1l.1.8z"
                        className="st11"
                    />
                </g>
                <g id="buisson_10_">
                    <path d="M1569.3 489a13.5 13.5 0 01-25.9 5.3 13.5 13.5 0 1125.9-5.3z" className="st8" />
                    <path
                        d="M1562.2 481.5a13.7 13.7 0 01-18.8 12.7 13.5 13.5 0 0117.9-17.5c.6 1.5.9 3.1.9 4.8z"
                        className="st12"
                    />
                </g>
                <g id="buisson_11_">
                    <path
                        d="M1271.8 486.9a15.8 15.8 0 01-30.4 6.1 16 16 0 0121.1-20.6c5.4 2.5 9.3 8.1 9.3 14.5z"
                        className="st7"
                    />
                    <path
                        d="M1263.4 478.1a16 16 0 01-22.1 15 16 16 0 0121.1-20.6c.7 1.7 1 3.6 1 5.6z"
                        className="st8"
                    />
                </g>
                <g id="buisson_13_">
                    <path
                        d="M1011.2 458.1a18.3 18.3 0 01-35.1 7.2 18.3 18.3 0 0124.4-23.8 18.3 18.3 0 0110.7 16.6z"
                        className="st7"
                    />
                    <path
                        d="M1001.6 448a18.6 18.6 0 01-25.5 17.3 18.3 18.3 0 0124.4-23.8c.6 2 1.1 4.2 1.1 6.5z"
                        className="st9"
                    />
                </g>
                <g id="buisson_14_">
                    <path
                        d="M801.6 376a18.3 18.3 0 01-35.1 7.2 18.3 18.3 0 0124.4-23.8 18.3 18.3 0 0110.7 16.6z"
                        className="st17"
                    />
                    <path
                        d="M791.9 365.9a18.6 18.6 0 01-25.5 17.3 18.3 18.3 0 0124.4-23.8c.7 2 1.1 4.2 1.1 6.5z"
                        className="st14"
                    />
                </g>
                <g id="buisson_1_">
                    <path
                        d="M401.6 230l-.5-.1a10.4 10.4 0 00-12.7 7.8l-.2 1.4-18.4-4.4c1.8-.6 3.5-1.5 5-2.7l.8 2.4.2.1 2.2.4h3.8l.9-.1.6-.1H383.6l.5-.1.7-.2.6-.2.6-.2.5-.2.7-.3.5-.2.2-.1.5-.3.6-.3.6-.3.5-.3.5-.4.6-.4.5-.4.5-.4.3-.3.4-.4.2-.2.3-.3.4-.5.4-.4.2-.3.2-.3.2-.3.2-.3.2-.3.1-.1.1-.2.9-1.6.2-.3.1-.2.1-.3.1-.3.1-.3.1-.3.1-.3.3-.8.1-.4.1-.5.3-1.6c2.6 2.6 4 6.4 3.7 10.4z"
                        className="st7"
                    />
                    <path
                        d="M397.6 221l-.1.5-.1.4-.3.8-.1.3-.1.3-.1.3-.1.3-.1.3-.1.2-.2.3-.9 1.6-.1.2-.1.1-.2.3-.2.3-.2.3-.2.3-.2.3-.4.4-.4.5-.3.3-.2.2-.4.4-.3.3-.5.4-.5.4-.6.4-.5.4-.5.3-.6.3-.6.3-.5.3-.2.1-.5.2-.7.3-.5.2-.6.2-.6.2-.7.2-.5.1H383.3l-.6.1-.9.1h-2.9-.9l-2.2-.4-.2-.1-.8-2.4a16.2 16.2 0 01-5 2.7l-9.2-2.2a11.5 11.5 0 0117-12.2c3.2-3.9 8.4-5.8 13.7-4.5 2.7.6 5 2 6.7 3.9l-.4 1.5z"
                        className="st11"
                    />
                    <path
                        d="M408.9 242.6l-.4 1.4-7.7-1.9a14 14 0 005.3-8l.2-1.2c2.4 2.4 3.5 6.1 2.6 9.7z"
                        className="st7"
                    />
                    <path
                        d="M406 234a14 14 0 01-5.3 8l-12.6-3 .2-1.4c1.4-5.7 7.1-9.2 12.7-7.8l.5.1c1.9.5 3.5 1.5 4.7 2.9l-.2 1.2z"
                        className="st11"
                    />
                    <g id="fruits_8_">
                        <circle cx="368" cy="223.6" r=".8" className="st23" />
                        <circle cx="380.1" cy="223" r=".7" className="st23" />
                        <circle cx="365" cy="229.2" r="1" className="st23" />
                        <circle cx="389.1" cy="231.9" r=".8" className="st23" />
                        <circle cx="383.5" cy="221.2" r=".7" className="st23" />
                        <circle cx="392.4" cy="236.2" r=".9" className="st23" />
                        <circle cx="405" cy="235.3" r=".8" className="st23" />
                        <circle cx="401.7" cy="239.2" r=".8" className="st23" />
                        <circle cx="368.2" cy="229" r=".8" className="st23" />
                        <circle cx="379.9" cy="233.7" r=".8" className="st23" />
                        <circle cx="385.1" cy="229.5" r=".7" className="st23" />
                        <circle cx="393.6" cy="222.8" r=".8" className="st23" />
                        <circle cx="394.9" cy="232.7" r=".8" className="st23" />
                        <circle cx="400.9" cy="235.6" r=".8" className="st23" />
                        <circle cx="389.8" cy="227.4" r=".9" className="st23" />
                    </g>
                </g>
                <g id="arbre_14_">
                    <g id="branches_15_">
                        <path d="M1242.7 451.7h8.6v49.2h-8.6z" className="st13" />
                        <path d="M1233.7 468.3h15.7v3.5h-15.7z" className="st13" transform="rotate(39 1241.6 470)" />
                        <path
                            d="M1245.8 482.4h15.7v3.5h-15.7z"
                            className="st13"
                            transform="rotate(-39.4 1253.7 484.1)"
                        />
                    </g>
                    <g id="feuilles_9_">
                        <path
                            d="M1271.1 406.1v24.3a24.3 24.3 0 01-42.8 15.7 59.4 59.4 0 0042-46.5c.5 2 .8 4.2.8 6.5z"
                            className="st17"
                        />
                        <path
                            d="M1270.2 399.6a58.9 58.9 0 01-42 46.5 24 24 0 01-5.8-15.7v-24.3a24.3 24.3 0 0147.8-6.5z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_8_">
                        <path
                            d="M1239.1 461.3a9.6 9.6 0 01-17.5 5.5c6.9-1 12.3-6.8 12.6-13.9a9.7 9.7 0 014.9 8.4z"
                            className="st17"
                        />
                        <path
                            d="M1234.1 452.8a14.6 14.6 0 01-12.6 13.9 9.7 9.7 0 017.9-15.1c1.7 0 3.3.5 4.7 1.2z"
                            className="st14"
                        />
                    </g>
                </g>
                <g id="banc">
                    <path d="M1249.3 486.2h2.2v10.3h-2.2zM1277.9 486.2h2.2v10.3h-2.2z" className="st24" />
                    <path d="M1263.6 477.4h2.2v26.5h-2.2z" className="st24" transform="rotate(-90 1264.7 490.6)" />
                    <path
                        d="M1250.3 479.9v11h28.6v-11h-28.6zm3.7 7.6h-1.6V482h1.6v5.5zm3.8 0h-1.6V482h1.6v5.5zm3.8 0h-1.6V482h1.6v5.5zm3.8 0h-1.6V482h1.6v5.5zm3.9 0h-1.6V482h1.6v5.5zm3.8 0h-1.6V482h1.6v5.5zm3.8-.1h-1.6v-5.5h1.6v5.5z"
                        className="st24"
                    />
                </g>
                <g id="buisson_6_">
                    <path
                        d="M1480.9 493.6c-.3.9-.3 1.9-.1 2.8l-10.7-.8-3.8-.3-4.1-.3c1.2-.4 2.2-.9 3.2-1.7l.2.4.2.1a15.4 15.4 0 0019.1-10.7l.3-1.3c1.8 2 2.8 4.6 2.9 7.3a6.3 6.3 0 00-7.2 4.5z"
                        className="st19"
                    />
                    <path
                        d="M1484.8 483a15.5 15.5 0 01-19.1 10.7l-.2-.1-.2-.4c-1 .7-2 1.3-3.2 1.7l-6-.5a7.8 7.8 0 01-.1-4.3c1.1-4 5.3-6.3 9.3-5.2h.2a11.7 11.7 0 0119.6-3.4l-.3 1.5z"
                        className="st11"
                    />
                    <path
                        d="M1504.9 495a6.4 6.4 0 01-1.7 3l-10.2-.8-1.4-.1-3.9-.3c1.1-.6 2-1.5 2.8-2.6l.4 1.4h.1l2.3.4c4.4.2 8.6-2.7 9.8-7.2l.2-.8c1.6 1.9 2.3 4.5 1.6 7z"
                        className="st19"
                    />
                    <path
                        d="M1503.1 489a10 10 0 01-9.8 7.2c-.8 0-1.6-.1-2.3-.4h-.1l-.4-1.4a8.7 8.7 0 01-2.8 2.6l-2.3-.2-4.6-.4c-.2-.9-.1-1.8.1-2.8.9-3.1 3.9-5 7.1-4.6l.8.2c.8.2 1.5.6 2.1 1.1a7.4 7.4 0 0112.5-2.2l-.3.9z"
                        className="st11"
                    />
                </g>
                <g id="arbre_55_">
                    <path id="branches_14_" d="M836.7 385.6h6.8v25.6h-6.8z" className="st2" />
                    <g id="feuilles_7_">
                        <path
                            d="M856.5 383c.1 3.1-1.6 5.6-3.7 5.6h-26.2c-.7 0-1.3-.3-1.8-.7a64.8 64.8 0 0026.1-58c3.4 16.9 5.3 34.7 5.6 53.1z"
                            className="st3"
                        />
                        <path
                            d="M851.2 335.7a64.8 64.8 0 01-26.4 52.2 6 6 0 01-1.9-4.9 291 291 0 0113.5-83.7 4.5 4.5 0 011.8-2.6c1.7-1.2 4-.3 4.9 2.6a254.2 254.2 0 017.9 30.5l.2 5.9z"
                            className="st4"
                        />
                        <g id="fruits_1_">
                            <circle id="fruit_5_" cx="834.1" cy="330.1" r="1.3" className="st5" />
                            <circle id="fruit_4_" cx="838.7" cy="358.1" r="1.3" className="st5" />
                            <circle id="fruit_3_" cx="851.2" cy="371.7" r="1.3" className="st5" />
                            <circle id="fruit_2_" cx="845.5" cy="341.7" r="1.3" className="st5" />
                            <circle id="fruit_1_" cx="841.4" cy="314.1" r="1.3" className="st5" />
                            <circle id="fruit" cx="830.8" cy="380" r="1.3" className="st5" />
                        </g>
                    </g>
                </g>
                <g id="arbre_1_">
                    <g id="branches_12_">
                        <path d="M1296 439.8h8.7v59.5h-8.7z" className="st6" />
                        <path d="M1308.8 436.3h5.8V465h-5.8z" className="st6" transform="rotate(52.4 1311.7 450.6)" />
                        <path d="M1312.7 427.6h2.2v26.9h-2.2z" className="st6" transform="rotate(30 1313.8 441)" />
                        <path d="M1284.1 439.1h3.1v17.7h-3.1z" className="st6" transform="rotate(-11.4 1285.7 448.2)" />
                        <path d="M1286.2 446.6h5.5v25.6h-5.5z" className="st6" transform="rotate(-48.4 1289 459.3)" />
                        <path d="M1303.2 471.8h1.7v11.4h-1.7z" className="st6" transform="rotate(52.4 1304 477.6)" />
                    </g>
                    <g id="feuilles_6_">
                        <path
                            d="M1286.4 439.3a17.9 17.9 0 01-32.1 10.9h.3a23.8 23.8 0 0023.8-23.8l-.1-2.1c4.9 3.2 8.1 8.7 8.1 15z"
                            className="st7"
                        />
                        <path
                            d="M1278.4 426.4a23.8 23.8 0 01-23.8 23.8h-.3a17.8 17.8 0 0123.9-25.8c.2.6.2 1.3.2 2z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_5_">
                        <path d="M1328.1 413.6a28.6 28.6 0 01-55 11.1 28.6 28.6 0 1155-11.1z" className="st7" />
                        <path
                            d="M1313 397.8a29 29 0 01-39.9 27 28.6 28.6 0 0138.1-37.2c1.1 3.1 1.8 6.5 1.8 10.2z"
                            className="st9"
                        />
                    </g>
                    <g id="feuilles_4_">
                        <path
                            d="M1341.4 432.5a14.5 14.5 0 01-26 8.8h.2c10.6 0 19.3-8.6 19.3-19.3l-.1-1.7c4 2.7 6.6 7.1 6.6 12.2z"
                            className="st10"
                        />
                        <path
                            d="M1334.9 422.1c0 10.6-8.6 19.3-19.3 19.3h-.2a14.5 14.5 0 0119.4-21l.1 1.7z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_7_">
                    <g id="branches_13_">
                        <path d="M316.4 178.3h8.6v49.2h-8.6z" className="st13" />
                        <path d="M307.5 194.8h15.7v3.5h-15.7z" className="st13" transform="rotate(39 315.4 196.5)" />
                        <path d="M319.6 208.9h15.7v3.5h-15.7z" className="st13" transform="rotate(-39.4 327.5 210.7)" />
                    </g>
                    <g id="feuilles_3_">
                        <path
                            d="M344.9 132.6v24.3a24.3 24.3 0 01-42.8 15.7 59.4 59.4 0 0042-46.5c.4 2.1.8 4.2.8 6.5z"
                            className="st7"
                        />
                        <path
                            d="M344 126.1a58.9 58.9 0 01-42 46.5 24 24 0 01-5.8-15.7v-24.3a24.3 24.3 0 0147.8-6.5z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles">
                        <path
                            d="M312.8 187.8a9.6 9.6 0 01-17.5 5.5c6.9-1 12.3-6.8 12.6-13.9a9.6 9.6 0 014.9 8.4z"
                            className="st20"
                        />
                        <path
                            d="M307.9 179.4a14.6 14.6 0 01-12.6 13.9 9.7 9.7 0 017.9-15.1c1.7 0 3.3.4 4.7 1.2z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_4_">
                    <g id="branches_28_">
                        <path d="M924.3 378.5h10.8v70.8h-10.8z" className="st13" />
                        <path d="M934.6 378.2h7.7V422h-7.7z" className="st13" transform="rotate(23.4 938.5 400.2)" />
                        <path d="M933.5 424.6h3.9v14.5h-3.9z" className="st13" transform="rotate(52.4 935.5 431.9)" />
                        <path d="M917.8 401.6h7v23.5h-7z" className="st13" transform="rotate(135 921.3 413.4)" />
                    </g>
                    <g id="feuilles_61_">
                        <path
                            d="M954 333.1v24.3a24.3 24.3 0 01-42.8 15.7 59.4 59.4 0 0042-46.5c.5 2 .8 4.2.8 6.5z"
                            className="st16"
                        />
                        <path
                            d="M953.1 326.6a58.9 58.9 0 01-42 46.5 24 24 0 01-5.8-15.7v-24.3a24.3 24.3 0 0147.8-6.5z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_60_">
                        <path
                            d="M971.4 364.8a18.3 18.3 0 01-35.1 7.2 18.3 18.3 0 0124.4-23.8 18.2 18.2 0 0110.7 16.6z"
                            className="st9"
                        />
                        <path
                            d="M961.8 354.7a18.6 18.6 0 01-25.5 17.3 18.3 18.3 0 0124.4-23.8c.7 2 1.1 4.2 1.1 6.5z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_59_">
                        <path
                            d="M920.7 397.5a15.3 15.3 0 01-27.9 8.7 23.4 23.4 0 0020-22.1c4.7 2.6 7.9 7.6 7.9 13.4z"
                            className="st17"
                        />
                        <path
                            d="M912.8 384.1c-.4 11.3-9 20.6-20 22.1a15.4 15.4 0 0112.6-24.1c2.7.1 5.2.8 7.4 2z"
                            className="st14"
                        />
                    </g>
                </g>
                <g id="buisson_5_">
                    <path
                        d="M591.4 289.8l-.3-.1c-4-1.3-8.3.8-9.6 4.9l-.3 1-12.9-4.4a14 14 0 003.8-1.6l.4 1.8.2.1 1.5.4.3.1H578.6l.5-.1.5-.1.5-.1.4-.1.5-.2.4-.1h.1l.4-.2.4-.2.4-.2.4-.2.4-.2.4-.3.4-.3.4-.3.3-.2.3-.3.2-.2.2-.2.3-.3.3-.3.2-.2.2-.2.2-.2.2-.2.2-.2.1-.1.1-.1.8-1.1.1-.2.1-.1.1-.2.1-.2.1-.2.1-.2.1-.2.2-.5.1-.3.1-.3.3-1.1c1.5 2 2.2 4.9 1.7 7.7z"
                        className="st10"
                    />
                    <path
                        d="M589.2 283.1l-.1.3-.1.3-.2.5-.1.2-.1.2-.1.2-.1.2-.1.2-.1.1-.1.2-.8 1.1-.1.1-.1.1-.2.2-.2.2-.2.2-.2.2-.2.2-.3.3-.3.3-.2.2-.2.2-.3.3-.3.2-.4.3-.4.3-.4.3-.4.2-.4.2-.4.2-.4.2-.4.2h-.1l-.4.1-.5.2-.4.1-.5.1-.5.1-.5.1h-.3H574.7h-.4l-.3-.1-1.5-.4-.2-.1-.4-1.8a10 10 0 01-3.8 1.6l-6.4-2.2a8.4 8.4 0 0113-7.9 9.6 9.6 0 0114.6.8c.1.5 0 .9-.1 1.3z"
                        className="st11"
                    />
                    <path
                        d="M595.8 299.4l-.4 1-5.4-1.8c2-1.2 3.6-3.1 4.4-5.5l.3-.9c1.4 2 1.9 4.7 1.1 7.2z"
                        className="st10"
                    />
                    <path
                        d="M594.3 293.1c-.8 2.4-2.4 4.3-4.4 5.5l-8.8-3 .3-1c1.4-4 5.7-6.2 9.6-4.9l.3.1a7 7 0 013.2 2.4l-.2.9z"
                        className="st11"
                    />
                </g>
                <g id="buisson_4_">
                    <path
                        d="M918.8 425.7l-.6-.2a13 13 0 00-17.5 9l-22.6-8.3a20 20 0 006.7-2.4c.1 1 .4 2.1.7 3l.3.1 2.7.8.5.1.4.1h.1l.5.1.6.1.5.1h.9l.6.1h3.3l.8-.1.8-.1.8-.1.7-.1 1-.2.6-.2.2-.1.7-.2.8-.3.8-.3.7-.3h.1l.7-.3.8-.4.7-.4.6-.4.4-.3.6-.4.3-.2.4-.3.6-.5.5-.5.3-.3.3-.3.3-.3.3-.3.3-.3.1-.1.2-.2 1.4-1.8.2-.4.1-.2.2-.4.2-.4.2-.4.2-.4.2-.4.4-.9.2-.5.2-.5.6-1.8c3 3.4 4.3 8.3 3.4 13z"
                        className="st19"
                    />
                    <path
                        d="M914.9 414.2l-.2.5-.2.5-.4.9-.2.4-.2.4-.2.4-.2.4-.2.4-.1.2-.2.4-1.4 1.8-.2.2-.1.1-.3.3-.3.3-.3.3-.3.3-.3.3-.5.5-.6.5-.4.3-.3.2-.6.4-.4.3-.6.4-.7.4-.8.4-.7.3h-.1l-.7.3-.8.3-.8.3-.7.2-.2.1-.6.2a3 3 0 01-1 .2l-.7.1-.8.1-.8.1-.8.1h-.6-2.7l-.6-.1h-.9l-.5-.1-.6-.1-.5-.1h-.1l-.4-.1-.5-.1-2.7-.8-.3-.1c-.3-1-.6-2-.7-3-2 1.2-4.3 2-6.7 2.4l-11.3-4.2c-.2-2 .1-4 .8-5.9 2.7-7.2 11-10.8 18.6-8 1.3.5 2.5 1.1 3.6 1.9a17.3 17.3 0 0117.7-3.2c3.3 1.2 6 3.2 7.9 5.8l-.6 1.9z"
                        className="st11"
                    />
                    <path
                        d="M926.3 442.1l-.7 1.6-9.5-3.5c3.5-1.9 6.3-4.9 7.7-8.8l.5-1.4c2.7 3.3 3.6 7.8 2 12.1z"
                        className="st19"
                    />
                    <path
                        d="M923.8 431.3a16.3 16.3 0 01-7.7 8.8l-15.4-5.7.5-1.7a13 13 0 0117-7.3l.6.2c2.3.9 4.1 2.4 5.5 4.2l-.5 1.5z"
                        className="st11"
                    />
                </g>
                <g id="escaliers_de_bois">
                    <g id="pierre">
                        <path
                            d="M868.7 421.8h17.2v9.1h-17.2zM848.7 413.3h17.5v7.7h-17.5zM828.7 403.8h17.5v7.5h-17.5zM808.7 393.6h17.5v10h-17.5zM788.7 383.1h17.5v12.6h-17.5zM768.7 374.6h17.5v13.8h-17.5zM748.7 365h17.5v15h-17.5zM728.7 354.6h17.5v15h-17.5zM705.7 345.6h20.5v15h-20.5z"
                            className="st25"
                        />
                    </g>
                    <g id="bois">
                        <path
                            d="M886.2 435.7c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.3 1.1 2.5 2.5 2.5zM866.2 427c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM846.2 417.1c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM826.2 407.5c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM806.2 396.7c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM786.2 388.4c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM766.2 378.7c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM746.2 368.6c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.4 1.1 2.5 2.5 2.5zM726.2 359.5c1.4 0 2.5-1.1 2.5-2.5v-9.7c0-1.4-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5v9.7c0 1.3 1.1 2.5 2.5 2.5z"
                            className="st26"
                        />
                    </g>
                </g>
                <g id="pont">
                    <g id="sol" fill="none" strokeMiterlimit="10">
                        <path stroke="#f2c483" strokeWidth="7" d="M1156 492.6a90.5 90.5 0 0175 6.3" />
                        <path stroke="#665e31" d="M1155.7 489.3a90.5 90.5 0 0175 6.3" />
                    </g>
                    <g id="barreaux">
                        <path d="M1176.3 469.3h3v20.1h-3z" className="st29" transform="rotate(-5 1177.8 479.3)" />
                        <path d="M1159.8 471.8h3v20h-3z" className="st29" transform="rotate(-15 1161.4 481.9)" />
                        <path d="M1225.3 477.8h3v20h-3z" className="st29" transform="rotate(25 1226.8 487.8)" />
                        <path d="M1209.6 472h3v20h-3z" className="st29" transform="rotate(15 1211.1 482)" />
                        <path d="M1193 469.4h3v20h-3z" className="st29" transform="rotate(5 1194.5 479.4)" />
                        <path
                            d="M1163.3 489.3l13.7-19M1223.3 494.9l-9.9-21.4M1194 486.8l-17-16.5M1194 486.6l19.2-13.1"
                            className="st30"
                        />
                    </g>
                    <path
                        id="rampe"
                        fill="none"
                        stroke="#f2c483"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                        d="M1153.4 472.8a114.6 114.6 0 0183.9 7.2"
                    />
                    <g id="clous">
                        <circle cx="1163.1" cy="488.4" r=".5" className="st32" />
                        <circle cx="1178.5" cy="486.4" r=".5" className="st32" />
                        <circle cx="1194" cy="486.2" r=".5" className="st32" />
                        <circle cx="1209.3" cy="488.7" r=".5" className="st32" />
                        <circle cx="1223.7" cy="494" r=".5" className="st32" />
                        <circle cx="1213.4" cy="473.7" r=".5" className="st32" />
                        <circle cx="1177.1" cy="470.6" r=".5" className="st32" />
                    </g>
                </g>
                <g id="fleur_30_">
                    <ellipse
                        id="feuille_62_"
                        cx="525.9"
                        cy="273"
                        className="st11"
                        rx=".7"
                        ry="2.2"
                        transform="rotate(-17.3 526 273)"
                    />
                    <path id="tige_30_" d="M526.5 271.8h.3v4.6h-.3z" className="st7" />
                    <circle id="petales_61_" cx="526.6" cy="271.4" r="1.1" className="st33" />
                    <path id="petales_60_" d="M525.5 269.5h2.2v1.9h-2.2z" className="st33" />
                    <ellipse
                        id="feuille_61_"
                        cx="527.8"
                        cy="272"
                        className="st11"
                        rx="2.6"
                        ry=".6"
                        transform="rotate(-63 527.8 272)"
                    />
                </g>
                <g id="MOULIN">
                    <g id="moulin_1_">
                        <path id="mur" d="M703.5 243.6V359H586.6V243.6l58.5-22.8z" className="st25" />
                        <g id="briques_4_">
                            <g id="briques_gauches">
                                <path
                                    fill="#db8b72"
                                    d="M594 253.4h-7.8c-.7 0-1.3-.6-1.3-1.3v-.8c0-1.6 1.3-2.9 2.9-2.9h8c.7 0 1.3.6 1.3 1.3v.7c-.1 1.6-1.4 3-3.1 3z"
                                />
                                <path
                                    d="M589.8 260.8h-2.9a2 2 0 01-2-2 3 3 0 013-3h.8c1.3 0 2.4 1.1 2.4 2.4v1.3c0 .8-.6 1.3-1.3 1.3z"
                                    className="st35"
                                />
                                <path
                                    fill="#d8846a"
                                    d="M595.8 268.3h-9.1c-.9 0-1.7-.8-1.7-1.7v-1.2c0-1.1.9-2 2-2h7.3c1.6 0 2.8 1.3 2.8 2.8v.9c-.1.7-.6 1.2-1.3 1.2z"
                                />
                                <path
                                    fill="#f78b6b"
                                    d="M589.6 275.8h-3.2c-.8 0-1.5-.7-1.5-1.5v-.6c0-1.6 1.3-2.9 2.9-2.9h1.2c1.2 0 2.2 1 2.2 2.2v1.3c-.1.8-.8 1.5-1.6 1.5z"
                                />
                                <path
                                    fill="#f4a187"
                                    d="M594.6 283.3H586c-.6 0-1.2-.5-1.2-1.2 0-2.1 1.7-3.8 3.8-3.8h6.7c.9 0 1.6.7 1.6 1.6v1c.1 1.3-.9 2.4-2.3 2.4z"
                                />
                                <path
                                    fill="#e0876c"
                                    d="M587.8 290.8h-1.4c-.8 0-1.5-.7-1.5-1.5v-.7c0-1.6 1.3-2.8 2.8-2.8h1.7c.9 0 1.7.7 1.7 1.7 0 1.8-1.5 3.3-3.3 3.3z"
                                />
                                <path
                                    fill="#e59a88"
                                    d="M595.8 298.3h-8.4a2.5 2.5 0 01-2.5-2.5c0-1.4 1.1-2.5 2.5-2.5h5.8c2.1 0 3.8 1.7 3.8 3.8 0 .6-.5 1.2-1.2 1.2z"
                                />
                                <path
                                    fill="#fcaa90"
                                    d="M604.5 260.8h-7.8a3.1 3.1 0 01-3.1-3.1c0-1.1.9-1.9 1.9-1.9h6.5c2.1 0 3.7 1.7 3.7 3.7 0 .8-.5 1.3-1.2 1.3z"
                                />
                                <path
                                    fill="#db866b"
                                    d="M603 290.8h-6.3a3.1 3.1 0 01-3.1-3.1c0-1.1.9-1.9 1.9-1.9h8.2a2 2 0 012 2v.3c0 1.5-1.2 2.7-2.7 2.7z"
                                />
                                <path
                                    fill="#dd8064"
                                    d="M586.2 241.2h2.9a2 2 0 012 2 3 3 0 01-3 3h-.8a2.4 2.4 0 01-2.4-2.4v-1.3c-.1-.8.5-1.3 1.3-1.3z"
                                />
                            </g>
                            <g id="briques_droites">
                                <path
                                    d="M705.2 250.1v.9c0 1.3-1 2.3-2.3 2.3h-8.8a1 1 0 01-1-1c0-1.1.4-2.1 1.2-2.8a4 4 0 012.8-1.2h6.4c.9.1 1.7.9 1.7 1.8z"
                                    className="st35"
                                />
                                <path
                                    fill="#ed9c83"
                                    d="M703.9 260.8h-2.8c-1.3 0-2.3-1-2.3-2.3v-1.2c0-.8.6-1.4 1.4-1.4h2.6c1.3 0 2.4 1.1 2.4 2.4v1.3c0 .7-.6 1.2-1.3 1.2z"
                                />
                                <path
                                    fill="#e88c70"
                                    d="M702.6 268.3h-6.2a3.2 3.2 0 01-3.2-3.2c0-1 .8-1.7 1.7-1.7h8.5c1.1 0 1.9.9 1.9 1.9v.4a2.8 2.8 0 01-2.7 2.6z"
                                />
                                <path
                                    fill="#d68167"
                                    d="M702.3 275.8h-1.5c-.9 0-1.6-.7-1.6-1.6v-.7c0-1.5 1.2-2.6 2.6-2.6h1.8c.9 0 1.6.7 1.6 1.6v.4c0 1.6-1.3 2.9-2.9 2.9z"
                                />
                                <path
                                    fill="#db8f77"
                                    d="M702.4 283.3h-7.9c-.8 0-1.4-.6-1.4-1.4v-1.1c0-1.4 1.1-2.5 2.5-2.5h7.9c1 0 1.7.8 1.7 1.7v.5c0 1.6-1.2 2.8-2.8 2.8z"
                                />
                                <path
                                    fill="#d68369"
                                    d="M703.8 290.8h-3c-.9 0-1.7-.8-1.7-1.7 0-1.8 1.5-3.3 3.3-3.3h.5c1.3 0 2.3 1 2.3 2.3v1.3c0 .8-.6 1.4-1.4 1.4z"
                                />
                                <path
                                    fill="#e2957d"
                                    d="M703.6 298.3h-8.8c-.9 0-1.7-.8-1.7-1.7v-.6c0-1.5 1.2-2.7 2.7-2.7h6c1.9 0 3.4 1.5 3.4 3.4 0 .9-.7 1.6-1.6 1.6z"
                                />
                                <path
                                    fill="#f4a790"
                                    d="M703.6 305.8h-3c-.8 0-1.5-.7-1.5-1.5v-1.1c0-1.3 1.1-2.4 2.4-2.4h1.1c1.4 0 2.6 1.2 2.6 2.6v.8c0 .8-.7 1.6-1.6 1.6z"
                                />
                                <path
                                    fill="#d8866c"
                                    d="M703.4 313.2h-8.7c-.9 0-1.6-.7-1.6-1.6 0-1.9 1.5-3.4 3.4-3.4h5.6c1.7 0 3.1 1.4 3.1 3.1 0 1.1-.8 1.9-1.8 1.9z"
                                />
                                <path
                                    d="M703.4 321.7H700a.8.8 0 01-.8-.8v-1c0-1.8 1.4-3.2 3.2-3.2h1.5c.7 0 1.3.6 1.3 1.3v1.8c0 1.1-.8 1.9-1.8 1.9z"
                                    className="st35"
                                />
                                <path
                                    fill="#d88166"
                                    d="M702.5 330.2h-8.4a1 1 0 01-1-1v-2.4c0-.8.7-1.5 1.5-1.5h9c.9 0 1.6.7 1.6 1.6v.7c0 1.4-1.2 2.6-2.7 2.6z"
                                />
                                <path
                                    fill="#d88e77"
                                    d="M703.1 337.7h-2.9c-.6 0-1-.4-1-1v-3.6c0-.2.2-.4.4-.4h4.5c.7 0 1.2.5 1.2 1.2v1.7c-.1 1.1-1 2.1-2.2 2.1z"
                                />
                                <path
                                    fill="#f9af98"
                                    d="M704.3 345.2h-9.5c-.9 0-1.7-.8-1.7-1.7v-1.2c0-1.1.9-2.1 2.1-2.1h6.8c1.8 0 3.3 1.5 3.3 3.3v.8c-.1.4-.5.9-1 .9z"
                                />
                                <path
                                    d="M703.4 353.6h-3c-.7 0-1.3-.6-1.3-1.3v-1.4c0-1.3 1-2.3 2.3-2.3h.6c1.8 0 3.2 1.4 3.2 3.2 0 1-.8 1.8-1.8 1.8z"
                                    className="st35"
                                />
                                <path
                                    fill="#d87d61"
                                    d="M693.2 275.8h-7.1c-.9 0-1.6-.7-1.6-1.6 0-1.9 1.5-3.4 3.4-3.4h7.2c.9 0 1.5.7 1.5 1.5.1 2-1.5 3.5-3.4 3.5z"
                                />
                                <path
                                    d="M694.8 305.8h-8.6c-.9 0-1.6-.7-1.6-1.6v-.5c0-1.6 1.3-2.9 2.9-2.9h6.2a3 3 0 013 3v.1a2 2 0 01-1.9 1.9z"
                                    className="st56"
                                />
                                <path
                                    fill="#e89c85"
                                    d="M694.9 321.7h-9.3a1 1 0 01-1-1v-.7c0-1.8 1.5-3.3 3.3-3.3h5.6c1.8 0 3.2 1.4 3.2 3.2 0 1-.8 1.8-1.8 1.8z"
                                />
                                <path
                                    fill="#eaa28c"
                                    d="M695.7 337.7h-8.2a2.9 2.9 0 01-2.9-2.9c0-1.1.9-2 2-2h7.2c1.6 0 2.9 1.3 2.9 2.9v1.2c0 .3-.5.8-1 .8z"
                                />
                                <path
                                    fill="#e5977f"
                                    d="M700.1 240.4h2.8c1.3 0 2.3 1 2.3 2.3v1.2c0 .8-.6 1.4-1.4 1.4h-2.6a2.4 2.4 0 01-2.4-2.4v-1.3c0-.7.6-1.2 1.3-1.2z"
                                />
                            </g>
                            <g id="briques_milieu">
                                <path
                                    fill="#dd957f"
                                    d="M660 252.8h-6.2a2 2 0 01-2-2 3 3 0 013-3h5.1c1.3 0 2.4 1.1 2.4 2.4v.2c.1 1.4-1 2.4-2.3 2.4z"
                                />
                                <path
                                    fill="#db8165"
                                    d="M666.1 245.6h-7.6a2.9 2.9 0 01-2.9-2.9c0-1.1.9-2.1 2.1-2.1h6.6c1.9 0 3.4 1.5 3.4 3.4 0 .9-.7 1.6-1.6 1.6z"
                                />
                                <path
                                    fill="#dd927c"
                                    d="M661.1 237.8h-5.4c-.7 0-1.3-.6-1.3-1.3v-.8c0-1.6 1.3-2.9 2.9-2.9h5.6c.7 0 1.3.6 1.3 1.3v.7c-.1 1.7-1.4 3-3.1 3z"
                                />
                                <path
                                    fill="#efac97"
                                    d="M634.8 237.8h-4.2a2 2 0 01-2.1-2.1c0-1.6 1.3-2.9 2.9-2.9h3.3c1.4 0 2.5 1.1 2.5 2.5.1 1.4-1 2.5-2.4 2.5z"
                                />
                                <path
                                    fill="#d8886f"
                                    d="M634.2 252.8h-1.5a2.9 2.9 0 01-2.9-2.9c0-1.2 1-2.1 2.1-2.1h3.3c1.1 0 1.9.9 1.9 1.9.2 1.8-1.2 3.1-2.9 3.1z"
                                />
                                <path
                                    d="M630.5 245.6h-7.1c-.9 0-1.6-.7-1.6-1.6v-.2c0-1.8 1.4-3.2 3.2-3.2h7.3c.9 0 1.6.7 1.6 1.6 0 1.8-1.5 3.4-3.4 3.4z"
                                    className="st56"
                                />
                            </g>
                        </g>
                        <g id="toit">
                            <path
                                fill="#ce5743"
                                d="M711.2 238.9l-2.6 6.7-5.1-2-58.4-22.8-58.5 22.8-5 2-2.6-6.7 7.6-3 48.7-18.9 9.8-3.8 9.8 3.8 48.6 18.9z"
                            />
                            <path
                                d="M649.3 216h13.3v4.7h-13.3z"
                                className="st66"
                                transform="rotate(16.4 655.9 218.2)"
                            />
                            <path
                                d="M661.6 220.8h13.3v4.7h-13.3z"
                                className="st66"
                                transform="rotate(16.4 668.2 223)"
                            />
                            <path d="M674 225.7h13.3v4.7H674z" className="st66" transform="rotate(16.4 680.6 228)" />
                            <path
                                d="M686.3 230.5h13.3v4.7h-13.3z"
                                className="st66"
                                transform="rotate(16.4 692.9 232.8)"
                            />
                            <path
                                d="M698.6 235.4h13.3v4.7h-13.3z"
                                className="st66"
                                transform="rotate(16.4 705.2 237.6)"
                            />
                            <path d="M650.6 213.1l-1.3 4.5-4.2-1.3v-4.8z" className="st66" />
                            <g>
                                <path
                                    d="M627.6 216h13.3v4.7h-13.3z"
                                    className="st66"
                                    transform="rotate(163.6 634.3 218.3)"
                                />
                                <path
                                    d="M615.3 220.8h13.3v4.7h-13.3z"
                                    className="st66"
                                    transform="rotate(163.6 622 223.2)"
                                />
                                <path
                                    d="M603 225.7h13.3v4.7H603z"
                                    className="st66"
                                    transform="rotate(163.6 609.6 228)"
                                />
                                <path
                                    d="M590.6 230.5h13.3v4.7h-13.3z"
                                    className="st66"
                                    transform="rotate(163.6 597.3 232.8)"
                                />
                                <path
                                    d="M578.3 235.4h13.3v4.7h-13.3z"
                                    className="st66"
                                    transform="rotate(163.6 585 237.7)"
                                />
                                <path d="M639.6 213.1l1.3 4.5 4.2-1.3v-4.8z" className="st66" />
                            </g>
                        </g>
                    </g>
                    <g id="r">
                        <g id="roue_1_">
                            <g id="godets">
                                <path
                                    id="godet"
                                    d="M686.4 302.2l3-3c.4-.4.4-1 0-1.4l-.3-.3a1 1 0 00-1.4 0l-3 3c-.2.2-.2.5 0 .7l1 1c.2.2.5.2.7 0z"
                                    className="st67"
                                />
                                <path
                                    id="godet_1_"
                                    d="M686.7 309.3l3.4-2.4c.5-.3.6-.9.2-1.4l-.2-.3c-.3-.5-.9-.6-1.4-.2l-3.4 2.4c-.2.2-.3.5-.1.7l.8 1.1c.2.2.5.3.7.1z"
                                    className="st67"
                                />
                                <path
                                    id="godet_2_"
                                    d="M685.9 316.3l3.8-1.8c.5-.2.7-.8.5-1.3l-.2-.3c-.2-.5-.8-.7-1.3-.5l-3.8 1.8c-.3.1-.4.4-.2.7l.6 1.2c0 .2.3.4.6.2z"
                                    className="st67"
                                />
                                <path
                                    id="godet_3_"
                                    d="M683.8 323.1l4.1-1.1c.5-.1.9-.7.7-1.2l-.1-.4c-.1-.5-.7-.9-1.2-.7l-4.1 1.1c-.3.1-.4.3-.4.6l.4 1.3c0 .3.3.5.6.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_4_"
                                    d="M680.5 329.4l4.2-.4c.6 0 1-.5.9-1.1v-.4c0-.6-.5-1-1.1-.9l-4.2.4c-.3 0-.5.3-.5.5l.1 1.4c.1.4.4.6.6.5z"
                                    className="st67"
                                />
                                <path
                                    id="godet_5_"
                                    d="M676.3 335.1l4.2.4c.6 0 1-.4 1.1-.9v-.4c0-.6-.4-1-.9-1.1l-4.2-.4c-.3 0-.5.2-.5.5l-.1 1.4c-.1.2.1.5.4.5z"
                                    className="st67"
                                />
                                <path
                                    id="godet_6_"
                                    d="M671.1 339.9l4.1 1.1c.5.1 1.1-.2 1.2-.7l.1-.4c.1-.5-.2-1.1-.7-1.2l-4.1-1.1c-.3-.1-.5.1-.6.4l-.4 1.3c-.1.3.1.5.4.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_7_"
                                    d="M665.1 343.8l3.8 1.8c.5.2 1.1 0 1.3-.5l.2-.3c.2-.5 0-1.1-.5-1.3l-3.8-1.8c-.3-.1-.5 0-.7.2l-.6 1.2c0 .3.1.6.3.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_8_"
                                    d="M658.6 346.5l3.4 2.4c.5.3 1.1.2 1.4-.2l.2-.3c.3-.5.2-1.1-.2-1.4l-3.4-2.4c-.2-.2-.5-.1-.7.1l-.8 1.1c-.2.3-.1.6.1.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_9_"
                                    d="M651.7 348.1l3 3c.4.4 1 .4 1.4 0l.3-.3c.4-.4.4-1 0-1.4l-3-3a.5.5 0 00-.7 0l-1 1c-.2.2-.2.5 0 .7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_10_"
                                    d="M644.6 348.5l2.4 3.4c.3.5.9.6 1.4.2l.3-.2c.5-.3.6-.9.2-1.4l-2.4-3.4c-.2-.2-.5-.3-.7-.1l-1.1.8c-.2.1-.3.4-.1.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_11_"
                                    d="M637.5 347.6l1.8 3.8c.2.5.8.7 1.3.5l.3-.2c.5-.2.7-.8.5-1.3l-1.8-3.8c-.1-.3-.4-.4-.7-.2l-1.2.6c-.2.1-.3.3-.2.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_12_"
                                    d="M630.8 345.5l1.1 4.1c.1.5.7.9 1.2.7l.4-.1c.5-.1.9-.7.7-1.2l-1.1-4.1c-.1-.3-.3-.4-.6-.4l-1.3.4c-.4.1-.5.3-.4.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_13_"
                                    d="M624.4 342.3l.4 4.2c0 .6.5 1 1.1.9h.4c.6 0 1-.5.9-1.1l-.4-4.2c0-.3-.3-.5-.5-.5l-1.4.1c-.3.1-.5.3-.5.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_14_"
                                    d="M618.8 338l-.4 4.2c0 .6.4 1 .9 1.1h.4c.6 0 1-.4 1.1-.9l.4-4.2c0-.3-.2-.5-.5-.5l-1.4-.1c-.2-.1-.5.1-.5.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_15_"
                                    d="M614 332.8l-1.1 4.1c-.1.5.2 1.1.7 1.2l.4.1c.5.1 1.1-.2 1.2-.7l1.1-4.1c.1-.3-.1-.5-.4-.6l-1.3-.4c-.3 0-.6.1-.6.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_16_"
                                    d="M610.1 326.9l-1.8 3.8c-.2.5 0 1.1.5 1.3l.3.2c.5.2 1.1 0 1.3-.5l1.8-3.8c.1-.3 0-.5-.2-.7l-1.2-.6c-.3-.1-.6 0-.7.3z"
                                    className="st67"
                                />
                                <path
                                    id="godet_17_"
                                    d="M607.4 320.3l-2.4 3.4c-.3.5-.2 1.1.2 1.4l.3.2c.5.3 1.1.2 1.4-.2l2.4-3.4c.2-.2.1-.5-.1-.7l-1.1-.8c-.3-.2-.6-.1-.7.1z"
                                    className="st67"
                                />
                                <path
                                    id="godet_18_"
                                    d="M605.8 313.4l-3 3a1 1 0 000 1.4l.3.3c.4.4 1 .4 1.4 0l3-3c.2-.2.2-.5 0-.7l-1-1a.5.5 0 00-.7 0z"
                                    className="st67"
                                />
                                <path
                                    id="godet_19_"
                                    d="M605.4 306.3l-3.4 2.4c-.5.3-.6.9-.2 1.4l.2.3c.3.5.9.6 1.4.2l3.4-2.4c.2-.2.3-.5.1-.7l-.8-1.1a.6.6 0 00-.7-.1z"
                                    className="st67"
                                />
                                <path
                                    id="godet_20_"
                                    d="M606.3 299.3l-3.8 1.8c-.5.2-.7.8-.5 1.3l.2.3c.2.5.8.7 1.3.5l3.8-1.8c.3-.1.4-.4.2-.7l-.6-1.2a.5.5 0 00-.6-.2z"
                                    className="st67"
                                />
                                <path
                                    id="godet_21_"
                                    d="M608.4 292.5l-4.1 1.1c-.5.1-.9.7-.7 1.2l.1.4c.1.5.7.9 1.2.7l4.1-1.1c.3-.1.4-.3.4-.6l-.4-1.3c-.1-.3-.4-.5-.6-.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_22_"
                                    d="M611.6 286.2l-4.2.4c-.6 0-1 .5-.9 1.1v.4c0 .6.5 1 1.1.9l4.2-.4c.3 0 .5-.3.5-.5l-.1-1.4c-.1-.3-.3-.5-.6-.5z"
                                    className="st67"
                                />
                                <path
                                    id="godet_23_"
                                    d="M615.9 280.5l-4.2-.4a1 1 0 00-1.1.9v.4c0 .6.4 1 .9 1.1l4.2.4c.3 0 .5-.2.5-.5l.1-1.4c.1-.2-.1-.4-.4-.5z"
                                    className="st67"
                                />
                                <path
                                    id="godet_24_"
                                    d="M621.1 275.7l-4.1-1.1c-.5-.1-1.1.2-1.2.7l-.1.4c-.1.5.2 1.1.7 1.2l4.1 1.1c.3.1.5-.1.6-.4l.4-1.3c0-.2-.2-.5-.4-.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_25_"
                                    d="M627 271.9l-3.8-1.8c-.5-.2-1.1 0-1.3.5l-.2.3c-.2.5 0 1.1.5 1.3l3.8 1.8c.3.1.5 0 .7-.2l.6-1.2c.1-.3 0-.6-.3-.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_26_"
                                    d="M633.6 269.1l-3.4-2.4c-.5-.3-1.1-.2-1.4.2l-.2.3c-.3.5-.2 1.1.2 1.4l3.4 2.4c.2.2.5.1.7-.1l.8-1.1c.1-.2.1-.5-.1-.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_27_"
                                    d="M640.5 267.5l-3-3a1 1 0 00-1.4 0l-.3.3a1 1 0 000 1.4l3 3c.2.2.5.2.7 0l1-1c.2-.2.2-.5 0-.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_28_"
                                    d="M647.6 267.2l-2.4-3.4c-.3-.5-.9-.6-1.4-.2l-.3.2c-.5.3-.6.9-.2 1.4l2.4 3.4c.2.2.5.3.7.1l1.1-.8c.2-.2.2-.5.1-.7z"
                                    className="st67"
                                />
                                <path
                                    id="godet_29_"
                                    d="M654.6 268l-1.8-3.8c-.2-.5-.8-.7-1.3-.5l-.3.2c-.5.2-.7.8-.5 1.3l1.8 3.8c.1.3.4.4.7.2l1.2-.6c.2 0 .3-.3.2-.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_30_"
                                    d="M661.4 270.1l-1.1-4.1c-.1-.5-.7-.9-1.2-.7l-.4.1c-.5.1-.9.7-.7 1.2l1.1 4.1c.1.3.3.4.6.4l1.3-.4c.3 0 .4-.3.4-.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_31_"
                                    d="M667.7 273.3l-.4-4.2c0-.6-.5-1-1.1-.9h-.4c-.6 0-1 .5-.9 1.1l.4 4.2c0 .3.3.5.5.5l1.4-.1c.3 0 .5-.3.5-.6z"
                                    className="st67"
                                />
                                <path
                                    id="godet_32_"
                                    d="M673.3 277.6l.4-4.2c0-.6-.4-1-.9-1.1h-.4a1 1 0 00-1.1.9l-.4 4.2c0 .3.2.5.5.5l1.4.1c.3.1.5-.1.5-.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_33_"
                                    d="M678.2 282.8l1.1-4.1c.1-.5-.2-1.1-.7-1.2l-.4-.1c-.5-.1-1.1.2-1.2.7l-1.1 4.1c-.1.3.1.5.4.6l1.3.4c.2 0 .5-.1.6-.4z"
                                    className="st67"
                                />
                                <path
                                    id="godet_34_"
                                    d="M682 288.8l1.8-3.8c.2-.5 0-1.1-.5-1.3l-.3-.2c-.5-.2-1.1 0-1.3.5l-1.8 3.8c-.1.3 0 .5.2.7l1.2.6c.3 0 .6-.1.7-.3z"
                                    className="st67"
                                />
                                <path
                                    id="godet_35_"
                                    d="M684.8 295.3l2.4-3.4c.3-.5.2-1.1-.2-1.4l-.3-.2c-.5-.3-1.1-.2-1.4.2l-2.4 3.4c-.2.2-.1.5.1.7l1.1.8c.2.2.5.1.7-.1z"
                                    className="st67"
                                />
                            </g>
                            <g id="axe">
                                <path
                                    id="poutre_axe"
                                    fill="#a67c52"
                                    d="M640.6 302.3h11v11h-11z"
                                    transform="rotate(-180 646 307.8)"
                                />
                                <circle id="axe_bois" cx="646.1" cy="307.8" r="5.5" className="st69" />
                                <circle id="axe_metal" cx="646.1" cy="307.8" r="2" className="st70" />
                            </g>
                            <path
                                id="cercle"
                                d="M646.1 272.8c7.7 0 15 2.5 21.1 7.1l1.1.9a34.8 34.8 0 015.6 48.1l-.9 1.2a34.8 34.8 0 01-48.1 5.6l-1.1-.9a34.8 34.8 0 01-5.6-48.1l.5-.7.3-.4a35 35 0 0127.1-12.8m0-6a40.9 40.9 0 00-31.6 14.9l-1.1 1.3a41.1 41.1 0 006.6 56.4l1.3 1.1a41.1 41.1 0 0056.4-6.6l1.1-1.3a41.1 41.1 0 00-6.6-56.4l-1.4-1.1a40.6 40.6 0 00-24.7-8.3z"
                                className="st69"
                            />
                            <g id="poutres">
                                <path d="M636.6 271h4v42.3h-4z" className="st69" />
                                <path d="M659.7 279.2h4v42.3h-4z" className="st69" transform="rotate(90 661.7 300.3)" />
                                <path d="M628.4 294.2h4v42.3h-4z" className="st69" transform="rotate(90 630.4 315.3)" />
                                <path d="M651.6 302.3h4v42.3h-4z" className="st69" />
                                <path d="M655 270.6h4V305h-4z" className="st69" transform="rotate(45 657 287.9)" />
                                <path d="M633.2 310.6h4V345h-4z" className="st69" transform="rotate(45 635.2 327.8)" />
                                <path d="M624.1 279.7h4v34.4h-4z" className="st69" transform="rotate(135 626.1 297)" />
                                <path d="M664 301.5h4v34.4h-4z" className="st69" transform="rotate(135 666 318.7)" />
                            </g>
                            <g id="plaques_1_">
                                <g id="plaque">
                                    <path
                                        d="M640.1 268.9h-3c-.3 0-.5.2-.5.5v7.8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-7.8c0-.3-.2-.5-.5-.5z"
                                        className="st67"
                                    />
                                    <circle cx="638.6" cy="270.9" r=".9" className="st71" />
                                    <circle cx="638.6" cy="275.7" r=".9" className="st71" />
                                </g>
                                <g id="plaque_1_">
                                    <path
                                        d="M655.1 337.7h-3c-.3 0-.5.2-.5.5v7.8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-7.8c0-.2-.3-.5-.5-.5z"
                                        className="st67"
                                    />
                                    <circle cx="653.6" cy="339.7" r=".9" className="st71" />
                                    <circle cx="653.5" cy="344.6" r=".9" className="st71" />
                                </g>
                                <g id="plaque_2_">
                                    <path
                                        d="M675.9 298.8v3c0 .3.2.5.5.5h7.8c.3 0 .5-.2.5-.5v-3c0-.3-.2-.5-.5-.5h-7.8c-.2 0-.5.2-.5.5z"
                                        className="st67"
                                    />
                                    <circle cx="677.9" cy="300.3" r=".9" className="st71" />
                                    <circle cx="682.8" cy="300.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_3_">
                                    <path
                                        d="M607.4 313.8v3c0 .3.2.5.5.5h7.8c.3 0 .5-.2.5-.5v-3c0-.3-.2-.5-.5-.5h-7.8c-.3 0-.5.2-.5.5z"
                                        className="st67"
                                    />
                                    <circle cx="609.4" cy="315.3" r=".9" className="st71" />
                                    <circle cx="614.2" cy="315.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_4_">
                                    <path
                                        d="M615.2 283.9l-2.1 2.1c-.2.2-.2.5 0 .7l5.5 5.5c.2.2.5.2.7 0l2.1-2.1c.2-.2.2-.5 0-.7l-5.5-5.5a.5.5 0 00-.7 0z"
                                        className="st67"
                                    />
                                    <circle cx="615.6" cy="286.4" r=".9" className="st71" />
                                    <circle cx="619" cy="289.8" r=".9" className="st71" />
                                </g>
                                <g id="plaque_5_">
                                    <path
                                        d="M672.9 323.5l-2.1 2.1c-.2.2-.2.5 0 .7l5.5 5.5c.2.2.5.2.7 0l2.1-2.1c.2-.2.2-.5 0-.7l-5.5-5.5a.5.5 0 00-.7 0z"
                                        className="st67"
                                    />
                                    <circle cx="673.3" cy="326" r=".9" className="st71" />
                                    <circle cx="676.7" cy="329.4" r=".9" className="st71" />
                                </g>
                                <g id="plaque_6_">
                                    <path
                                        d="M630.4 334.7l-2.1-2.1a.5.5 0 00-.7 0l-5.5 5.5c-.2.2-.2.5 0 .7l2.1 2.1c.2.2.5.2.7 0l5.5-5.5v-.7z"
                                        className="st67"
                                    />
                                    <circle cx="627.9" cy="335.1" r=".9" className="st71" />
                                    <circle cx="624.4" cy="338.5" r=".9" className="st71" />
                                </g>
                                <g id="plaque_7_">
                                    <path
                                        d="M670.2 276.8l-2.1-2.1a.5.5 0 00-.7 0l-5.5 5.5c-.2.2-.2.5 0 .7l2.1 2.1c.2.2.5.2.7 0l5.5-5.5c.2-.2.2-.5 0-.7z"
                                        className="st67"
                                    />
                                    <circle cx="667.7" cy="277.1" r=".9" className="st71" />
                                    <circle cx="664.3" cy="280.5" r=".9" className="st71" />
                                </g>
                                <g id="plaque_9_">
                                    <path
                                        d="M630.1 274.9l-1.6-2.5c-.1-.2-.4-.3-.7-.1l-6.6 4.3c-.2.1-.3.4-.1.7l1.6 2.5c.1.2.4.3.7.1l6.6-4.3c.1-.2.2-.5.1-.7z"
                                        className="st67"
                                    />
                                    <circle cx="627.6" cy="274.7" r=".9" className="st71" />
                                    <circle cx="623.5" cy="277.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_11_">
                                    <path
                                        d="M611.5 295.9l-3-.6c-.3-.1-.5.1-.6.4l-1.6 7.7c-.1.3.1.5.4.6l3 .6c.3.1.5-.1.6-.4l1.6-7.7c0-.3-.2-.6-.4-.6z"
                                        className="st67"
                                    />
                                    <circle cx="609.6" cy="297.5" r=".9" className="st71" />
                                    <circle cx="608.6" cy="302.2" r=".9" className="st71" />
                                </g>
                                <g id="plaque_13_">
                                    <path
                                        d="M613.2 323.8l-2.5 1.6c-.2.1-.3.4-.1.7l4.3 6.6c.1.2.4.3.7.1l2.5-1.6c.2-.1.3-.4.1-.7l-4.3-6.6a.6.6 0 00-.7-.1z"
                                        className="st67"
                                    />
                                    <circle cx="613" cy="326.3" r=".9" className="st71" />
                                    <circle cx="615.6" cy="330.4" r=".9" className="st71" />
                                </g>
                                <g id="plaque_15_">
                                    <path
                                        d="M634.1 342.4l-.6 3c-.1.3.1.5.4.6l7.7 1.6c.3.1.5-.1.6-.4l.6-3c.1-.3-.1-.5-.4-.6l-7.7-1.6c-.3 0-.5.1-.6.4z"
                                        className="st67"
                                    />
                                    <circle cx="635.8" cy="344.3" r=".9" className="st71" />
                                    <circle cx="640.5" cy="345.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_17_">
                                    <path
                                        d="M662.1 340.7l1.6 2.5c.1.2.4.3.7.1l6.6-4.3c.2-.1.3-.4.1-.7l-1.6-2.5c-.1-.2-.4-.3-.7-.1l-6.6 4.3c-.2.2-.3.5-.1.7z"
                                        className="st67"
                                    />
                                    <circle cx="664.6" cy="340.9" r=".9" className="st71" />
                                    <circle cx="668.7" cy="338.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_19_">
                                    <path
                                        d="M680.7 319.7l3 .6c.3.1.5-.1.6-.4l1.6-7.7c.1-.3-.1-.5-.4-.6l-3-.6c-.3-.1-.5.1-.6.4l-1.6 7.7c-.1.3.1.6.4.6z"
                                        className="st67"
                                    />
                                    <circle cx="682.6" cy="318.1" r=".9" className="st71" />
                                    <circle cx="683.6" cy="313.4" r=".9" className="st71" />
                                </g>
                                <g id="plaque_21_">
                                    <path
                                        d="M679 291.8l2.5-1.6c.2-.1.3-.4.1-.7l-4.3-6.6c-.1-.2-.4-.3-.7-.1l-2.5 1.6c-.2.1-.3.4-.1.7l4.3 6.6c.1.2.4.2.7.1z"
                                        className="st67"
                                    />
                                    <circle cx="679.1" cy="289.3" r=".9" className="st71" />
                                    <circle cx="676.5" cy="285.2" r=".9" className="st71" />
                                </g>
                                <g id="plaque_23_">
                                    <path
                                        d="M658 273.2l.6-3c.1-.3-.1-.5-.4-.6l-7.7-1.6c-.3-.1-.5.1-.6.4l-.6 3c-.1.3.1.5.4.6l7.7 1.6c.3.1.5-.1.6-.4z"
                                        className="st67"
                                    />
                                    <circle cx="656.4" cy="271.3" r=".9" className="st71" />
                                    <circle cx="651.6" cy="270.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_8_">
                                    <path
                                        d="M634.2 302.9l-2.1 2.1c-.2.2-.2.5 0 .7l5.5 5.5c.2.2.5.2.7 0l2.1-2.1c.2-.2.2-.5 0-.7l-5.5-5.5a.5.5 0 00-.7 0z"
                                        className="st67"
                                    />
                                    <circle cx="634.5" cy="305.3" r=".9" className="st71" />
                                    <circle cx="638" cy="308.8" r=".9" className="st71" />
                                </g>
                                <g id="plaque_10_">
                                    <path
                                        d="M653.9 304.4l-2.1 2.1c-.2.2-.2.5 0 .7l5.5 5.5c.2.2.5.2.7 0l2.1-2.1c.2-.2.2-.5 0-.7l-5.5-5.5a.5.5 0 00-.7 0z"
                                        className="st67"
                                    />
                                    <circle cx="654.2" cy="306.9" r=".9" className="st71" />
                                    <circle cx="657.6" cy="310.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_12_">
                                    <path
                                        d="M651 295.9l-2.1-2.1a.5.5 0 00-.7 0l-5.5 5.5c-.2.2-.2.5 0 .7l2.1 2.1c.2.2.5.2.7 0l5.5-5.5c.2-.2.2-.5 0-.7z"
                                        className="st67"
                                    />
                                    <circle cx="648.6" cy="296.3" r=".9" className="st71" />
                                    <circle cx="645.1" cy="299.7" r=".9" className="st71" />
                                </g>
                                <g id="plaque_14_">
                                    <path
                                        d="M649.5 315.6l-2.1-2.1a.5.5 0 00-.7 0l-5.5 5.5c-.2.2-.2.5 0 .7l2.1 2.1c.2.2.5.2.7 0l5.5-5.5c.2-.2.2-.5 0-.7z"
                                        className="st67"
                                    />
                                    <circle cx="647" cy="315.9" r=".9" className="st71" />
                                    <circle cx="643.6" cy="319.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_16_">
                                    <path
                                        d="M640.1 293.5h-3c-.3 0-.5.2-.5.5v7.8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5V294c0-.3-.2-.5-.5-.5z"
                                        className="st67"
                                    />
                                    <circle cx="638.6" cy="295.5" r=".9" className="st71" />
                                    <circle cx="638.6" cy="300.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_18_">
                                    <path
                                        d="M660.4 301.8v-3c0-.3-.2-.5-.5-.5H652c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5h7.8c.4 0 .6-.2.6-.5z"
                                        className="st67"
                                    />
                                    <circle cx="658.4" cy="300.3" r=".9" className="st71" />
                                    <circle cx="653.5" cy="300.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_20_">
                                    <path
                                        d="M652 322.1h3c.3 0 .5-.2.5-.5v-7.8c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5v7.8c.1.3.3.5.5.5z"
                                        className="st67"
                                    />
                                    <circle cx="653.6" cy="320.1" r=".9" className="st71" />
                                    <circle cx="653.6" cy="315.3" r=".9" className="st71" />
                                </g>
                                <g id="plaque_22_">
                                    <path
                                        d="M640.6 316.8v-3c0-.3-.2-.5-.5-.5h-7.8c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5h7.8c.3 0 .5-.2.5-.5z"
                                        className="st67"
                                    />
                                    <circle cx="638.6" cy="315.3" r=".9" className="st71" />
                                    <circle cx="633.7" cy="315.3" r=".9" className="st71" />
                                </g>
                            </g>
                        </g>
                        <path
                            id="eau"
                            d="M655.2 267.9a40.3 40.3 0 0130.6 48.7 41.3 41.3 0 01-7.8 16.5s-1.2 3-.8 9.2c0 2.2 1.8 4.7 4.3 5.2 3 .7 6-1.2 6.6-4.2l.1-1.6c-1.6-14 1.9-24.1 2.6-27.5a46 46 0 00-18.7-43.6 42 42 0 00-17.8-6.8c-6.6-.9-10.8-.6-12.5-.7v2h7.7v1.9l5.7.9z"
                            className="st0"
                        />
                        <g id="particules_d_x27_eau">
                            <circle id="goutte_18_" cx="641.4" cy="260.7" r=".4" className="st72" />
                            <path
                                id="goutte_17_"
                                d="M640.5 263.2c.5-.2.8-.8.6-1.3-.2-.5-.8-.8-1.3-.6-.5.2-1.1.7-1.4 1.9 1.1.2 1.6.2 2.1 0z"
                                className="st72"
                            />
                            <path
                                id="goutte_16_"
                                d="M637 266.7c.5.1 1.1-.2 1.2-.7.1-.5-.2-1.1-.7-1.2s-1.3-.1-2.2.7c.8.8 1.2 1.1 1.7 1.2z"
                                className="st72"
                            />
                            <path
                                id="goutte_15_"
                                d="M650.2 265.8c.5.3 1.1.1 1.3-.4s.1-1.1-.4-1.3c-.5-.3-1.3-.4-2.3.2.6.9.9 1.2 1.4 1.5z"
                                className="st72"
                            />
                            <circle id="goutte_14_" cx="639.5" cy="259.8" r=".4" className="st72" />
                            <circle id="goutte_13_" cx="646.6" cy="264.4" r=".4" className="st72" />
                            <circle id="goutte_12_" cx="637.2" cy="260.8" r=".4" className="st72" />
                            <circle id="goutte_11_" cx="647.3" cy="265" r=".4" className="st72" />
                            <circle id="goutte_10_" cx="654.6" cy="268.9" r=".4" className="st72" />
                            <circle id="goutte_9_" cx="641.4" cy="260.7" r=".4" className="st72" />
                            <circle id="goutte_8_" cx="643.7" cy="266.9" r=".4" className="st72" />
                            <circle id="goutte_7_" cx="633" cy="264.4" r=".4" className="st72" />
                            <circle id="goutte_6_" cx="640.8" cy="261.4" r=".4" className="st72" />
                            <circle id="goutte_5_" cx="652.6" cy="265" r=".4" className="st72" />
                            <circle id="goutte_4_" cx="654.8" cy="266.8" r=".8" className="st72" />
                            <circle id="goutte_3_" cx="657.4" cy="266.2" r=".4" className="st72" />
                            <circle id="goutte_2_" cx="651" cy="266.1" r=".8" className="st72" />
                            <circle id="goutte_1_" cx="646.3" cy="266.1" r=".6" className="st72" />
                            <circle id="goutte" cx="644.5" cy="264.7" r="1.5" className="st72" />
                            <circle id="goutte_37_" cx="640.7" cy="260.5" r=".4" className="st72" />
                            <path
                                id="goutte_36_"
                                d="M639.8 263c.5-.2.8-.8.6-1.3-.2-.5-.8-.8-1.3-.6-.5.2-1.1.7-1.4 1.9 1.1.1 1.6.2 2.1 0z"
                                className="st72"
                            />
                            <path
                                id="goutte_35_"
                                d="M636.4 266.5c.5.1 1.1-.2 1.2-.7.1-.5-.2-1.1-.7-1.2-.5-.1-1.3-.1-2.2.7.8.7 1.1 1 1.7 1.2z"
                                className="st72"
                            />
                            <path
                                id="goutte_34_"
                                d="M649.5 265.5c.5.3 1.1.1 1.3-.4.3-.5.1-1.1-.4-1.3s-1.3-.4-2.3.2c.7.9 1 1.3 1.4 1.5z"
                                className="st72"
                            />
                            <circle id="goutte_33_" cx="638.8" cy="259.5" r=".4" className="st72" />
                            <circle id="goutte_32_" cx="646" cy="264.1" r=".4" className="st72" />
                            <circle id="goutte_31_" cx="636.5" cy="260.5" r=".4" className="st72" />
                            <circle id="goutte_30_" cx="646.7" cy="264.7" r=".4" className="st72" />
                            <circle id="goutte_29_" cx="653.9" cy="268.6" r=".4" className="st72" />
                            <circle id="goutte_28_" cx="640.7" cy="260.5" r=".4" className="st72" />
                            <circle id="goutte_27_" cx="643.1" cy="266.6" r=".4" className="st72" />
                            <circle id="goutte_26_" cx="632.3" cy="264.2" r=".4" className="st72" />
                            <circle id="goutte_25_" cx="640.1" cy="261.1" r=".4" className="st72" />
                            <circle id="goutte_24_" cx="652" cy="264.7" r=".4" className="st72" />
                            <circle id="goutte_23_" cx="654.1" cy="266.6" r=".8" className="st72" />
                            <circle id="goutte_22_" cx="656.8" cy="266" r=".4" className="st72" />
                            <circle id="goutte_21_" cx="650.4" cy="265.8" r=".8" className="st72" />
                            <circle id="goutte_20_" cx="645.6" cy="265.8" r=".6" className="st72" />
                            <circle id="goutte_19_" cx="643.9" cy="264.5" r="1.5" className="st72" />
                        </g>
                    </g>
                    <circle
                        id="fenetre"
                        cx="645.1"
                        cy="242.9"
                        r="6.9"
                        fill="#acd4ea"
                        stroke="#f29876"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <g id="v_2_">
                        <g id="branches">
                            <path
                                d="M582.4 297.6c1.2-1.9 2.2-4.3 2.8-6.8.7-2.5 1.1-5 1.4-7.6.2-2.6.2-5.2-.3-7.5-.2-1.2-.6-2.3-1.1-3.3-.8-1.6-1.2-3.2-1.3-4.7-.3-3.1.1-6.2 1-9s2.2-5.5 3.9-7.9a32 32 0 0113.3-10.9c2.6-1.2 5.3-2.1 8-2.8l8.1-1.8a53.6 53.6 0 0015.1-5.9c2.5-1.5 5.3-2.3 8.1-2.6 2.8-.3 5.6-.1 8.4.4a45.5 45.5 0 0115.4 6 41.7 41.7 0 00-23.6-5.3c-2.7.4-5.2 1.3-7.4 2.7a51.8 51.8 0 01-15.2 6.8c-2.7.7-5.4 1.3-7.9 2.1a44 44 0 00-7.4 3 27.3 27.3 0 00-14.5 17.3c-.6 2.4-.9 5-.5 7.3.2 1.2.5 2.3 1 3.1.8 1.4 1.3 2.9 1.7 4.4.7 3 .9 6 .8 8.9-.1 2.9-.5 5.8-1.1 8.7-.7 2.9-1.5 5.7-3.2 8.6l-5.5-3.2z"
                                className="st74"
                            />
                            <path
                                d="M587.4 255.7a61.1 61.1 0 0123.8-5c4-.1 8-.1 11.8-1 3.8-.9 7.3-3 10.5-5.4-3 2.6-6.4 5-10.3 6.1-3.9 1.2-8 1.4-11.9 1.8-2 .1-3.9.4-5.9.7-1.9.3-3.8.8-5.7 1.3a68.2 68.2 0 00-10.9 4.2l-1.4-2.7z"
                                className="st74"
                            />
                            <path
                                d="M624.1 249.4a57.6 57.6 0 0017.5 5.5l6.1.7a59.7 59.7 0 01-24.1-5.3l.5-.9zM630.2 230.9a12.3 12.3 0 019.1 1.3c2.7 1.3 5.4 2.7 8.2 3.8 2.8 1 5.7 1.9 8.7 2.4l1.1.2 1.1.2 2.2.3 4.5.4-4.5-.3-2.2-.3-1.1-.1-1.1-.2c-3-.4-5.9-1.2-8.8-2.2-2.9-1-5.6-2.3-8.3-3.5a17 17 0 00-4.2-1.3c-1.4-.2-2.9 0-4.3.3l-.4-1z"
                                className="st74"
                            />
                            <path
                                d="M586.8 289.6a17.7 17.7 0 01-1.3-6.6c0-.7 0-1.5.4-2.2-.2.7-.1 1.4.1 2.1l.6 2c.5 1.3 1.1 2.6 1.9 3.7l-1.7 1z"
                                className="st67"
                            />
                            <path
                                d="M594.9 253.4c1.1 0 2.1.1 3.1.3a10.8 10.8 0 015.2 3.3c.7.7 1.2 1.6 1.8 2.4l-2.1-2a9.7 9.7 0 00-5-2.2c-.9-.1-1.8 0-2.7.2l-.3-2z"
                                className="st74"
                            />
                        </g>
                        <g id="v_1_">
                            <path
                                id="vigne_50_"
                                d="M591.6 251.2c-.6.6-1 1.4-1.1 2.3l.1.1c1.6.1 3.1-1.2 3.3-3.1l-.1-.1c-.8-.1-1.6.2-2.2.8z"
                                className="st8"
                            />
                            <path
                                id="vigne_49_"
                                d="M604.5 252.1l.2-.1c.5.1.9.4 1.2.6-.2-.3-.4-.8-.4-1.2l.1-.2c2.2-1.5 4.4-.7 4.9 1.8l-.1.2c-.4.3-.9.5-1.3.6.6.7.9 1.8.6 3.1l-.2.2c-1.3.2-2.4-.2-3-1l-.6 1-.2.1c-2.3-.7-2.9-3-1.2-5.1z"
                                className="st17"
                            />
                            <path
                                id="vigne_48_"
                                d="M649.7 237.7v-.2l.9-.7-1.1-.1-.1-.1c-.3-2.2 1-3.5 3-3.1l.1.1v1.2c.7-.2 1.6 0 2.5.6l.1.2c-.3 1.1-1 1.8-1.8 1.9l.6.8v.2c-1.3 1.6-3.2 1.2-4.2-.8z"
                                className="st12"
                            />
                            <path
                                id="vigne_47_"
                                d="M603.1 257.8h.1l.7.4-.4-.7v-.1c1.1-.7 2.4-.1 2.9 1.4v.1l-.6.3c.4.5.7 1.1.7 1.9l-.1.1c-.7 0-1.3-.3-1.8-.8l-.2.6h-.1c-1.4-.8-1.9-2.1-1.2-3.2z"
                                className="st75"
                            />
                            <path
                                id="vigne_45_"
                                d="M625.7 234.1l-.1-.1.2-1-.8.6h-.2c-1.5-1.3-1.4-3 .2-3.9h.2l.7.8c.4-.6 1.1-1 2.1-1.1l.2.1c.5.9.4 1.8-.1 2.4l.9.2.1.1c0 1.8-1.5 2.7-3.4 1.9z"
                                className="st75"
                            />
                            <path
                                id="vigne_44_"
                                d="M625.2 248.5l.2-.2c.6-.1 1.1 0 1.6.1-.4-.3-.7-.6-1-1.1v-.3c1.7-2.6 4.4-2.7 6.1-.3v.3c-.3.5-.7.9-1.1 1.2a4 4 0 012.1 3l-.1.3c-1.3.9-2.7.9-3.7.2l-.2 1.4-.2.2c-2.9.3-4.5-1.8-3.7-4.8z"
                                className="st8"
                            />
                            <path
                                id="vigne_43_"
                                d="M590.4 256.3l.2.1.5.9.3-1 .2-.1c2.1.1 3.1 1.6 2.3 3.3l-.2.1-1.1-.2c.1.7-.2 1.5-1 2.3 0 0-.1.1-.2 0-1-.5-1.5-1.2-1.4-2l-.9.4-.2-.1c-1.2-1.4-.5-3.1 1.5-3.7z"
                                className="st8"
                            />
                            <path
                                id="vigne_42_"
                                d="M602.1 240.3l-.2.2-1.6-.1 1 1.1v.3c-1.8 2.5-4.5 2.7-6.1.3v-.3c.3-.5.7-.9 1.1-1.2-1-.5-1.7-1.5-2-3.1l.1-.3c1.3-.9 2.7-.9 3.7-.2l.2-1.4.2-.2c2.8-.3 4.4 1.9 3.6 4.9z"
                                className="st16"
                            />
                            <path
                                id="vigne_41_"
                                d="M619.2 249.9l.1.1v1.2l.7-.8h.2c2 1.1 2.3 3 .7 4.4h-.2l-1-.7c-.3.8-1 1.4-2.1 1.7l-.2-.1c-.7-.9-.9-1.9-.4-2.7l-1-.1-.1-.1c-.4-2 1.1-3.3 3.3-2.9z"
                                className="st16"
                            />
                            <path
                                id="vigne_40_"
                                d="M617.3 236.8h.1c.3.1.5.3.6.5l-.1-.7.1-.1c1.4-.6 2.6.1 2.6 1.5l-.1.1-.8.2c.3.5.3 1.1 0 1.8l-.1.1c-.8 0-1.4-.4-1.6-.9l-.5.5h-.1c-1.2-.6-1.3-2-.1-3z"
                                className="st16"
                            />
                            <path
                                id="vigne_39_"
                                d="M611.3 238.6l-.1-.2.4-1.5-1.3.8-.3-.1c-2.2-2.2-1.8-4.9.8-6l.3.1 1 1.3c.7-.9 1.8-1.4 3.4-1.5l.2.1c.6 1.5.4 2.8-.5 3.7.4.1.9.2 1.3.5l.1.2c-.1 2.8-2.5 4-5.3 2.6z"
                                className="st75"
                            />
                            <path
                                id="vigne_38_"
                                d="M596 247.9h-.2a7 7 0 01-.8-1l-.1 1.2-.2.1c-2.5.4-4.1-1.2-3.5-3.5l.2-.1h1.3c-.3-.9 0-1.9.7-2.9l.2-.1c1.2.3 2 1.1 2.2 2.1l1-.6h.2c1.7 1.5 1.3 3.6-1 4.8z"
                                className="st17"
                            />
                            <path
                                id="vigne_37_"
                                d="M588.1 254.7l-.2.1c-.5 0-1 0-1.4-.2l.9 1v.2c-1.7 2.2-4.1 2.2-5.5 0v-.2l1-1c-.9-.5-1.5-1.5-1.7-2.8l.1-.2c1.2-.7 2.4-.7 3.3 0l.3-1.3.2-.1c2.5-.2 3.9 1.8 3 4.5z"
                                className="st7"
                            />
                            <path
                                id="vigne_36_"
                                d="M615.4 252.7a5.2 5.2 0 00-3.7-1.5l-.2.2c0 2.9 2.3 5.2 5.2 5.2l.2-.2c0-1.5-.6-2.8-1.5-3.7z"
                                className="st16"
                            />
                            <path
                                id="vigne_35_"
                                d="M636.4 243.6c1-.9 1.6-2.2 1.6-3.7l-.2-.2a5.1 5.1 0 00-5.3 5.1l.2.2c1.4.1 2.8-.5 3.7-1.4z"
                                className="st17"
                            />
                            <path
                                id="vigne_34_"
                                d="M597.2 256.5a4 4 0 00-3-1.2l-.1.1c0 2.3 1.9 4.2 4.2 4.2l.1-.1c0-1.2-.5-2.3-1.2-3z"
                                className="st17"
                            />
                            <path
                                id="vigne_33_"
                                d="M605.4 239c.1-.8-.1-1.6-.7-2.3h-.1c-1.4 1.2-1.7 3.2-.6 4.5h.1c.7-.6 1.2-1.4 1.3-2.2z"
                                className="st76"
                            />
                            <path
                                id="vigne_32_"
                                d="M607.7 241.8a5.1 5.1 0 002.8-2.8l-.1-.2a5.2 5.2 0 00-6.8 2.9l.1.2a5 5 0 004-.1z"
                                className="st8"
                            />
                            <path
                                id="vigne_31_"
                                d="M625.4 236.6a5 5 0 00-4-.5l-.1.2a5.3 5.3 0 006.4 3.7l.1-.2a5.6 5.6 0 00-2.4-3.2z"
                                className="st14"
                            />
                            <path
                                id="vigne_30_"
                                d="M632 227.3c-1 .9-1.6 2.2-1.6 3.6l.2.2c2.9.1 5.3-2.2 5.4-5l-.2-.2c-1.5 0-2.8.5-3.8 1.4z"
                                className="st17"
                            />
                            <path
                                id="vigne_29_"
                                d="M621.8 232a5 5 0 00-1.6-3.6c-.1-.1-.2-.1-.2 0-2 2.1-1.9 5.4.2 7.4.1.1.2.1.2 0a6.2 6.2 0 001.4-3.8z"
                                className="st17"
                            />
                            <path
                                id="vigne_28_"
                                d="M613.6 253.6c-.2-.9-.8-1.7-1.7-2.2h-.1c-1 1.7-.4 3.9 1.4 4.9h.1c.4-.9.5-1.8.3-2.7z"
                                className="st75"
                            />
                            <path
                                id="vigne_27_"
                                d="M589.3 247.5c-.5-1.1-1.5-2-2.7-2.4l-.2.1c-.9 2.5.5 5.2 3 6.1l.2-.1c.3-1.3.2-2.6-.3-3.7z"
                                className="st17"
                            />
                            <path
                                id="vigne_26_"
                                d="M638.8 227.8l-.1-.1-.2-.8-.3.7s-.1.1-.1 0c-1.5-.4-2-1.6-1.2-2.8 0 0 .1-.1.1 0l.8.3c.1-.5.4-1.1 1.1-1.5h.1c.6.5.9 1.1.8 1.7l.7-.1.1.1c.5 1.2-.2 2.4-1.8 2.5z"
                                className="st76"
                            />
                            <path
                                id="vigne_25_"
                                d="M638.8 257c-.4.9-.4 2 0 2.9l.2.1c2.1-.9 3.1-3.2 2.2-5.1l-.2-.1a4.3 4.3 0 00-2.2 2.2z"
                                className="st75"
                            />
                            <path
                                id="vigne_24_"
                                d="M644.1 255.6l.1-.1.8-.1a3 3 0 01-.7-.5v-.1c.6-1.6 2-2 3.2-.9v.1l-.4.8c.6.1 1.1.6 1.5 1.4v.1c-.6.6-1.3.8-1.9.6l.1.8-.1.1c-1.6.5-2.7-.5-2.6-2.2z"
                                className="st7"
                            />
                            <path
                                id="vigne_23_"
                                d="M635.9 255a3.6 3.6 0 00-1.9-1.5l-.1.1c-.4 1.7.6 3.4 2.3 3.9l.1-.1c.2-.9.1-1.7-.4-2.4z"
                                className="st14"
                            />
                            <path
                                id="vigne_22_"
                                d="M634.8 255.2c0-.6-.2-1.1-.7-1.5h-.1c-.8.9-.8 2.3.1 3.1h.1c.4-.5.6-1 .6-1.6z"
                                className="st18"
                            />
                            <path
                                id="vigne_21_"
                                d="M637.3 231.3h.2l1 1.1V231l.2-.2c2.7-.6 4.6 1 4.2 3.6l-.2.2-1.5.1c.4.9.2 2.1-.5 3.3l-.2.1c-1.4-.3-2.3-1.1-2.6-2.2l-1 .8h-.2c-2.1-1.6-1.8-4 .6-5.4z"
                                className="st75"
                            />
                            <path
                                id="vigne_20_"
                                d="M629.7 228.5c-.2.8-.1 1.7.3 2.5 0 0 .1.1.1 0 1.6-.9 2.2-3 1.3-4.6 0 0-.1-.1-.1 0a4 4 0 00-1.6 2.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_19_"
                                d="M622.5 252.4c.2.8.7 1.6 1.5 2.1h.1c1-1.5.6-3.6-1-4.6h-.1c-.6.7-.7 1.6-.5 2.5z"
                                className="st75"
                            />
                            <path
                                id="vigne_18_"
                                d="M666.2 230.5c-.8.3-1.6.9-2 1.8v.1c1.6.8 3.6.1 4.5-1.7v-.1c-.8-.4-1.7-.4-2.5-.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_17_"
                                d="M661.6 239.3l.1-.1.9-.1a3 3 0 01-.7-.5v-.2c.8-1.6 2.3-2 3.5-.8v.2l-.5.8c.6.2 1.2.7 1.5 1.6v.2c-.7.6-1.5.8-2.1.5v.8l-.1.1c-1.6.4-2.8-.7-2.6-2.5z"
                                className="st75"
                            />
                            <path
                                id="vigne_16_"
                                d="M658.8 240.3c-.3-.8-1-1.5-1.8-1.8l-.1.1c-.7 1.7.1 3.7 1.9 4.4l.1-.1c.3-.9.2-1.8-.1-2.6z"
                                className="st76"
                            />
                            <path
                                id="vigne_15_"
                                d="M650.2 228.1l.1-.1h1l-.7-.6v-.2c.9-1.8 2.7-2 3.9-.6v.2l-.6.8c.7.2 1.2.9 1.5 1.8v.2c-.8.6-1.7.8-2.4.4v.9l-.1.1c-1.9.3-3.1-1-2.7-2.9z"
                                className="st14"
                            />
                            <path
                                id="vigne_14_"
                                d="M646.3 224.2a5.1 5.1 0 00-2.8 2.8l.1.2a5.2 5.2 0 006.8-2.9l-.1-.2a5.5 5.5 0 00-4 .1z"
                                className="st75"
                            />
                            <path
                                id="vigne_13_"
                                d="M643.1 223.9c-.3 1-.2 2.1.4 3.1 0 .1.1.1.2 0a4 4 0 001.5-5.5c0-.1-.1-.1-.2 0-1 .5-1.7 1.4-1.9 2.4z"
                                className="st15"
                            />
                            <path
                                id="vigne_12_"
                                d="M658.2 231.3c-.1-.9-.5-1.7-1.3-2.2h-.1a3.3 3.3 0 00.6 4.7h.1c.5-.7.8-1.6.7-2.5z"
                                className="st75"
                            />
                            <path
                                id="vigne_11_"
                                d="M659 231v-.2c.2-.3.5-.5.8-.6l-1-.1-.1-.1c-.3-2 .9-3.2 2.7-2.8l.1.1v1.1c.7-.2 1.5-.1 2.3.5l.1.2c-.2 1-.9 1.6-1.6 1.7l.5.8v.2c-1.2 1.3-2.9 1-3.8-.8z"
                                className="st76"
                            />
                            <path
                                id="vigne_10_"
                                d="M586.9 282.5c-.1.1-.2.1-.2 0-.5-.2-.9-.5-1.2-.8.2.4.3.8.3 1.3l-.1.2c-2.5 1.2-4.7.1-4.9-2.5l.1-.2 1.4-.5c-.6-.8-.7-2-.3-3.3l.2-.2c1.4-.1 2.5.5 3 1.5l.8-1c.1-.1.2-.1.2 0 2.5 1.2 2.7 3.6.7 5.5z"
                                className="st77"
                            />
                            <path
                                id="vigne_9_"
                                d="M586.3 288.4a4 4 0 00-2.7-1.4l-.1.1a4 4 0 003.6 4.3l.1-.1a4 4 0 00-.9-2.9z"
                                className="st8"
                            />
                            <path
                                id="vigne_8_"
                                d="M589.8 272.7l-.1-.2.2-1-.8.6h-.2c-1.5-1.4-1.3-3.2.4-4h.2l.7.8c.4-.6 1.2-1 2.2-1.1l.2.1c.4 1 .3 1.9-.2 2.5l.9.3.1.2c-.1 1.8-1.7 2.6-3.6 1.8z"
                                className="st14"
                            />
                            <path
                                id="vigne_7_"
                                d="M585.7 272.1c-.8-.4-1.7-.6-2.6-.3l-.1.1a3.4 3.4 0 004.2 2.4l.1-.1c-.3-1-.9-1.7-1.6-2.1z"
                                className="st14"
                            />
                            <path
                                id="vigne_6_"
                                d="M587.3 272.8c-.1-.5-.4-.9-.9-1.2h-.1a2 2 0 00.7 2.6h.1c.3-.4.3-.9.2-1.4z"
                                className="st75"
                            />
                            <path
                                id="vigne_5_"
                                d="M590.5 288.4l-.1-.2.2-1-.8.6h-.2c-1.6-1.4-1.4-3.2.3-4h.2l.7.8c.4-.6 1.2-1 2.2-1.1l.2.1c.5 1 .4 1.8-.2 2.5l.9.3.1.2c0 1.8-1.6 2.7-3.5 1.8z"
                                className="st17"
                            />
                            <path
                                id="vigne_4_"
                                d="M586.4 268.6s-.1.1-.1 0l-.7-.3.3.6v.1c-1.2.9-2.4.6-2.8-.7v-.1l.7-.4c-.4-.4-.6-.9-.5-1.7l.1-.1c.7-.2 1.3 0 1.7.4l.3-.6s.1-.1.1 0c1.3.3 1.7 1.5.9 2.8z"
                                className="st75"
                            />
                            <path
                                id="vigne_3_"
                                d="M590.5 274.5c-.9.9-1.5 2.2-1.5 3.7l.2.2c2.9 0 5.2-2.3 5.2-5.2l-.2-.2c-1.4 0-2.7.6-3.7 1.5z"
                                className="st14"
                            />
                            <path
                                id="vigne_2_"
                                d="M591.4 276.3c-.9.2-1.7.8-2.2 1.7v.1c1.7 1 3.9.4 4.9-1.4v-.1c-.8-.4-1.8-.5-2.7-.3z"
                                className="st75"
                            />
                            <path
                                id="vigne_1_"
                                d="M610.3 243.9l.1-.1.9-.3a3 3 0 01-.8-.4l-.1-.2c.5-1.8 2-2.4 3.4-1.4l.1.2-.4.9c.7.1 1.3.5 1.8 1.3v.2c-.6.8-1.3 1.1-2 .9l.2.8-.1.1c-1.6.8-2.9-.1-3.1-2z"
                                className="st76"
                            />
                            <path
                                id="vigne"
                                d="M608.2 245.9c.5.8 1.2 1.3 2.1 1.5l.1-.1c.4-1.9-.7-3.8-2.5-4.1l-.1.1c-.2.9 0 1.9.4 2.6z"
                                className="st14"
                            />
                        </g>
                    </g>
                    <g id="coursier">
                        <g id="structure">
                            <path
                                d="M641.7 262v5.3h-3.2v.1h-15.9v-.1h-10.4v.1h-61.7v-.2h-10.9v.2H403.5v-9.1h60v.2h8.7v-.2h10.3v.3h12.1v-.3H512v.2h18.9v-.1h8.3v.2h9.9v.2h14.7v-.3H583v.2h7.7v-.4h7.6v.4h8.7v-.3h7.5v.1h5.7v.2h4.2v-.1h6.4v.2h10.5v1h.3v2.2z"
                                className="st26"
                            />
                            <path
                                d="M579.4 268.9v9.4h.2v10.6h-.2v6.9h.2v.2h-3.8v-10.6h.2v-8.7h-.2v-9.9h3.8v2.1zM530.8 267.4v5.4h.1v7.2H527v-1.3h.3v-8.6h-.3v-7.2h3.9v4.5zM599.9 266.1v4.7h-.2V285h.2v16.9h-9.2v-35.8zM649.4 265.2v1.3h-.1v.9h-2v-.1h-5.6V265h1.9v.1h3.7v.1z"
                                className="st26"
                            />
                        </g>
                        <g id="plaques">
                            <g id="plaque_24_">
                                <path
                                    d="M579.2 258.6h-3c-.3 0-.5.2-.5.5v7.8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-7.8c0-.2-.2-.5-.5-.5z"
                                    className="st67"
                                />
                                <circle cx="577.7" cy="260.6" r=".9" className="st71" />
                                <circle cx="577.7" cy="265.5" r=".9" className="st71" />
                            </g>
                            <g id="plaque_25_">
                                <path
                                    d="M530.4 258.6h-3c-.3 0-.5.2-.5.5v7.8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-7.8c0-.2-.2-.5-.5-.5z"
                                    className="st67"
                                />
                                <circle cx="528.9" cy="260.6" r=".9" className="st71" />
                                <circle cx="528.9" cy="265.5" r=".9" className="st71" />
                            </g>
                        </g>
                    </g>
                    <path
                        id="meule"
                        d="M715.8 344.9l-2.2.6c-.9.3-1.9-.3-2.2-1.2l-6.9-24.2c-.3-.9.3-1.9 1.2-2.2l2.2-.6c.9-.3 1.9.3 2.2 1.2l6.9 24.2c.3 1-.2 1.9-1.2 2.2z"
                        className="st70"
                    />
                </g>
                <g id="maison_milieu">
                    <g id="cheminée">
                        <g id="fumee">
                            <circle id="fumee11" cx="989" cy="335.4" r="16.2" className="st80" />
                            <circle id="fumee14" cx="987.9" cy="336.3" r="14.7" className="st81" />
                            <circle id="fumee9" cx="989.7" cy="336.3" r="13.3" className="st82" />
                            <circle id="fumee13" cx="987.5" cy="336.3" r="12.4" className="st83" />
                            <circle id="fumee8" cx="994.3" cy="336.3" r="11.1" className="st84" />
                            <circle id="fumee12" cx="983.7" cy="336.1" r="9.6" className="st85" />
                            <circle id="fumee10" cx="991.9" cy="336.3" r="8" className="st86" />
                            <circle id="fumee4_2_" cx="989" cy="335.4" r="16.2" className="st80" />
                            <circle id="fumee7_2_" cx="993.5" cy="336.1" r="14.7" className="st81" />
                            <circle id="fumee2_2_" cx="985" cy="336.1" r="13.3" className="st82" />
                            <circle id="fumee6_2_" cx="994.2" cy="335.8" r="12.4" className="st83" />
                            <circle id="fumee1_2_" cx="987.2" cy="335.7" r="11.1" className="st84" />
                            <circle id="fumee5_2_" cx="990.8" cy="335.4" r="9.6" className="st85" />
                            <circle id="fumee3_2_" cx="995.5" cy="336.8" r="8" className="st86" />
                        </g>
                        <path d="M981.5 333.2h17.9v5h-17.9z" className="st87" />
                        <path d="M982.5 338.3h15.9v22.3h-15.9z" className="st25" />
                        <path
                            d="M988.7 357.7v-.9c0-1.3.9-2.3 2.1-2.3h7.8c.5 0 .9.5.9 1 0 1.1-.4 2.1-1 2.8-.6.7-1.5 1.2-2.5 1.2H990.3a2 2 0 01-1.6-1.8z"
                            className="st88"
                        />
                        <path
                            d="M998.8 347H996c-1 0-1.8.8-1.8 1.8 0 1.8 1.4 3.2 3.2 3.2h.3c1 0 1.8-.8 1.8-1.8v-2.4c.1-.5-.3-.8-.7-.8z"
                            className="st89"
                        />
                        <path
                            fill="#68686d"
                            d="M997.8 339.5h-6.9c-1 0-1.8.8-1.8 1.8v1.4c0 1 .8 1.7 1.7 1.7h7.4c.8 0 1.5-.7 1.5-1.5v-1.7a2 2 0 00-1.9-1.7z"
                        />
                        <path
                            d="M985.7 354.4h-2.8c-.8 0-1.5.7-1.5 1.5v2c0 .8.7 1.5 1.5 1.5h1.9a2 2 0 002-2v-1.9c0-.6-.5-1.1-1.1-1.1z"
                            className="st91"
                        />
                        <path
                            fill="#6d6d75"
                            d="M990.5 347h-6a3 3 0 00-3 3c0 1.1.9 1.9 1.9 1.9h7.9c1 0 1.7-.8 1.7-1.7v-.8c0-1.3-1.1-2.4-2.5-2.4z"
                        />
                        <path
                            fill="#868693"
                            d="M985.8 339.5h-1.6a2.4 2.4 0 00-2.4 2.4v.6c0 1.1.9 2 2 2h.4c1.8 0 3.3-1.5 3.3-3.3 0-1-.8-1.7-1.7-1.7z"
                        />
                    </g>
                    <g id="escaliers_gauche">
                        <path
                            d="M980.3 423.6v34.6h-41.8v-11.5h-23.4v-8.3h7.9v-1.9h-.1v-2.6h7.9v-1.8h-.1v-2.6h7.8V425l7.6-1.4h22.4z"
                            className="st25"
                        />
                        <path
                            d="M947.7 424c0 .6-.5 1-1.1 1h-8.2c-.6 0-1.1-.5-1.1-1 0-.6.5-1 1.1-1h8.2c.6-.1 1.1.4 1.1 1z"
                            className="st94"
                        />
                        <path
                            d="M958.1 423.7v.4c0 .5-.4.9-.9.9h-8.6a.9.9 0 01-.9-.9v-.4c0-.5.4-.9.9-.9h8.6c.5 0 .9.4.9.9z"
                            className="st95"
                        />
                        <path
                            d="M972 423.7v.4c0 .5-.5 1-1.2 1h-11.5c-.7 0-1.2-.4-1.2-1v-.4c0-.5.5-1 1.2-1h11.5c.7 0 1.2.4 1.2 1z"
                            className="st96"
                        />
                        <path
                            d="M939.3 428.4v.6c0 .4-.3.8-.7.8h-7.8c-.4 0-.7-.3-.7-.8v-.6c0-.4.3-.8.7-.8h7.8c.4 0 .7.4.7.8z"
                            className="st97"
                        />
                        <path
                            d="M931.3 432.6v1.2c0 .3-.2.5-.4.5h-8.4c-.2 0-.4-.2-.4-.5v-1.2c0-.3.2-.5.4-.5h8.4c.2 0 .4.2.4.5z"
                            className="st98"
                        />
                        <path
                            d="M923.3 437.2v1c0 .3-.2.6-.5.6h-8.2c-.3 0-.5-.3-.5-.6v-1c0-.3.2-.6.5-.6h8.2c.3-.1.5.2.5.6z"
                            className="st99"
                        />
                    </g>
                    <path id="facade" d="M971.8 379.3h82.7v94.9h-82.7z" className="st25" />
                    <g id="toit_1_">
                        <path id="facade_1_" d="M965.8 375.8v-3.1l47.4-42.3 47.3 42v3.4z" className="st25" />
                        <g id="poutres_1_">
                            <path d="M967.4 375.8h91.2v3.5h-91.2z" className="st6" />
                            <path
                                d="M959.1 352.8h63.3v5.6h-63.3z"
                                className="st6"
                                transform="rotate(-41.8 990.8 355.7)"
                            />
                            <path
                                d="M1003.5 352.7h64.2v5.2h-64.2z"
                                className="st6"
                                transform="scale(-1) rotate(41.8 929.8 -2709.9)"
                            />
                        </g>
                        <g id="tuiles">
                            <path
                                d="M1014.3 336.3h12.2v4.3h-12.2z"
                                className="st87"
                                transform="rotate(36.5 1020.3 338.4)"
                            />
                            <path
                                d="M1023.4 344.4h12.2v4.3h-12.2z"
                                className="st87"
                                transform="rotate(36.5 1029.5 346.5)"
                            />
                            <path
                                d="M1032.5 352.5h12.2v4.3h-12.2z"
                                className="st87"
                                transform="rotate(36.5 1038.6 354.6)"
                            />
                            <path
                                d="M1041.6 360.6h12.2v4.3h-12.2z"
                                className="st87"
                                transform="rotate(36.5 1047.7 362.7)"
                            />
                            <path
                                d="M1050.8 368.7h12.2v4.3h-12.2z"
                                className="st87"
                                transform="rotate(36.5 1056.8 370.8)"
                            />
                            <path d="M1017.4 332.3l-2.5 3.4-1.7-1.2v-5.4z" className="st87" />
                            <g>
                                <path
                                    d="M963.4 368.7h12.2v4.3h-12.2z"
                                    className="st87"
                                    transform="rotate(143.5 969.5 370.8)"
                                />
                                <path
                                    d="M970.1 361.4H985v4.3h-14.9z"
                                    className="st87"
                                    transform="rotate(143.5 977.5 363.5)"
                                />
                                <path
                                    d="M979.1 353.3h15v4.3h-15z"
                                    className="st87"
                                    transform="rotate(143.5 986.6 355.5)"
                                />
                                <path
                                    d="M988.2 345.3h15.1v4.3h-15.1z"
                                    className="st87"
                                    transform="rotate(143.5 995.7 347.4)"
                                />
                                <path
                                    d="M997.3 337.2h15.1v4.3h-15.1z"
                                    className="st87"
                                    transform="rotate(143.5 1004.8 339.3)"
                                />
                                <path d="M1006.8 333.8l2.6 3.5 3.8-2.8v-5.4z" className="st87" />
                            </g>
                        </g>
                    </g>
                    <g id="esclaier_droite">
                        <path
                            d="M1091.4 476.8v3.8h-36.9v-3.8h-7.6v-4.4h6.7v-1.9h-3.5v-5.7h4.2v-4.5h6.2v4.5h7.7v4.1h7.7v3.5h7.8v4.4z"
                            className="st25"
                        />
                        <path
                            d="M1053.6 460.6h7.2c.4 0 .8-.4.8-.8v-.5c0-.4-.4-.8-.8-.8h-7.2c-.4 0-.8.4-.8.8v.5c0 .5.4.8.8.8z"
                            className="st98"
                        />
                        <path
                            d="M1060.7 465.3h7.8c.4 0 .7-.3.7-.7v-.9c0-.4-.3-.7-.7-.7h-7.8c-.4 0-.7.3-.7.7v.9c-.1.4.3.7.7.7z"
                            className="st96"
                        />
                        <path
                            d="M1068.4 469.3h7.8c.4 0 .7-.3.7-.7v-.8c0-.4-.3-.7-.7-.7h-7.8c-.4 0-.7.3-.7.7v.8c0 .4.3.7.7.7z"
                            className="st95"
                        />
                        <path
                            d="M1076 473.5h8c.4 0 .8-.3.8-.8v-.8c0-.4-.3-.8-.8-.8h-8c-.4 0-.8.3-.8.8v.8c0 .4.3.8.8.8z"
                            className="st100"
                        />
                        <path
                            d="M1083.7 477h8c.4 0 .7-.3.7-.7 0-.8-.6-1.4-1.4-1.4h-7.4c-.4 0-.7.3-.7.7v.7c.1.4.5.7.8.7z"
                            className="st35"
                        />
                    </g>
                    <g id="briques">
                        <g id="briques_droites_1_">
                            <path
                                fill="#505056"
                                d="M1044.1 383.1v.9c0 1.3 1 2.3 2.3 2.3h8.8c.6 0 1-.5 1-1 0-1.1-.4-2.1-1.2-2.8a4 4 0 00-2.8-1.2h-6.4c-.9 0-1.7.8-1.7 1.8z"
                            />
                            <path
                                fill="#7d7d8c"
                                d="M1055.5 393.8h-3.9c-1 0-1.8-.8-1.8-1.8 0-1.8 1.4-3.2 3.2-3.2h1.4c1 0 1.8.8 1.8 1.8v2.4c0 .5-.3.8-.7.8z"
                            />
                            <path
                                fill="#7c7c7f"
                                d="M1054.4 401.3h-8.5c-1 0-1.8-.8-1.8-1.8V398c0-1 .8-1.7 1.7-1.7h8.9c.8 0 1.5.7 1.5 1.5v1.7c0 1-.8 1.8-1.8 1.8z"
                            />
                            <path
                                d="M1053.9 408.8h-2.1c-.9 0-1.6-.7-1.6-1.6v-1.7c0-.9.8-1.7 1.7-1.7h2.7c.9 0 1.6.7 1.6 1.6v1c0 1.3-1 2.4-2.3 2.4z"
                                className="st95"
                            />
                            <path
                                d="M1054.1 416.2h-9.1a.9.9 0 01-.8-.8v-2.3c0-1 .8-1.9 1.9-1.9h9.1c.6 0 1.1.5 1.1 1.1v1.7c-.1 1.3-1 2.2-2.2 2.2z"
                                className="st104"
                            />
                            <path
                                fill="#878787"
                                d="M1055.5 423.7h-4.2c-.6 0-1.1-.5-1.1-1.1v-1.2c0-1.5 1.2-2.7 2.7-2.7h1.7c.9 0 1.7.7 1.7 1.7v2.5c-.1.5-.4.8-.8.8z"
                            />
                            <path
                                fill="#5d5d68"
                                d="M1055 431.2h-9.4c-.8 0-1.4-.6-1.4-1.4v-1.2c0-1.3 1.1-2.4 2.4-2.4h6.6c1.7 0 3.1 1.4 3.1 3.1v.6c-.1.7-.6 1.3-1.3 1.3z"
                            />
                            <path
                                d="M1055.2 438.7h-4.1a.9.9 0 01-.9-.9v-2.2c0-1 .8-1.8 1.8-1.8h2.2a2 2 0 012 2v1.9c0 .5-.4 1-1 1z"
                                className="st100"
                            />
                            <path
                                d="M1054.8 446.2h-9.5c-.6 0-1.2-.5-1.2-1.2v-.8a3 3 0 013-3h6.4c1.5 0 2.7 1.2 2.7 2.7v.9c0 .7-.6 1.4-1.4 1.4z"
                                className="st107"
                            />
                            <path
                                d="M1054.8 454.7h-4.2a.4.4 0 01-.4-.4v-1.8c0-1.6 1.3-2.8 2.8-2.8h2.3c.5 0 .9.4.9.9v2.6c0 .8-.6 1.5-1.4 1.5z"
                                className="st104"
                            />
                            <path
                                d="M1054 463.1h-9a.9.9 0 01-.9-.9v-3.1c0-.6.5-1 1-1h10c.6 0 1.1.5 1.1 1.1v1.7c0 1.2-.9 2.2-2.2 2.2z"
                                className="st96"
                            />
                            <path
                                d="M1054.1 470.6h-2.9c-.6 0-1-.4-1-1V466c0-.2.2-.4.4-.4h4.5c.7 0 1.2.5 1.2 1.2v1.7c-.1 1.2-1 2.1-2.2 2.1z"
                                className="st95"
                            />
                            <path
                                fill="#606066"
                                d="M1044.8 408.8h-5.1c-.6 0-1.1-.5-1.1-1.1v-1c0-1.6 1.3-2.9 2.9-2.9h5.2c.6 0 1 .5 1 1v1a3 3 0 01-2.9 3z"
                            />
                            <path
                                fill="#585868"
                                d="M1046.4 438.7h-9.8a1 1 0 01-1-1V436c0-1.3 1-2.3 2.3-2.3h7.3c1.4 0 2.5 1.1 2.5 2.5v1.3c0 .6-.6 1.2-1.3 1.2z"
                            />
                            <path
                                d="M1046.7 454.7h-9.9c-.6 0-1.1-.5-1.1-1.1v-1.3c0-1.4 1.1-2.6 2.6-2.6h7.2c1.3 0 2.4 1.1 2.4 2.4v1.6c-.2.5-.6 1-1.2 1z"
                                className="st110"
                            />
                            <path
                                d="M1047.4 470.6h-9.5c-1.3 0-2.3-1-2.3-2.3V467c0-.8.6-1.4 1.4-1.4h8.6c1.2 0 2.2 1 2.2 2.2v2.5c-.1.2-.2.3-.4.3z"
                                className="st111"
                            />
                        </g>
                        <g id="briques_gauche">
                            <path
                                d="M975.7 388.8h-3.9c-1 0-1.8.8-1.8 1.8v2.3c0 .5.4.9.9.9h3.7c1 0 1.8-.8 1.8-1.8v-2.4c0-.5-.3-.8-.7-.8z"
                                className="st112"
                            />
                            <path
                                d="M980.1 396.3h-7.6a2.6 2.6 0 00-2.6 2.6v1.4c0 .6.5 1.1 1.1 1.1h9.8c.7 0 1.2-.6 1.2-1.2v-1.8c.1-1.2-.8-2.1-1.9-2.1z"
                                className="st96"
                            />
                            <path
                                d="M973.6 403.8h-2.4c-.6 0-1.2.5-1.2 1.2v1.6c0 1.2 1 2.2 2.2 2.2h2.7c.7 0 1.2-.5 1.2-1.2v-1.3c-.1-1.4-1.2-2.5-2.5-2.5z"
                                className="st113"
                            />
                            <path
                                d="M979.7 411.2H971a1 1 0 00-1 1v2c0 1.1.9 2.1 2.1 2.1h8.7c.7 0 1.3-.6 1.3-1.3v-1.3c0-1.4-1.1-2.5-2.4-2.5z"
                                className="st114"
                            />
                            <path
                                d="M975.3 418.7h-4.2c-.6 0-1.1.5-1.1 1.1v1.2c0 1.5 1.2 2.7 2.7 2.7h1.7c.9 0 1.7-.7 1.7-1.7v-2.6c-.1-.3-.4-.7-.8-.7z"
                                className="st95"
                            />
                            <path
                                d="M981 426.2h-9.8c-.7 0-1.2.6-1.2 1.2v1.6c0 1.2 1 2.2 2.2 2.2h7c1.6 0 2.9-1.3 2.9-2.9v-1c0-.6-.5-1.1-1.1-1.1z"
                                className="st115"
                            />
                            <path
                                fill="#64646d"
                                d="M974.8 433.7H971c-.6 0-1.1.5-1.1 1.1v2c0 1.1.9 2 2 2h1.9c1.2 0 2.2-1 2.2-2.2V435c0-.8-.5-1.3-1.2-1.3z"
                            />
                            <path
                                d="M980.7 441.2h-9.6c-.6 0-1.1.5-1.1 1.1v.9a3 3 0 003 3h6.5c1.5 0 2.7-1.2 2.7-2.7v-.9c-.1-.8-.7-1.4-1.5-1.4z"
                                className="st98"
                            />
                            <path
                                d="M974.9 449.7h-4c-.5 0-.9.4-.9.9v1.5c0 1.4 1.1 2.5 2.5 2.5h2.9c.3 0 .6-.3.6-.6v-3.2c0-.6-.5-1.1-1.1-1.1z"
                                className="st117"
                            />
                            <path
                                d="M972.7 463.1h8.4c.6 0 1-.5 1-1v-2.4c0-.8-.7-1.5-1.5-1.5h-9c-.9 0-1.6.7-1.6 1.6v.7c0 1.4 1.2 2.6 2.7 2.6zM972.1 470.6h2.9c.6 0 1-.4 1-1V466c0-.2-.2-.4-.4-.4h-4.5c-.7 0-1.2.5-1.2 1.2v1.7c.1 1.2 1 2.1 2.2 2.1z"
                                className="st95"
                            />
                            <path
                                d="M987.8 418.7h-8.4a1 1 0 00-1 1v1.3c0 1.5 1.2 2.7 2.7 2.7h8.5c.5 0 .9-.4.9-.9v-1.3a2.7 2.7 0 00-2.7-2.8z"
                                className="st94"
                            />
                            <path
                                d="M989.4 388.8h-9.8a1 1 0 00-1 1v1.6c0 1.3 1 2.3 2.3 2.3h7.3c1.4 0 2.5-1.1 2.5-2.5V390c0-.6-.6-1.2-1.3-1.2z"
                                className="st118"
                            />
                            <path
                                d="M989.3 449.7h-9.8a1 1 0 00-1 1v1.1c0 1.6 1.3 2.8 2.8 2.8h6.6c1.5 0 2.7-1.2 2.7-2.7v-1c0-.7-.6-1.2-1.3-1.2z"
                                className="st99"
                            />
                            <path
                                d="M979.5 470.6h8.2c1.6 0 2.9-1.3 2.9-2.9a2 2 0 00-2-2h-7.2a2.9 2.9 0 00-2.9 2.9v1.2c0 .4.5.8 1 .8z"
                                className="st95"
                            />
                            <path
                                d="M980.7 381.3h-9.5c-.7 0-1.2.5-1.2 1.2v1.3c0 1.4 1.1 2.5 2.5 2.5h7c1.5 0 2.6-1.2 2.6-2.6v-.9c0-.8-.6-1.5-1.4-1.5z"
                                className="st94"
                            />
                        </g>
                        <g id="briques_milieu_1_">
                            <path
                                fill="#696977"
                                d="M1002.5 415.9h-1.3c-.9 0-1.6-.7-1.6-1.6v-1.1c0-1.2 1-2.2 2.2-2.2h1.2c.9 0 1.5.7 1.5 1.5v1.4c.1 1.1-.8 2-2 2z"
                            />
                            <path
                                fill="#4a4a51"
                                d="M1021.9 423.4h-19c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1.1.9-1.9 1.9-1.9h18.7c.9 0 1.7.7 1.7 1.7v1.7c-.2.9-.9 1.6-1.7 1.6z"
                            />
                            <path
                                fill="#676768"
                                d="M1002.6 408.4h-5c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.6.4-1 1-1h6.1c.9 0 1.6.7 1.6 1.6v1.4a2 2 0 01-2 2z"
                            />
                            <path
                                d="M1002.6 400.9h-1.9c-.9 0-1.6-.7-1.6-1.6v-.8c0-1.4 1.2-2.6 2.6-2.6h1.3c.9 0 1.5.7 1.5 1.5v1.5c.1 1.1-.8 2-1.9 2zM1023.8 415.9h-.7c-.9 0-1.6-.7-1.6-1.6v-1.2c0-1.2 1-2.1 2.1-2.1h.8c.9 0 1.5.7 1.5 1.5v1.3c0 1.2-1 2.1-2.1 2.1z"
                                className="st95"
                            />
                            <path
                                fill="#565656"
                                d="M1026.5 408.4h-3.5c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1 .8-1.9 1.9-1.9h4.1c.9 0 1.5.7 1.5 1.5v1.1c0 1.4-1.1 2.4-2.4 2.4z"
                            />
                            <path
                                d="M1024.5 400.9h-1.9c-.6 0-1.2-.5-1.2-1.2V398a2 2 0 012.1-2.1h1.8c.6 0 1.1.5 1.1 1.1v2c0 1.1-.9 1.9-1.9 1.9z"
                                className="st123"
                            />
                            <path
                                d="M1021.9 393.5h-19.5c-.8 0-1.4-.6-1.4-1.4v-1.9c0-.9.7-1.6 1.6-1.6h18.3c1.3 0 2.3 1 2.3 2.3v1.4c0 .6-.6 1.2-1.3 1.2z"
                                className="st124"
                            />
                        </g>
                    </g>
                    <g id="fenetre_1_">
                        <path d="M1005.8 394.7h14.3v22.4h-14.3z" className="st22" />
                        <path d="M1019.6 395.2v21.4h-13.3v-21.4h13.3m1-1h-15.3v23.4h15.3v-23.4z" className="st6" />
                        <path d="M1013 417.6v-23.4M1020.6 408.6h-15.3" className="st125" />
                        <path d="M1020.6 400.2h-15.3" className="st126" />
                    </g>
                    <g id="briques_milieu_2_">
                        <path
                            fill="#525254"
                            d="M1023.5 367.7h6.2a2 2 0 012 2 3 3 0 01-3 3h-5.1a2.4 2.4 0 01-2.4-2.4v-.2c-.1-1.3 1-2.4 2.3-2.4z"
                        />
                        <path
                            fill="#717172"
                            d="M1031 365.4h-4.1a2.9 2.9 0 01-2.9-2.9c0-1.1.9-2.1 2.1-2.1h3.1c1.9 0 3.4 1.5 3.4 3.4 0 .9-.7 1.6-1.6 1.6z"
                        />
                        <path
                            fill="#5c5c63"
                            d="M1021.3 354.3c0-.7.6-1.3 1.3-1.3h2c1.6 0 2.9 1.3 2.9 2.9v.2c0 .7-.6 1.3-1.3 1.3h-1.9c-1.6 0-3-1.4-3-3.1z"
                        />
                        <path
                            fill="#606068"
                            d="M1000.9 352.7h2.7a2 2 0 012.1 2.1c0 1.6-1.3 2.9-2.9 2.9h-1.8a2.5 2.5 0 01-2.5-2.5c-.1-1.4 1-2.5 2.4-2.5z"
                        />
                        <path
                            d="M1001.4 367.7h1.5c1.6 0 2.9 1.3 2.9 2.9 0 1.2-1 2.1-2.1 2.1h-3.3c-1.1 0-1.9-.9-1.9-1.9-.2-1.7 1.2-3.1 2.9-3.1z"
                            className="st97"
                        />
                        <path
                            fill="#717182"
                            d="M996.3 360.5h4.4c.9 0 1.6.7 1.6 1.6v.2c0 1.8-1.4 3.2-3.2 3.2h-4.5c-.9 0-1.6-.7-1.6-1.6 0-1.9 1.5-3.4 3.3-3.4z"
                        />
                    </g>
                    <circle
                        id="fenetre_2_"
                        cx="1013.5"
                        cy="362.8"
                        r="6.9"
                        fill="#acd4ea"
                        stroke="#6b6a6b"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <g id="fenetre_3_">
                        <path fill="#8c6239" d="M950.2 430.7h14.3V458h-14.3z" />
                        <path d="M964 431.2v26.3h-13.3v-26.3H964m1-1h-15.3v28.3H965v-28.3z" className="st69" />
                        <g>
                            <circle cx="952.4" cy="443.2" r="1.1" fill="#f9b548" />
                        </g>
                    </g>
                </g>
                <g id="maison_droite">
                    <g id="esclaier_gauche">
                        <path d="M1285 495v3.8h36.8l.2-20.2h-6.1v4.4h-7.7v4.1h-7.8v3.5h-7.7v4.4z" className="st25" />
                        <path
                            d="M1322.7 479.3h-7.1a.8.8 0 01-.8-.8v-.9c0-.5.4-.8.8-.8h7.1c.5 0 .8.4.8.8v.9c.1.4-.3.8-.8.8z"
                            className="st135"
                        />
                        <path
                            d="M1315.7 483.7h-7.8a.7.7 0 01-.7-.7v-1.1c0-.4.3-.7.7-.7h7.8c.4 0 .7.3.7.7v1.1c.1.4-.3.7-.7.7z"
                            className="st136"
                        />
                        <path
                            d="M1307.8 487.7h-7.4a1 1 0 01-1-1v-.5c0-.5.4-1 1-1h7.4c.5 0 1 .4 1 1v.5c-.1.6-.5 1-1 1z"
                            className="st137"
                        />
                        <path
                            d="M1300.4 491.9h-8.2c-.3 0-.6-.2-.6-.6v-1.4c0-.3.2-.6.6-.6h8.2c.3 0 .6.2.6.6v1.4c0 .3-.3.6-.6.6z"
                            className="st138"
                        />
                        <path
                            d="M1292.5 495.7h-7.9a.8.8 0 01-.8-.8v-1c0-.4.3-.8.8-.8h7.9c.4 0 .8.3.8.8v1c0 .4-.3.8-.8.8z"
                            className="st139"
                        />
                    </g>
                    <g id="droites">
                        <path id="facade_4_" d="M1406.4 429h70.3v80.7h-70.3z" className="st25" />
                        <g id="toit_4_">
                            <path id="poutre" fill="#a57665" d="M1389.3 428.1h91.2v3.5h-91.2z" />
                            <path id="toit_3_" d="M1398 391.5h81.6V428H1398z" className="st87" />
                            <g id="tuiles_2_">
                                <path
                                    d="M1419.4 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1425.5 391.2)"
                                />
                                <path
                                    d="M1431.6 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1437.7 391.2)"
                                />
                                <path
                                    d="M1443.8 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1449.8 391.2)"
                                />
                                <path
                                    d="M1455.9 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1462 391.2)"
                                />
                                <path
                                    d="M1468.1 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1474.2 391.2)"
                                />
                                <path
                                    d="M1395 389h12.2v4.3H1395z"
                                    className="st141"
                                    transform="rotate(-5 1401 391.2)"
                                />
                                <path
                                    d="M1407.2 389h12.2v4.3h-12.2z"
                                    className="st141"
                                    transform="rotate(-5 1413.3 391.2)"
                                />
                            </g>
                        </g>
                        <g id="briques_droites_3_">
                            <path
                                fill="#9b9ba5"
                                d="M1466.4 434.8v.9c0 1.3 1 2.3 2.3 2.3h8.8c.6 0 1-.5 1-1 0-1.1-.4-2.1-1.2-2.8a4 4 0 00-2.8-1.2h-6.4c-.9 0-1.7.8-1.7 1.8z"
                            />
                            <path
                                fill="#4d4d54"
                                d="M1477.8 445.5h-3.9c-1 0-1.8-.8-1.8-1.8 0-1.8 1.4-3.2 3.2-3.2h1.4c1 0 1.8.8 1.8 1.8v2.4c0 .4-.3.8-.7.8z"
                            />
                            <path
                                d="M1476.7 453h-8.5c-1 0-1.8-.8-1.8-1.8v-1.4c0-1 .8-1.7 1.7-1.7h8.9c.8 0 1.5.7 1.5 1.5v1.7c0 .8-.8 1.7-1.8 1.7z"
                                className="st144"
                            />
                            <path
                                fill="#757577"
                                d="M1476.2 460.4h-2.1c-.9 0-1.6-.7-1.6-1.6v-1.7c0-.9.8-1.7 1.7-1.7h2.7c.9 0 1.6.7 1.6 1.6v1c0 1.4-1 2.4-2.3 2.4z"
                            />
                            <path
                                fill="#81818c"
                                d="M1476.4 467.9h-9.1a.9.9 0 01-.8-.8v-2.3c0-1 .8-1.9 1.9-1.9h9.1c.6 0 1.1.5 1.1 1.1v1.7c-.1 1.2-1 2.2-2.2 2.2z"
                            />
                            <path
                                fill="#525256"
                                d="M1477.8 475.4h-4.2c-.6 0-1.1-.5-1.1-1.1v-1.2c0-1.5 1.2-2.7 2.7-2.7h1.7c.9 0 1.7.7 1.7 1.7v2.5c-.1.5-.4.8-.8.8z"
                            />
                            <path
                                fill="#9d9d9e"
                                d="M1477.3 482.9h-9.4c-.8 0-1.4-.6-1.4-1.4v-1.2c0-1.3 1.1-2.4 2.4-2.4h6.6c1.7 0 3.1 1.4 3.1 3.1v.6c-.1.7-.6 1.3-1.3 1.3z"
                            />
                            <path
                                fill="#a1a1af"
                                d="M1477.5 490.4h-4.1a.9.9 0 01-.9-.9v-2.2c0-1 .8-1.8 1.8-1.8h2.2a2 2 0 012 2v1.9c0 .5-.4 1-1 1z"
                            />
                            <path
                                fill="#6e6e7a"
                                d="M1477.1 497.9h-9.5c-.6 0-1.2-.5-1.2-1.2v-.8a3 3 0 013-3h6.4c1.5 0 2.7 1.2 2.7 2.7v.9c0 .7-.6 1.4-1.4 1.4z"
                            />
                            <path
                                d="M1467.1 460.4h-5.1c-.6 0-1.1-.5-1.1-1.1v-1c0-1.6 1.3-2.9 2.8-2.9h5.2c.6 0 1 .5 1 1v1c.1 1.7-1.2 3-2.8 3z"
                                className="st115"
                            />
                            <path
                                fill="#666670"
                                d="M1468.7 490.4h-9.8a1 1 0 01-1-1v-1.6c0-1.3 1-2.3 2.3-2.3h7.3c1.4 0 2.5 1.1 2.5 2.5v1.3c0 .5-.6 1.1-1.3 1.1z"
                            />
                        </g>
                        <g id="fenetre_7_">
                            <g id="fenetre_4_">
                                <path d="M1432.5 441.5h13.9v21.7h-13.9z" className="st22" />
                                <path d="M1445.9 442v20.7H1433V442h12.9m1-1H1432v22.7h14.9V441z" className="st6" />
                                <path d="M1439.4 463.7V441M1446.9 455H1432" className="st125" />
                                <path d="M1446.9 446.8H1432" className="st126" />
                            </g>
                            <g id="briques_milieu_5_">
                                <path
                                    d="M1428.9 462.4h-1.3c-.9 0-1.6-.7-1.6-1.6v-1.1c0-1.2 1-2.2 2.2-2.2h1.2c.9 0 1.5.7 1.5 1.5v1.4c.1 1-.8 2-2 2z"
                                    className="st152"
                                />
                                <path
                                    fill="#85858e"
                                    d="M1448.3 469.8h-19c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1.1.9-1.9 1.9-1.9h18.7c.9 0 1.7.7 1.7 1.7v1.7c-.1.9-.8 1.6-1.7 1.6z"
                                />
                                <path
                                    fill="#5e5e68"
                                    d="M1429 454.9h-5c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.6.4-1 1-1h6.1c.9 0 1.6.7 1.6 1.6v1.4a2 2 0 01-2 2z"
                                />
                                <path
                                    fill="#8f8f96"
                                    d="M1429 447.4h-1.9c-.9 0-1.6-.7-1.6-1.6v-.8c0-1.4 1.2-2.6 2.6-2.6h1.3c.9 0 1.5.7 1.5 1.5v1.5c.1 1.1-.8 2-1.9 2z"
                                />
                                <path
                                    fill="#a5a5a8"
                                    d="M1450.2 462.4h-.7c-.9 0-1.6-.7-1.6-1.6v-1.2c0-1.2 1-2.1 2.1-2.1h.8c.9 0 1.5.7 1.5 1.5v1.3c0 1.1-.9 2.1-2.1 2.1z"
                                />
                                <path
                                    d="M1452.9 454.9h-3.5c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1 .8-1.9 1.9-1.9h4.1c.9 0 1.5.7 1.5 1.5v1.1c0 1.3-1 2.4-2.4 2.4z"
                                    className="st115"
                                />
                                <path
                                    d="M1450.9 447.4h-1.9c-.6 0-1.2-.5-1.2-1.2v-1.7a2 2 0 012.1-2.1h1.8c.6 0 1.1.5 1.1 1.1v2c0 1-.8 1.9-1.9 1.9z"
                                    className="st118"
                                />
                                <path
                                    fill="#6e6e77"
                                    d="M1448.3 439.9h-19.5c-.8 0-1.4-.6-1.4-1.4v-1.9c0-.9.7-1.6 1.6-1.6h18.3c1.3 0 2.3 1 2.3 2.3v1.4c0 .6-.6 1.2-1.3 1.2z"
                                />
                            </g>
                        </g>
                    </g>
                    <g id="gauche">
                        <g id="cheminée_1_">
                            <g id="fumee_1_">
                                <circle id="fumee1_1_" cx="1407.9" cy="374" r="16.2" className="st158" />
                                <circle id="fumee6_1_" cx="1394.9" cy="374.9" r="14.7" className="st159" />
                                <circle id="fumee2_1_" cx="1397.1" cy="374.9" r="13.3" className="st160" />
                                <circle id="fumee3_1_" cx="1401.4" cy="374" r="12.4" className="st161" />
                                <circle id="fumee5_1_" cx="1399.9" cy="373.3" r="11.1" className="st162" />
                                <circle id="fumee4_1_" cx="1401.1" cy="373.4" r="9.6" className="st163" />
                                <circle id="fumee7_1_" cx="1401.4" cy="374" r="8" className="st124" />
                                <circle id="fumee8_1_" cx="1394.4" cy="374" r="16.2" className="st158" />
                                <circle id="fumee13_1_" cx="1403.2" cy="374.5" r="14.7" className="st159" />
                                <circle id="fumee9_1_" cx="1405.5" cy="374" r="13.3" className="st160" />
                                <circle id="fumee10_1_" cx="1397.4" cy="374" r="12.4" className="st161" />
                                <circle id="fumee12_1_" cx="1407.1" cy="374" r="11.1" className="st162" />
                                <circle id="fumee11_1_" cx="1400.6" cy="374" r="9.6" className="st163" />
                                <circle id="fumee14_1_" cx="1393.9" cy="374" r="8" className="st124" />
                            </g>
                            <path d="M1391.9 371.4h17.9v5h-17.9z" className="st87" />
                            <path d="M1393 376.5h15.9v22.3H1393z" className="st25" />
                            <path
                                d="M1399 379.4v.9c0 1.3.9 2.3 2.1 2.3h7.8c.5 0 .9-.5.9-1 0-1.1-.4-2.1-1-2.8a3.3 3.3 0 00-2.5-1.2H1400.6c-.9.1-1.6.9-1.6 1.8z"
                                className="st88"
                            />
                            <path
                                d="M1409.1 390.1h-2.8c-1 0-1.8-.8-1.8-1.8 0-1.8 1.4-3.2 3.2-3.2h.3c1 0 1.8.8 1.8 1.8v2.4c0 .5-.3.8-.7.8z"
                                className="st89"
                            />
                            <path
                                d="M1408 397.6h-6.9c-1 0-1.8-.8-1.8-1.8v-1.4c0-1 .8-1.7 1.7-1.7h7.4c.8 0 1.5.7 1.5 1.5v1.7a2 2 0 01-1.9 1.7z"
                                className="st164"
                            />
                            <path
                                d="M1395.9 382.7h-2.8c-.8 0-1.5-.7-1.5-1.5v-2c0-.8.7-1.5 1.5-1.5h1.9a2 2 0 012 2v1.9c.1.6-.4 1.1-1.1 1.1z"
                                className="st91"
                            />
                            <path
                                fill="#a2a2a5"
                                d="M1400.7 390.1h-6a3 3 0 01-3-3c0-1.1.9-1.9 1.9-1.9h7.9c1 0 1.7.8 1.7 1.7v.8c0 1.3-1.1 2.4-2.5 2.4z"
                            />
                            <path
                                fill="#9090a3"
                                d="M1396.1 397.6h-1.6a2.4 2.4 0 01-2.4-2.4v-.6c0-1.1.9-2 2-2h.4c1.8 0 3.3 1.5 3.3 3.3-.1 1-.8 1.7-1.7 1.7z"
                            />
                        </g>
                        <path id="facade_3_" d="M1320.8 414.9h92.4v94.9h-92.4z" className="st25" />
                        <g id="briques_1_">
                            <g id="briques_droites_2_">
                                <path
                                    d="M1403.2 418.7v.9c0 1.3 1 2.3 2.3 2.3h8.8c.6 0 1-.5 1-1 0-1.1-.4-2.1-1.2-2.8a4 4 0 00-2.8-1.2h-6.4c-.9 0-1.7.8-1.7 1.8z"
                                    className="st88"
                                />
                                <path
                                    d="M1414.6 429.4h-3.9c-1 0-1.8-.8-1.8-1.8 0-1.8 1.4-3.2 3.2-3.2h1.4c1 0 1.8.8 1.8 1.8v2.4c0 .4-.3.8-.7.8z"
                                    className="st89"
                                />
                                <path
                                    d="M1413.5 436.9h-8.5c-1 0-1.8-.8-1.8-1.8v-1.4c0-1 .8-1.7 1.7-1.7h8.9c.8 0 1.5.7 1.5 1.5v1.7c0 .8-.8 1.7-1.8 1.7z"
                                    className="st164"
                                />
                                <path
                                    fill="#74747f"
                                    d="M1413 444.3h-2.1c-.9 0-1.6-.7-1.6-1.6V441c0-.9.8-1.7 1.7-1.7h2.7c.9 0 1.6.7 1.6 1.6v1c0 1.4-1 2.4-2.3 2.4z"
                                />
                                <path
                                    d="M1413.1 451.8h-9.1a.9.9 0 01-.8-.8v-2.3c0-1 .8-1.9 1.9-1.9h9.1c.6 0 1.1.5 1.1 1.1v1.7c0 1.2-.9 2.2-2.2 2.2z"
                                    className="st168"
                                />
                                <path
                                    fill="#545456"
                                    d="M1414.6 459.3h-4.2c-.6 0-1.1-.5-1.1-1.1V457c0-1.5 1.2-2.7 2.7-2.7h1.7c.9 0 1.7.7 1.7 1.7v2.5c-.1.5-.4.8-.8.8z"
                                />
                                <path
                                    fill="#7f7f87"
                                    d="M1414.1 466.8h-9.4c-.8 0-1.4-.6-1.4-1.4v-1.2c0-1.3 1.1-2.4 2.4-2.4h6.6c1.7 0 3.1 1.4 3.1 3.1v.6c-.1.7-.6 1.3-1.3 1.3z"
                                />
                                <path
                                    d="M1414.3 474.3h-4.1a.9.9 0 01-.9-.9v-2.2c0-1 .8-1.8 1.8-1.8h2.2a2 2 0 012 2v1.9c0 .5-.4 1-1 1z"
                                    className="st161"
                                />
                                <path
                                    d="M1413.9 481.8h-9.5c-.6 0-1.2-.5-1.2-1.2v-.8a3 3 0 013-3h6.4c1.5 0 2.7 1.2 2.7 2.7v.9c0 .7-.6 1.4-1.4 1.4z"
                                    className="st152"
                                />
                                <path
                                    d="M1413.9 490.2h-4.2a.4.4 0 01-.4-.4V488c0-1.6 1.3-2.8 2.8-2.8h2.3c.5 0 .9.4.9.9v2.6c0 .9-.6 1.5-1.4 1.5z"
                                    className="st171"
                                />
                                <path
                                    d="M1413.1 498.7h-9a.9.9 0 01-.9-.9v-3.1c0-.6.5-1 1-1h10c.6 0 1.1.5 1.1 1.1v1.7c0 1.2-1 2.2-2.2 2.2z"
                                    className="st117"
                                />
                                <path
                                    fill="#545459"
                                    d="M1403.9 444.3h-5.1c-.6 0-1.1-.5-1.1-1.1v-1c0-1.6 1.3-2.9 2.9-2.9h5.2c.6 0 1 .5 1 1v1a3 3 0 01-2.9 3z"
                                />
                                <path
                                    d="M1405.5 474.3h-9.8a1 1 0 01-1-1v-1.6c0-1.3 1-2.3 2.3-2.3h7.3c1.4 0 2.5 1.1 2.5 2.5v1.3c0 .5-.6 1.1-1.3 1.1z"
                                    className="st117"
                                />
                                <path
                                    d="M1405.8 490.2h-9.9c-.6 0-1.1-.5-1.1-1.1v-1.3c0-1.4 1.1-2.6 2.6-2.6h7.2c1.3 0 2.4 1.1 2.4 2.4v1.6c-.2.6-.6 1-1.2 1z"
                                    className="st144"
                                />
                            </g>
                            <g id="briques_gauche_1_">
                                <path
                                    d="M1324.8 424.4h-3.9c-1 0-1.8.8-1.8 1.8v2.3c0 .5.4.9.9.9h3.7c1 0 1.8-.8 1.8-1.8v-2.4c.1-.5-.2-.8-.7-.8z"
                                    className="st173"
                                />
                                <path
                                    d="M1329.3 431.9h-7.6a2.6 2.6 0 00-2.6 2.6v1.4c0 .6.5 1.1 1.1 1.1h9.8c.7 0 1.2-.6 1.2-1.2V434c.1-1.2-.8-2.1-1.9-2.1z"
                                    className="st115"
                                />
                                <path
                                    fill="#7a7a7c"
                                    d="M1322.7 439.3h-2.4c-.6 0-1.2.5-1.2 1.2v1.6c0 1.2 1 2.2 2.2 2.2h2.7c.7 0 1.2-.5 1.2-1.2v-1.3c0-1.3-1.1-2.5-2.5-2.5z"
                                />
                                <path
                                    fill="#5f5f60"
                                    d="M1328.9 446.8h-8.8a1 1 0 00-1 1v2c0 1.1.9 2.1 2.1 2.1h8.7c.7 0 1.3-.6 1.3-1.3v-1.3c.1-1.4-1-2.5-2.3-2.5z"
                                />
                                <path
                                    fill="#757582"
                                    d="M1324.4 454.3h-4.2c-.6 0-1.1.5-1.1 1.1v1.2c0 1.5 1.2 2.7 2.7 2.7h1.7c.9 0 1.7-.7 1.7-1.7V455c0-.3-.3-.7-.8-.7z"
                                />
                                <path
                                    d="M1330.2 461.8h-9.8c-.7 0-1.2.6-1.2 1.2v1.6c0 1.2 1 2.2 2.2 2.2h7c1.6 0 2.9-1.3 2.9-2.9v-1c0-.6-.5-1.1-1.1-1.1z"
                                    className="st177"
                                />
                                <path
                                    d="M1324 469.3h-3.8c-.6 0-1.1.5-1.1 1.1v2c0 1.1.9 2 2 2h1.9c1.2 0 2.2-1 2.2-2.2v-1.6c0-.8-.5-1.3-1.2-1.3z"
                                    className="st168"
                                />
                                <path
                                    d="M1329.9 476.8h-9.6c-.6 0-1.1.5-1.1 1.1v.9a3 3 0 003 3h6.5c1.5 0 2.7-1.2 2.7-2.7v-.9c-.1-.8-.7-1.4-1.5-1.4z"
                                    className="st136"
                                />
                                <path
                                    d="M1324 485.2h-4c-.5 0-.9.4-.9.9v1.5c0 1.4 1.1 2.5 2.5 2.5h2.9c.3 0 .6-.3.6-.6v-3.2c.1-.5-.4-1.1-1.1-1.1z"
                                    className="st137"
                                />
                                <path
                                    fill="#797982"
                                    d="M1321.9 498.7h8.4c.6 0 1-.5 1-1v-2.4c0-.8-.7-1.5-1.5-1.5h-9c-.9 0-1.6.7-1.6 1.6v.7c0 1.4 1.2 2.6 2.7 2.6z"
                                />
                                <path
                                    d="M1337 454.3h-8.4a1 1 0 00-1 1v1.3c0 1.5 1.2 2.7 2.7 2.7h8.5c.5 0 .9-.4.9-.9v-1.3a2.7 2.7 0 00-2.7-2.8z"
                                    className="st135"
                                />
                                <path
                                    fill="#828282"
                                    d="M1338.6 424.4h-9.8a1 1 0 00-1 1v1.6c0 1.3 1 2.3 2.3 2.3h7.3c1.4 0 2.5-1.1 2.5-2.5v-1.2c-.1-.7-.6-1.2-1.3-1.2z"
                                />
                                <path
                                    d="M1338.5 485.2h-9.8a1 1 0 00-1 1v1.1c0 1.6 1.3 2.8 2.8 2.8h6.6c1.5 0 2.7-1.2 2.7-2.7v-1c0-.6-.6-1.2-1.3-1.2z"
                                    className="st138"
                                />
                                <path
                                    d="M1329.8 416.9h-9.5c-.7 0-1.2.5-1.2 1.2v1.3c0 1.4 1.1 2.5 2.5 2.5h7c1.5 0 2.6-1.2 2.6-2.6v-.9c.1-.9-.6-1.5-1.4-1.5z"
                                    className="st112"
                                />
                            </g>
                        </g>
                        <g id="fenetre_8_">
                            <g id="briques_milieu_4_">
                                <path
                                    d="M1356.7 446.8h-1.3c-.9 0-1.6-.7-1.6-1.6v-1.1c0-1.2 1-2.2 2.2-2.2h1.2c.9 0 1.5.7 1.5 1.5v1.4c.1 1.1-.8 2-2 2z"
                                    className="st114"
                                />
                                <path
                                    d="M1376.1 454.3h-19c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1.1.9-1.9 1.9-1.9h18.7c.9 0 1.7.7 1.7 1.7v1.7c-.2.9-.9 1.6-1.7 1.6z"
                                    className="st139"
                                />
                                <path
                                    fill="#5b5b60"
                                    d="M1356.8 439.3h-5c-.9 0-1.7-.8-1.7-1.7v-2.3c0-.6.4-1 1-1h6.1c.9 0 1.6.7 1.6 1.6v1.4a2 2 0 01-2 2z"
                                />
                                <path
                                    d="M1356.8 431.8h-1.9c-.9 0-1.6-.7-1.6-1.6v-.8c0-1.4 1.2-2.6 2.6-2.6h1.3c.9 0 1.5.7 1.5 1.5v1.5c.1 1.2-.8 2-1.9 2z"
                                    className="st91"
                                />
                                <path
                                    d="M1378 446.8h-.7c-.9 0-1.6-.7-1.6-1.6V444c0-1.2 1-2.1 2.1-2.1h.8c.9 0 1.5.7 1.5 1.5v1.3c0 1.2-1 2.1-2.1 2.1z"
                                    className="st136"
                                />
                                <path
                                    fill="#87878e"
                                    d="M1380.7 439.3h-3.5c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1 .8-1.9 1.9-1.9h4.1c.9 0 1.5.7 1.5 1.5v1.1c0 1.4-1 2.4-2.4 2.4z"
                                />
                                <path
                                    fill="#75757f"
                                    d="M1378.7 431.9h-1.9c-.6 0-1.2-.5-1.2-1.2V429a2 2 0 012.1-2.1h1.8c.6 0 1.1.5 1.1 1.1v2a2 2 0 01-1.9 1.9z"
                                />
                                <path
                                    d="M1376.1 424.4h-19.5c-.8 0-1.4-.6-1.4-1.4v-2c0-.9.7-1.6 1.6-1.6h18.3c1.3 0 2.3 1 2.3 2.3v1.4c0 .7-.6 1.3-1.3 1.3z"
                                    className="st112"
                                />
                            </g>
                            <g id="fenetre_6_">
                                <path d="M1359.9 425.6h14.3V448h-14.3z" className="st22" />
                                <path
                                    d="M1373.8 426.1v21.4h-13.3v-21.4h13.3m1-1h-15.3v23.4h15.3v-23.4z"
                                    className="st6"
                                />
                                <path d="M1367.1 448.6v-23.5M1374.8 439.5h-15.4" className="st125" />
                                <path d="M1374.8 431.2h-15.4" className="st126" />
                            </g>
                        </g>
                        <g id="toit_2_">
                            <path id="facade_2_" d="M1324.5 411.4v-3.1l47.4-42.3 47.4 42v3.4z" className="st25" />
                            <g id="poutres_2_">
                                <path d="M1317 411.4h100.3v3.5H1317z" className="st6" />
                                <path
                                    d="M1307.1 385.8h69.3v6h-69.3z"
                                    className="st6"
                                    transform="rotate(-41.8 1341.8 388.8)"
                                />
                                <path
                                    d="M1357.2 386.2h70v5.5h-70z"
                                    className="st6"
                                    transform="scale(-1) rotate(41.8 1017.8 -3643)"
                                />
                            </g>
                            <g id="tuiles_1_">
                                <path
                                    d="M1368.3 368.1h13.4v4.7h-13.4z"
                                    className="st87"
                                    transform="rotate(36.5 1375 370.3)"
                                />
                                <path
                                    d="M1378.3 376.9h13.4v4.7h-13.4z"
                                    className="st87"
                                    transform="rotate(36.5 1385 379.2)"
                                />
                                <path
                                    d="M1388.3 385.8h13.4v4.7h-13.4z"
                                    className="st87"
                                    transform="rotate(36.5 1395 388)"
                                />
                                <path
                                    d="M1398.3 394.7h13.4v4.7h-13.4z"
                                    className="st87"
                                    transform="rotate(36.5 1405 397)"
                                />
                                <path
                                    d="M1408.3 403.5h13.4v4.7h-13.4z"
                                    className="st87"
                                    transform="rotate(36.5 1415 405.8)"
                                />
                                <path d="M1371.8 363.6l-2.9 3.8-1.8-1.4v-5.8z" className="st141" />
                                <g>
                                    <path
                                        d="M1352.5 368.1h13.4v4.7h-13.4z"
                                        className="st87"
                                        transform="rotate(143.5 1359.2 370.4)"
                                    />
                                    <path
                                        d="M1342.5 376.9h13.4v4.7h-13.4z"
                                        className="st87"
                                        transform="rotate(143.5 1349.2 379.3)"
                                    />
                                    <path
                                        d="M1332.5 385.8h13.4v4.7h-13.4z"
                                        className="st87"
                                        transform="rotate(143.5 1339.2 388.2)"
                                    />
                                    <path
                                        d="M1322.5 394.7h13.4v4.7h-13.4z"
                                        className="st87"
                                        transform="rotate(143.5 1329.2 397)"
                                    />
                                    <path
                                        d="M1312.5 403.5h13.4v4.7h-13.4z"
                                        className="st87"
                                        transform="rotate(143.5 1319.2 405.9)"
                                    />
                                    <path d="M1362.4 363.6l2.8 3.8 1.9-1.4v-5.8z" className="st87" />
                                </g>
                            </g>
                        </g>
                        <g id="fenetre_9_">
                            <g id="briques_milieu_3_">
                                <path
                                    d="M1377.8 403.8h6.2a2 2 0 012 2 3 3 0 01-3 3h-5.1a2.4 2.4 0 01-2.4-2.4v-.2c-.1-1.3 1-2.4 2.3-2.4z"
                                    className="st111"
                                />
                                <path
                                    d="M1385.3 401.5h-4.1a2.9 2.9 0 01-2.9-2.9c0-1.1.9-2.1 2.1-2.1h3.1c1.9 0 3.4 1.5 3.4 3.4 0 .9-.7 1.6-1.6 1.6z"
                                    className="st115"
                                />
                                <path
                                    fill="#79798c"
                                    d="M1375.6 390.4c0-.7.6-1.3 1.3-1.3h2c1.6 0 2.9 1.3 2.9 2.9v.2c0 .7-.6 1.3-1.3 1.3h-1.9a3 3 0 01-3-3.1z"
                                />
                                <path
                                    fill="#57575e"
                                    d="M1355.2 388.8h2.7a2 2 0 012.1 2.1c0 1.6-1.3 2.9-2.9 2.9h-1.8a2.5 2.5 0 01-2.5-2.5c-.1-1.4 1-2.5 2.4-2.5z"
                                />
                                <path
                                    fill="#848499"
                                    d="M1355.6 403.8h1.5c1.6 0 2.9 1.3 2.9 2.9 0 1.2-1 2.1-2.1 2.1h-3.3c-1.1 0-1.9-.9-1.9-1.9a2.9 2.9 0 012.9-3.1z"
                                />
                                <path
                                    fill="#8b8b8c"
                                    d="M1351.4 396.6h4.4c.9 0 1.6.7 1.6 1.6v.2c0 1.8-1.4 3.2-3.2 3.2h-4.5c-.9 0-1.6-.7-1.6-1.6a3.2 3.2 0 013.3-3.4z"
                                />
                            </g>
                            <circle
                                id="fenetre_5_"
                                cx="1367.8"
                                cy="398.9"
                                r="6.9"
                                fill="#acd4ea"
                                stroke="gray"
                                strokeMiterlimit="10"
                                strokeWidth="3"
                            />
                        </g>
                        <g id="fenetre_10_">
                            <g id="briques_milieu_6_">
                                <path
                                    d="M1356.7 490.5h-1.6c-.8 0-1.4-.6-1.4-1.4v-2c0-.9.7-1.5 1.5-1.5h2.3c.6 0 1.2.5 1.2 1.2v1.8c.1 1-.8 1.9-2 1.9z"
                                    className="st168"
                                />
                                <path
                                    d="M1376.3 498H1357c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1.1.9-1.9 1.9-1.9h18.3c1.1 0 2.1.9 2.1 2.1v1.6c-.1.7-.7 1.3-1.4 1.3z"
                                    className="st115"
                                />
                                <path
                                    d="M1357.3 483h-5.8c-.7 0-1.3-.6-1.3-1.3v-2.1c0-.9.7-1.5 1.5-1.5h5a2 2 0 012.1 2.1v1.4c0 .8-.7 1.4-1.5 1.4z"
                                    className="st137"
                                />
                                <path
                                    d="M1356.8 475.5h-1.6c-1.1 0-1.9-.9-1.9-1.9v-1.7c0-.8.6-1.4 1.4-1.4h2.5c.9 0 1.6.7 1.6 1.6v1.5c0 1-.9 1.9-2 1.9z"
                                    className="st177"
                                />
                                <path
                                    d="M1378.7 490.5h-1.2c-1 0-1.9-.8-1.9-1.9V487c0-.8.7-1.5 1.5-1.5h1.6c.8 0 1.4.6 1.4 1.4v2.2c0 .8-.6 1.4-1.4 1.4z"
                                    className="st136"
                                />
                                <path
                                    d="M1380.7 483h-3.5c-.9 0-1.6-.7-1.6-1.6v-1.5c0-1 .8-1.9 1.9-1.9h4.1c.9 0 1.5.7 1.5 1.5v1.1c0 1.4-1 2.4-2.4 2.4z"
                                    className="st137"
                                />
                                <path
                                    d="M1378.7 475.5h-1.9c-.6 0-1.2-.5-1.2-1.2v-1.7a2 2 0 012.1-2.1h1.8c.6 0 1.1.5 1.1 1.1v2c0 1.1-.9 1.9-1.9 1.9z"
                                    className="st177"
                                />
                                <path
                                    d="M1376.2 468.1h-19.4c-.9 0-1.7-.7-1.7-1.7v-2.1c0-.7.6-1.3 1.3-1.3h19.1c1.1 0 1.9.9 1.9 1.9v1.9c0 .7-.6 1.3-1.2 1.3z"
                                    className="st112"
                                />
                            </g>
                            <g id="fenetre_11_">
                                <path d="M1359.9 469.3h14.3v22.4h-14.3z" className="st22" />
                                <path
                                    d="M1373.8 469.8v21.4h-13.3v-21.4h13.3m1-1h-15.3v23.4h15.3v-23.4z"
                                    className="st6"
                                />
                                <path d="M1367.1 492.2v-23.4M1374.8 483.2h-15.4" className="st125" />
                                <path d="M1374.8 474.8h-15.4" className="st126" />
                            </g>
                        </g>
                    </g>
                    <g id="v">
                        <g id="branches_11_">
                            <path
                                d="M1410.3 504.2c-1.9-3.2-3-6.4-3.8-9.7a57.3 57.3 0 012.8-34.5l.9-2.3.4-1 .3-1a39.3 39.3 0 00-3.2-26.4 18.3 18.3 0 00-5.6-6.7l-1.9-1.1-2.1-1a22 22 0 00-13.3-1.5c-3.1.6-6.4 1.2-9.6 1.3-3.3.1-6.5-.2-9.7-.6l-9.5-1.4c-3.2-.5-6.3-1-9.5-1.3-1.6-.1-3.2-.2-4.8-.1-1.6.1-3.2.3-4.6 1.1 1.4-.9 3-1.1 4.6-1.3 1.6-.2 3.2-.1 4.8-.1l9.6.9c3.2.4 6.3.8 9.5 1 3.2.3 6.3.4 9.5.1 3.1-.2 6.2-1 9.3-1.7a21.9 21.9 0 0114.8.9l2.3.9 2.3 1.2c3 1.9 5.2 4.7 6.9 7.6a43.3 43.3 0 014.6 29.4l-.4 1.3-.4 1.2-.8 2.2c-.5 1.5-1 2.9-1.3 4.4a56 56 0 00-.4 27.1c.8 2.9 1.9 5.7 3.4 8l-5.1 3.1z"
                                className="st74"
                            />
                            <path
                                d="M1409.6 461.6c-.7-3.5-1.9-7.2-3.5-10.5a20 20 0 00-6.7-8.4 28.8 28.8 0 00-10.4-3.8c-1.9-.3-3.8-.6-5.6-1.4-1.8-.8-3.3-1.9-4.8-3.2 1.5 1.1 3.2 2.1 4.9 2.8 1.8.7 3.6.8 5.5 1a30 30 0 0111.2 3.2c1.8 1 3.3 2.4 4.6 3.9a48.5 48.5 0 017.6 15.8l-2.8.6zM1403.4 422.4c-1.9 1-3.8 1.7-5.8 2.3-2 .6-4 1.2-6 1.6a64.6 64.6 0 01-12.3 1.4 113.5 113.5 0 0012.1-1.9 66.3 66.3 0 0011.5-4.3l.5.9zM1363.5 419.2c-1.4-.3-2.9-.5-4.3-.3a17 17 0 00-4.2 1.3c-2.7 1.2-5.5 2.6-8.3 3.5-2.9 1-5.8 1.8-8.8 2.2l-1.1.2-1.1.1-2.2.3-4.5.3 4.5-.4 2.2-.3 1.1-.2 1.1-.2c3-.5 5.9-1.4 8.7-2.4 2.8-1 5.5-2.5 8.2-3.8 1.4-.6 2.8-1.2 4.4-1.5 1.6-.3 3.1-.1 4.7.2l-.4 1z"
                                className="st74"
                            />
                            <path
                                d="M1407.1 451.2l-2.7-.2c-.9.1-1.8.3-2.6.7-.8.4-1.7.9-2.4 1.5l-2.1 2 1.8-2.4c.7-.7 1.4-1.4 2.3-2 .9-.6 1.8-1 2.9-1.3 1-.3 2.1-.3 3.1-.3l-.3 2z"
                                className="st74"
                            />
                        </g>
                        <g id="v_3_">
                            <path
                                id="vigne_99_"
                                d="M1403.4 444.6l-.1.1c.2 1.9 1.6 3.2 3.3 3.1l.1-.1c-.1-.9-.5-1.7-1.1-2.3-.6-.5-1.4-.8-2.2-.8z"
                                className="st8"
                            />
                            <path
                                id="vigne_98_"
                                d="M1402 442.9l-.2-.1c-.5.1-.9.4-1.2.6.2-.3.4-.8.4-1.2l-.1-.2c-2.2-1.5-4.4-.7-4.9 1.8l.1.2c.4.3.9.5 1.3.6-.6.7-.9 1.8-.6 3.1l.2.2c1.3.2 2.4-.2 3-1l.6 1 .2.1c2.3-.8 2.8-3 1.2-5.1z"
                                className="st17"
                            />
                            <path
                                id="vigne_97_"
                                d="M1341.1 426.1v-.2l-.9-.7 1.1-.1.1-.1c.3-2.2-1-3.5-3-3.1l-.1.1v1.2c-.7-.2-1.6 0-2.5.6l-.1.2c.3 1.1 1 1.8 1.8 1.9l-.6.8v.2c1.4 1.6 3.2 1.2 4.2-.8z"
                                className="st8"
                            />
                            <path
                                id="vigne_96_"
                                d="M1392.8 439.3s.1-.1 0-.1l-.3-.8.7.5h.1c.9-.9.5-2.3-.8-3.1h-.1l-.4.6c-.4-.5-1-.9-1.7-1l-.1.1c-.2.7 0 1.4.5 1.9l-.6.1s-.1.1 0 .1c.3 1.4 1.5 2.2 2.7 1.7z"
                                className="st75"
                            />
                            <path
                                id="vigne_95_"
                                d="M1378.1 420.4l.1-.1-.2-1 .8.6h.2c1.5-1.3 1.4-3-.2-3.9h-.2l-.7.8c-.4-.6-1.1-1-2.1-1.1l-.2.1c-.5.9-.4 1.8.1 2.4l-.9.2-.1.1c0 1.7 1.5 2.6 3.4 1.9z"
                                className="st75"
                            />
                            <path
                                id="vigne_94_"
                                d="M1385.7 438.2l-.2-.2c-.6-.1-1.1 0-1.6.1.4-.3.7-.6 1-1.1v-.3c-1.7-2.6-4.4-2.7-6.1-.3v.3c.3.5.7.9 1.1 1.2a4 4 0 00-2.1 3l.1.3c1.3.9 2.7.9 3.7.2l.2 1.4.2.2c2.9.4 4.5-1.8 3.7-4.8z"
                                className="st8"
                            />
                            <path
                                id="vigne_93_"
                                d="M1403.8 449.9l-.2.1-.5.9-.3-1-.2-.1c-2.1.1-3.1 1.6-2.3 3.3l.2.1 1.1-.2c-.1.7.2 1.5 1 2.3 0 0 .1.1.2 0 1-.5 1.5-1.2 1.4-2l.9.4.2-.1c1.2-1.4.5-3.1-1.5-3.7z"
                                className="st8"
                            />
                            <path
                                id="vigne_92_"
                                d="M1413.6 440.3l.2.2 1.6-.1-1 1.1v.3c1.8 2.5 4.5 2.7 6.1.3v-.3c-.3-.5-.7-.9-1.1-1.2 1-.5 1.7-1.5 2-3.1l-.1-.3c-1.3-.9-2.7-.9-3.7-.2l-.2-1.4-.2-.2c-2.9-.3-4.5 1.9-3.6 4.9z"
                                className="st16"
                            />
                            <path
                                id="vigne_91_"
                                d="M1390.8 426.3l-.2-.1-1.1.4.5-1-.1-.2c-1.8-1.4-3.7-.9-4.3 1.1l.1.2c.3.3.7.5 1 .6-.6.6-.9 1.5-.8 2.6l.1.2c1.1.3 2.1 0 2.6-.7l.5.9.2.1c2.1-.3 2.8-2.2 1.5-4.1z"
                                className="st16"
                            />
                            <path
                                id="vigne_90_"
                                d="M1403 420.8l-.1-.1-.8-.2.6-.4v-.1c-.5-1.5-1.7-1.9-2.8-1v.1l.3.7a2 2 0 00-1.4 1.2v.1c.5.6 1.1.8 1.7.7l-.1.7.1.1c1.4.5 2.5-.3 2.5-1.8z"
                                className="st16"
                            />
                            <path
                                id="vigne_89_"
                                d="M1392.7 418.2l.1-.2-.4-1.5 1.3.8.3-.1c2.2-2.2 1.8-4.9-.8-6l-.3.1-1 1.3c-.7-.9-1.8-1.4-3.4-1.5l-.2.1c-.6 1.5-.4 2.8.5 3.7-.4.1-.9.2-1.3.5l-.1.2c.1 2.8 2.5 4 5.3 2.6z"
                                className="st75"
                            />
                            <path
                                id="vigne_88_"
                                d="M1409.7 434.1c.1-.1.1-.1 0-.2l-.7-1.1c.3.2.7.3 1.2.3l.2-.1c1.2-2.2.3-4.2-2-4.5l-.2.1-.5 1.2c-.7-.5-1.7-.7-3-.4l-.1.2c-.1 1.3.3 2.3 1.2 2.8l-.9.7c-.1.1-.1.1 0 .2.8 2.1 3 2.5 4.8.8z"
                                className="st17"
                            />
                            <path
                                id="vigne_87_"
                                d="M1412.4 456.3l.2.1c.5 0 1 0 1.4-.2l-.9 1v.2c1.7 2.2 4.1 2.2 5.5 0v-.2l-1-1c.9-.5 1.5-1.5 1.7-2.8l-.1-.2c-1.2-.7-2.4-.7-3.3 0l-.3-1.3-.2-.1c-2.6-.2-3.9 1.8-3 4.5z"
                                className="st7"
                            />
                            <path
                                id="vigne_86_"
                                d="M1389.2 441.1c-.1.1-.1.2 0 .2a5.1 5.1 0 007.3-.5c.1-.1.1-.2 0-.2a5.3 5.3 0 00-3.8-1.3 6 6 0 00-3.5 1.8z"
                                className="st16"
                            />
                            <path
                                id="vigne_85_"
                                d="M1392.5 426.1l.2-.2a5.2 5.2 0 00-5.3-5.1l-.2.2c0 1.4.6 2.7 1.6 3.7a5 5 0 003.7 1.4z"
                                className="st17"
                            />
                            <path
                                id="vigne_84_"
                                d="M1393.1 459.2l.1.1c2.3 0 4.2-1.9 4.2-4.2l-.1-.1c-1.2 0-2.2.5-3 1.2-.7.7-1.2 1.8-1.2 3z"
                                className="st17"
                            />
                            <path
                                id="vigne_83_"
                                d="M1412.3 439.5l.1-.1c.5-1.6-.5-3.4-2.2-4l-.1.1c-.3.8-.1 1.7.3 2.4s1 1.3 1.9 1.6z"
                                className="st76"
                            />
                            <path
                                id="vigne_82_"
                                d="M1412.9 439.9c.1-.1.1-.2 0-.2a5.2 5.2 0 00-7.4-.1c-.1.1-.1.2 0 .2 1 1 2.3 1.5 3.7 1.6 1.4 0 2.7-.5 3.7-1.5z"
                                className="st8"
                            />
                            <path
                                id="vigne_81_"
                                d="M1392.2 423.1l.1.2c2.8.8 5.6-.9 6.4-3.7l-.1-.2a5 5 0 00-4 .5c-1.1.7-2 1.8-2.4 3.2z"
                                className="st14"
                            />
                            <path
                                id="vigne_80_"
                                d="M1365.2 414.7l-.2.2a5.2 5.2 0 005.4 5l.2-.2c0-1.4-.7-2.7-1.6-3.6a5.4 5.4 0 00-3.8-1.4z"
                                className="st17"
                            />
                            <path
                                id="vigne_79_"
                                d="M1385.6 417.3l.1-.2a5.1 5.1 0 00-6.2-3.9l-.1.2a5.6 5.6 0 002.3 3.3c1.1.6 2.5.9 3.9.6z"
                                className="st17"
                            />
                            <path
                                id="vigne_78_"
                                d="M1392 443.5l.1.1c2 .4 3.9-.9 4.3-2.8l-.1-.1c-1-.2-1.9 0-2.7.5-.9.5-1.4 1.3-1.6 2.3z"
                                className="st75"
                            />
                            <path
                                id="vigne_77_"
                                d="M1415 450.4l.2.1a4.9 4.9 0 003-6.1l-.2-.1a4.6 4.6 0 00-2.7 2.4 4.7 4.7 0 00-.3 3.7z"
                                className="st17"
                            />
                            <path
                                id="vigne_76_"
                                d="M1362.2 419.2l.1-.1.2-.8.3.7s.1.1.1 0c1.5-.4 2-1.6 1.2-2.8 0 0-.1-.1-.1 0l-.8.3c-.1-.5-.4-1.1-1.1-1.5h-.1c-.6.5-.9 1.1-.8 1.7l-.7-.1-.1.1c-.5 1.2.3 2.3 1.8 2.5z"
                                className="st76"
                            />
                            <path
                                id="vigne_75_"
                                d="M1384.9 427.4l-.1-.1c-2.1.1-3.7 2.1-3.5 4.3l.1.1c1-.1 2-.6 2.6-1.4.7-.7 1-1.7.9-2.9z"
                                className="st75"
                            />
                            <path
                                id="vigne_74_"
                                d="M1381.6 427.9l-.1-.1-.8-.1.7-.5v-.1c-.6-1.6-2-2-3.2-.9v.1l.4.8c-.6.1-1.1.6-1.5 1.4v.1c.6.6 1.3.8 1.9.6l-.1.8.1.1c1.6.5 2.7-.5 2.6-2.2z"
                                className="st7"
                            />
                            <path
                                id="vigne_73_"
                                d="M1392.9 428.8l.1.1a3.2 3.2 0 002.3-3.9l-.1-.1c-.8.2-1.5.8-1.9 1.5-.4.7-.6 1.6-.4 2.4z"
                                className="st14"
                            />
                            <path
                                id="vigne_72_"
                                d="M1395.1 428.2s.1 0 0 0c1-.8 1-2.2.2-3.1h-.1c-.4.4-.7 1-.7 1.5 0 .6.2 1.2.6 1.6z"
                                className="st18"
                            />
                            <path
                                id="vigne_71_"
                                d="M1350 422.7h-.2l-1 1.1v-1.4l-.2-.2c-2.7-.6-4.6 1-4.2 3.6l.2.2 1.5.1c-.4.9-.2 2.1.5 3.3l.2.1c1.4-.3 2.3-1.1 2.6-2.2l1 .8h.2c2.1-1.5 1.8-4-.6-5.4z"
                                className="st75"
                            />
                            <path
                                id="vigne_70_"
                                d="M1369.6 415.3h-.1a3.4 3.4 0 001.3 4.6h.1c.5-.8.5-1.7.3-2.5-.2-.9-.7-1.7-1.6-2.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_69_"
                                d="M1410.6 448.9s-.1-.1-.1 0c-1.6 1-2 3.1-1 4.6 0 0 .1.1.1 0 .8-.5 1.3-1.3 1.5-2.1.2-.8 0-1.7-.5-2.5z"
                                className="st75"
                            />
                            <path
                                id="vigne_68_"
                                d="M1332.6 416.1s-.1.1 0 .1c.9 1.8 2.9 2.5 4.5 1.7 0 0 .1-.1 0-.1a3.8 3.8 0 00-2-1.8c-.7-.3-1.7-.3-2.5.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_67_"
                                d="M1331 427.2l-.1-.1-.9-.1.7-.5v-.2c-.8-1.6-2.3-2-3.5-.8v.2l.5.8c-.6.2-1.2.7-1.5 1.6v.2c.7.6 1.5.8 2.1.5v.8l.1.1c1.6.4 2.8-.7 2.6-2.5z"
                                className="st75"
                            />
                            <path
                                id="vigne_66_"
                                d="M1333.9 430.2l.1.1a3.5 3.5 0 001.9-4.4l-.1-.1a3 3 0 00-1.8 1.8c-.4.8-.4 1.8-.1 2.6z"
                                className="st76"
                            />
                            <path
                                id="vigne_65_"
                                d="M1351.8 417.6l-.1-.1h-1l.7-.6v-.2c-.9-1.8-2.7-2-3.9-.6v.2l.6.8c-.7.2-1.2.9-1.5 1.8v.2c.8.6 1.7.8 2.4.4v.9l.1.1c1.8.4 3-.9 2.7-2.9z"
                                className="st14"
                            />
                            <path
                                id="vigne_64_"
                                d="M1351.2 415.5l-.1.2c1.1 2.7 4.1 4 6.8 2.9l.1-.2a5.1 5.1 0 00-2.8-2.8 4.7 4.7 0 00-4-.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_63_"
                                d="M1356.5 412.7h-.2a4 4 0 001.5 5.5h.2c.6-1 .7-2.1.4-3.1-.3-.9-.9-1.8-1.9-2.4z"
                                className="st15"
                            />
                            <path
                                id="vigne_62_"
                                d="M1342.5 419.3l.1.1c1.8.4 3.6-.8 4-2.6l-.1-.1c-.9-.2-1.8 0-2.5.5-.8.5-1.3 1.2-1.5 2.1z"
                                className="st75"
                            />
                            <path
                                id="vigne_61_"
                                d="M1343 418.1v-.2c-.2-.3-.5-.5-.8-.6l1-.1.1-.1c.3-2-.9-3.2-2.7-2.8l-.1.1a2 2 0 000 1.1c-.7-.2-1.5-.1-2.3.5l-.1.2c.2 1 .9 1.6 1.6 1.7l-.5.8v.2c1.1 1.3 2.9.9 3.8-.8z"
                                className="st76"
                            />
                            <path
                                id="vigne_60_"
                                d="M1403.3 420.8c.1.1.2.1.2 0 .5-.2.9-.5 1.2-.8-.2.4-.3.8-.3 1.3l.1.2c2.5 1.2 4.7.1 4.9-2.5l-.1-.2-1.4-.5c.6-.8.7-2 .3-3.3l-.2-.2c-1.4-.1-2.5.5-3 1.5a7 7 0 00-.8-1c-.1-.1-.2-.1-.2 0-2.5 1.1-2.8 3.5-.7 5.5z"
                                className="st77"
                            />
                            <path
                                id="vigne_59_"
                                d="M1389.7 438.9l.1-.1a4 4 0 00-3.3-4.6l-.1.1a4 4 0 00.7 3c.6.9 1.6 1.5 2.6 1.6z"
                                className="st8"
                            />
                            <path
                                id="vigne_58_"
                                d="M1411.9 448.5l.1-.2-.2-1 .8.6h.2c1.5-1.4 1.3-3.2-.4-4h-.2l-.7.8c-.4-.6-1.2-1-2.2-1.1l-.2.1c-.4 1-.3 1.9.2 2.5l-.9.3-.1.2c.1 1.8 1.7 2.7 3.6 1.8z"
                                className="st14"
                            />
                            <path
                                id="vigne_57_"
                                d="M1407.9 423.9l.1.1c1.8.5 3.7-.6 4.2-2.4l-.1-.1c-.9-.2-1.8-.1-2.6.3-.7.5-1.3 1.2-1.6 2.1z"
                                className="st14"
                            />
                            <path
                                id="vigne_56_"
                                d="M1408.1 424c1-.5 1.3-1.7.8-2.6h-.1c-.5.3-.8.7-.9 1.2-.1.4-.1.9.2 1.4z"
                                className="st75"
                            />
                            <path
                                id="vigne_55_"
                                d="M1373.5 419.9l-.1-.1-1 .2.6-.8v-.2c-1.3-1.6-3.2-1.5-4.1.2v.2l.8.7c-.6.4-1.1 1.1-1.2 2.2l.1.2c.9.5 1.8.4 2.5-.1l.2.9.1.1c2 0 2.9-1.6 2.1-3.5z"
                                className="st17"
                            />
                            <path
                                id="vigne_54_"
                                d="M1412.3 432.7s.1.1.1 0l.7-.3-.3.6v.1c1.2.9 2.4.6 2.8-.7v-.1l-.7-.4c.4-.4.6-.9.5-1.7l-.1-.1c-.7-.2-1.3 0-1.7.4l-.3-.6s-.1-.1-.1 0c-1.3.3-1.7 1.6-.9 2.8z"
                                className="st75"
                            />
                            <path
                                id="vigne_53_"
                                d="M1398.7 411c-.1-.1-.2 0-.2 0a5.1 5.1 0 001.3 7.2c.1.1.2 0 .2 0 .8-1.2 1.1-2.6.8-3.9a5 5 0 00-2.1-3.3z"
                                className="st14"
                            />
                            <path
                                id="vigne_52_"
                                d="M1396.7 414.1l-.1.1c-.2 2 1.3 3.7 3.3 3.9l.1-.1c.1-1-.2-1.9-.8-2.6a3.8 3.8 0 00-2.5-1.3z"
                                className="st75"
                            />
                            <path
                                id="vigne_51_"
                                d="M1407.5 425.6l-.1-.1-.9-.3.8-.4.1-.2c-.5-1.8-2-2.4-3.4-1.4l-.1.2.4.9c-.7.1-1.3.5-1.8 1.3v.2c.6.8 1.3 1.1 2 .9l-.2.8.1.1c1.6.7 3-.2 3.1-2z"
                                className="st76"
                            />
                            <path
                                id="vigne_46_"
                                d="M1412.8 423.8l-.1-.1a3.4 3.4 0 00-2.5 4.1l.1.1c.9-.2 1.6-.7 2.1-1.5.4-.7.5-1.6.4-2.6z"
                                className="st14"
                            />
                        </g>
                    </g>
                </g>
                <g id="buisson_12_">
                    <path
                        d="M520.1 267.5a18.3 18.3 0 01-35.1 7.2 18.3 18.3 0 0124.4-23.8 18 18 0 0110.7 16.6z"
                        className="st15"
                    />
                    <path
                        d="M510.5 257.3a18.6 18.6 0 01-25.5 17.3 18.3 18.3 0 0124.4-23.8c.7 2 1.1 4.2 1.1 6.5z"
                        className="st9"
                    />
                </g>
                <g id="fleur_25_">
                    <path id="tige_25_" d="M455.5 249.2h.3v5.9h-.3z" className="st7" />
                    <circle id="petales_51_" cx="455.6" cy="248.4" r="1.1" className="st33" />
                    <path id="petales_50_" d="M454.5 246.5h2.2v1.9h-2.2z" className="st33" />
                    <ellipse
                        id="feuille_52_"
                        cx="457.1"
                        cy="250"
                        className="st19"
                        rx="2.5"
                        ry=".6"
                        transform="rotate(-53.5 457.1 250)"
                    />
                    <ellipse
                        id="feuille_51_"
                        cx="454.3"
                        cy="251.1"
                        className="st19"
                        rx=".7"
                        ry="3"
                        transform="rotate(-25.2 454.3 251)"
                    />
                </g>
                <g id="arbre_15_">
                    <g id="branches_16_">
                        <path d="M355.8 180.9h8.7v59.5h-8.7z" className="st6" />
                        <path d="M368.5 177.3h5.8V206h-5.8z" className="st6" transform="rotate(52.4 371.4 191.7)" />
                        <path d="M372.5 168.7h2.2v26.9h-2.2z" className="st6" transform="rotate(30 373.6 182.1)" />
                        <path d="M343.9 180.2h3.1v17.7h-3.1z" className="st6" transform="rotate(-11.4 345.4 189.1)" />
                        <path d="M345.9 187.6h5.5v25.6h-5.5z" className="st6" transform="rotate(-48.4 348.7 200.4)" />
                        <path d="M362.9 212.9h1.7v11.4h-1.7z" className="st6" transform="rotate(52.4 363.8 218.6)" />
                    </g>
                    <g id="feuilles_12_">
                        <path
                            d="M346.2 180.4a17.9 17.9 0 01-32.1 10.9h.3a23.8 23.8 0 0023.8-23.8l-.1-2.1c4.9 3.2 8.1 8.7 8.1 15z"
                            className="st19"
                        />
                        <path
                            d="M338.2 167.5a23.8 23.8 0 01-23.8 23.8h-.3a17.8 17.8 0 0123.9-25.8l.2 2z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_11_">
                        <path d="M387.8 154.7a28.6 28.6 0 01-55 11.1 28.6 28.6 0 1155-11.1z" className="st19" />
                        <path
                            d="M372.8 138.8a29 29 0 01-39.9 27 28.6 28.6 0 0138.1-37.2c1.1 3.2 1.8 6.6 1.8 10.2z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_10_">
                        <path
                            d="M401.2 173.6a14.5 14.5 0 01-26 8.8h.2c10.6 0 19.3-8.6 19.3-19.3l-.1-1.7c4 2.6 6.6 7.1 6.6 12.2z"
                            className="st19"
                        />
                        <path
                            d="M394.7 163.1c0 10.6-8.6 19.3-19.3 19.3h-.2a14.5 14.5 0 0119.4-21l.1 1.7z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_39_">
                    <g id="branches_17_">
                        <path d="M1546.4 455.7h8.8v37.6h-8.8z" className="st6" />
                        <path d="M1554.7 466.3h2.2V478h-2.2z" className="st6" transform="rotate(37 1555.7 472)" />
                        <path d="M1544.5 469.5h2.2v11.7h-2.2z" className="st6" transform="rotate(-19.2 1545.7 475.4)" />
                    </g>
                    <g id="feuilles_15_">
                        <path
                            d="M1547.8 466.7c0 2.9-2.3 5.2-5.2 5.2-1.8 0-3.3-.9-4.3-2.2a8 8 0 006.8-7.5c1.6.9 2.7 2.6 2.7 4.5z"
                            className="st7"
                        />
                        <path
                            d="M1545.1 462.2c-.1 3.8-3 7-6.8 7.5a5 5 0 01-.9-3c0-2.9 2.3-5.2 5.2-5.2.9 0 1.8.3 2.5.7z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_14_">
                        <path
                            d="M1570.6 456.6l-.5.6-1.1 1.2-.6.6-.9.8-.9.8-1.2.9a29 29 0 01-8.3 4.4l-1.1.3-.8.2-1.1.2-1.6.2h-2.7l-.6-.1-.6-.1-1.7-.4-.5-.1-.9-.3-1-.3-2.6-1-.5-.3-2.1-1.1-.9-.5-.3-.2-.5-.3-1.2-.9-.5-.4-.8-.6-.8-.7-.3-.3-.1-.1a30 30 0 0011.3-10l1.5-.2c6.1-1 11.7-3.9 16-8a31.3 31.3 0 008.7-15l1.4 1c7.3 6.4 6.3 19.6-2.2 29.7z"
                            className="st7"
                        />
                        <path
                            d="M1571.4 426.1a32.3 32.3 0 01-8.7 15c-4.3 4.2-9.9 7-16 8l-1.5.2c-2.1 3-4.7 5.7-7.7 7.8-1.2.8-2.4 1.6-3.6 2.2l-.5-.4-.8-.8-.9-.9a31.4 31.4 0 01-7.7-15.8c-.7-5.5.7-10.5 4.3-13.8a7 7 0 011-.8l.4-.3.2-.1.3-.2.3-.2.4-.2.2-.1.4-.2.6-.2.7-.3.7-.2.1-.9v-.1l.1-.8.1-.3.1-.7.1-.2.2-.8.3-.9.3-.9.7-1.8.2-.5.6-1.2.2-.4.3-.5.3-.5.1-.2.4-.6.1-.2.3-.5.3-.4.4-.6.4-.5.5-.6.3-.3.6-.5.5-.5.6-.5.1-.1.4-.3.4-.3.6-.4.1-.1.5-.3.8-.4.6-.3.8-.3.7-.2h.2l.7-.2.7-.2h.1l.7-.1.7-.1H1551.5l1.5.2h.1l.6.1.2.1.6.2h.1l.7.2.5.2h.2l.3.1.2.1.3.1.7.4.8.5.5.3.1 2.3c0 5.6-1.4 10.9-3.9 15.4 4.4-1.9 9-2.3 12.9-1.1l.6.2.6.2.5.2.4.2.4.2.4.2.6.2z"
                            className="st11"
                        />
                        <path
                            d="M1568.1 424.6a19 19 0 00-12.9 1.1 32 32 0 003.9-15.4l-.1-2.3 1.9 1.5.5.5.1.1.3.3.3.3.3.3.7.9.4.5.3.4.1.2.4.6.1.2.3.6.4.8.2.5.2.5a46.8 46.8 0 011.6 4.7l.1.6.1.4.1.6.1.4.1.8c.4.2.4.5.5.9z"
                            className="st7"
                        />
                    </g>
                    <g id="feuilles_13_">
                        <path
                            d="M1568 462.7a7 7 0 01-12.7 4c5-.7 8.9-4.9 9.1-10.1a7.1 7.1 0 013.6 6.1z"
                            className="st7"
                        />
                        <path d="M1564.4 456.6c-.2 5.2-4.1 9.4-9.1 10.1a7 7 0 019.1-10.1z" className="st11" />
                    </g>
                </g>
                <g id="arbre_13_">
                    <g id="branches_18_">
                        <path d="M1722.9 405.8h11.1v64.7h-11.1z" className="st6" />
                        <path d="M1738.5 405.4h7v48.1h-7z" className="st6" transform="rotate(32.7 1742 429.5)" />
                        <path d="M1740.7 402.7h4V430h-4z" className="st6" transform="rotate(6.7 1743 416.4)" />
                        <path d="M1711.3 394.1h7v40.1h-7z" className="st6" transform="rotate(-37.6 1714.7 414.1)" />
                    </g>
                    <g id="feuilles_18_">
                        <path
                            d="M1716.2 389.8a18.3 18.3 0 01-32.9 11.1h.3a24.3 24.3 0 0024.3-24.3l-.1-2.1a18 18 0 018.4 15.3z"
                            className="st7"
                        />
                        <path
                            d="M1708 376.6a24.3 24.3 0 01-24.3 24.3h-.3a18.3 18.3 0 0124.6-26.4v2.1z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_17_">
                        <path
                            d="M1775.2 394.4c0 10.9-8.8 19.7-19.7 19.7-8.2 0-15.2-5-18.2-12a19.7 19.7 0 0126.2-25.7c6.9 3 11.7 9.9 11.7 18z"
                            className="st7"
                        />
                        <path
                            d="M1764.8 383.4a20.1 20.1 0 01-27.6 18.7 19.7 19.7 0 0126.2-25.7c1 2.1 1.4 4.5 1.4 7z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_16_">
                        <path
                            d="M1760.8 379.7a31 31 0 01-55.6 18.8h.5a41.1 41.1 0 0041.1-41.1l-.2-3.6c8.6 5.4 14.2 15 14.2 25.9z"
                            className="st7"
                        />
                        <path d="M1746.9 357.3a41.1 41.1 0 01-41.1 41.1h-.5a31 31 0 0141.6-44.8v3.7z" className="st8" />
                    </g>
                </g>
                <g id="arbre_12_">
                    <g id="branches_19_">
                        <path d="M1668.2 409.5h10.4V481h-10.4z" className="st6" />
                        <path d="M1681.2 430.3h3.3v22.4h-3.3z" className="st6" transform="rotate(52.4 1682.8 441.6)" />
                        <path d="M1679.5 401.3h2.6v32.3h-2.6z" className="st6" transform="rotate(30 1680.9 417.3)" />
                        <path d="M1662.1 421h3.7v21.3h-3.7z" className="st6" transform="rotate(-37.6 1663.9 431.6)" />
                        <path d="M1664.4 450.1h3.7v13.4h-3.7z" className="st6" transform="rotate(-60 1666.3 456.8)" />
                    </g>
                    <g id="feuilles_21_">
                        <path d="M1703.8 383.2a31.6 31.6 0 01-60.7 12.3 31.6 31.6 0 1160.7-12.3z" className="st8" />
                        <path
                            d="M1687.2 365.6a32.1 32.1 0 01-44 29.8 31.6 31.6 0 0142-41.1c1.2 3.6 2 7.4 2 11.3z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_20_">
                        <path
                            d="M1666.5 414.8a17 17 0 01-30.7 10.4h.3a22.7 22.7 0 0022.7-22.7l-.1-2a17 17 0 017.8 14.3z"
                            className="st8"
                        />
                        <path
                            d="M1658.8 402.5a22.7 22.7 0 01-22.7 22.7h-.3a17 17 0 0122.9-24.7l.1 2z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_19_">
                        <path
                            d="M1714.7 426.1a15.9 15.9 0 01-28.5 9.6h.3c11.7 0 21.1-9.5 21.1-21.1l-.1-1.9c4.3 2.9 7.2 7.8 7.2 13.4z"
                            className="st8"
                        />
                        <path
                            d="M1707.6 414.6c0 11.7-9.5 21.1-21.1 21.1h-.3a15.9 15.9 0 0121.3-22.9l.1 1.8z"
                            className="st12"
                        />
                    </g>
                </g>
                <g id="arbre_11_">
                    <g id="branches_20_">
                        <path d="M103.4 146.1h8.7v59.5h-8.7z" className="st6" />
                        <path d="M116.2 143.5h5.8v28.7h-5.8z" className="st6" transform="rotate(52.4 119 157.9)" />
                        <path d="M78.7 143h4.4v28h-4.4z" className="st6" transform="rotate(-48.4 81 157)" />
                        <path d="M91.3 138.7h3.1v17.7h-3.1z" className="st6" transform="rotate(27.7 92.9 147.5)" />
                        <path d="M91.5 141.4H97v65h-5.5z" className="st6" transform="rotate(-17.3 94.2 173.9)" />
                        <path d="M89.8 166.9h1.7v11.4h-1.7z" className="st6" transform="rotate(-57.3 90.6 172.6)" />
                        <path
                            id="branche"
                            d="M120.9 140.5h3.1v17.7h-3.1z"
                            className="st6"
                            transform="rotate(27.7 122.5 149.3)"
                        />
                    </g>
                    <g id="feuilles_25_">
                        <path d="M104.5 128.6a18.1 18.1 0 01-34.7 7.1 18.1 18.1 0 1134.7-7.1z" className="st11" />
                        <path
                            d="M95 118.5a18.4 18.4 0 01-25.2 17.1 18.1 18.1 0 0124.1-23.5c.7 2 1.1 4.2 1.1 6.4z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_24_">
                        <path d="M77.3 143.5a12 12 0 01-23.2 4.7 12 12 0 1123.2-4.7z" className="st11" />
                        <path
                            d="M71 136.8a12.3 12.3 0 01-16.9 11.4 12 12 0 0116.1-15.7c.5 1.3.8 2.8.8 4.3z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_23_">
                        <path
                            d="M137.5 119.3a29.7 29.7 0 01-54.1 17c21.4-3 37.9-21 38.8-43 9 5 15.3 14.8 15.3 26z"
                            className="st11"
                        />
                        <path d="M122.1 93.3a45.2 45.2 0 01-38.8 43 29.7 29.7 0 0138.8-43z" className="st12" />
                    </g>
                    <g id="feuilles_22_">
                        <path
                            d="M160.4 136.9a19 19 0 01-33.9 11.5h.3a25 25 0 0025.1-25.1l-.1-2.2c5.2 3.3 8.6 9.2 8.6 15.8z"
                            className="st11"
                        />
                        <path d="M151.9 123.3a25 25 0 01-25.1 25.1h-.3a19 19 0 0125.3-27.4l.1 2.3z" className="st12" />
                    </g>
                </g>
                <g id="arbre_10_">
                    <g id="branches_4_">
                        <path d="M1862 369.9h9.6v55.3h-9.6z" className="st6" />
                        <path d="M1872.2 391.9h3v15.4h-3z" className="st6" transform="rotate(-135 1873.7 399.6)" />
                        <path d="M1876.2 351.2h4.3v34h-4.3z" className="st6" transform="rotate(34.3 1878.3 368.2)" />
                        <path
                            d="M1876.4 352.5h3.8v17h-3.8z"
                            className="st6"
                            transform="rotate(4.8 1878.3 361) scale(.99996)"
                        />
                        <path
                            d="M1877.8 366.3h2.6V378h-2.6z"
                            className="st6"
                            transform="scale(-1) rotate(55.1 713.5 -3603.2)"
                        />
                        <path d="M1852.5 362.5h6.3v36.2h-6.3z" className="st6" transform="rotate(-33.2 1855.7 380.6)" />
                        <path d="M1845.5 370.5h3.8v17h-3.8z" className="st6" transform="rotate(-69.5 1847.3 379)" />
                    </g>
                    <g id="feuilles_31_">
                        <path
                            d="M1854.4 354.4a14.5 14.5 0 01-26 8.8h.2c10.6 0 19.3-8.6 19.3-19.3l-.1-1.7c3.9 2.6 6.6 7.1 6.6 12.2z"
                            className="st19"
                        />
                        <path
                            d="M1847.8 343.9c0 10.6-8.6 19.3-19.3 19.3h-.2a14.5 14.5 0 0119.4-21l.1 1.7z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_30_">
                        <path d="M1910.5 351.5a15.2 15.2 0 01-29.2 5.9 15.2 15.2 0 1129.2-5.9z" className="st19" />
                        <path
                            d="M1902.5 343.1a15.4 15.4 0 01-21.1 14.3 15.2 15.2 0 0120.2-19.8c.6 1.8.9 3.6.9 5.5z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_29_">
                        <path
                            d="M1899.3 339.9a32.6 32.6 0 01-59.3 18.7 49.6 49.6 0 0042.5-47.1 32.2 32.2 0 0116.8 28.4z"
                            className="st19"
                        />
                        <path d="M1882.5 311.4a49.4 49.4 0 01-42.5 47.1 32.5 32.5 0 0142.5-47.1z" className="st11" />
                    </g>
                    <g id="feuilles_28_">
                        <path
                            d="M1888.2 391.8a6.7 6.7 0 01-12 4.1h.1a9 9 0 008.9-8.9v-.8c1.8 1.2 3 3.2 3 5.6z"
                            className="st19"
                        />
                        <path
                            d="M1885.2 387a9 9 0 01-8.9 8.9h-.1a6.3 6.3 0 01-1.4-4c0-3.7 3-6.7 6.7-6.7 1.3 0 2.6.4 3.6 1.1l.1.7z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_27_">
                        <path
                            d="M1895.4 366.4a7.5 7.5 0 01-13.4 4.6h.1c5.5 0 9.9-4.4 9.9-9.9v-.9c2 1.3 3.4 3.6 3.4 6.2z"
                            className="st19"
                        />
                        <path d="M1892 361c0 5.5-4.4 9.9-9.9 9.9h-.1a7.5 7.5 0 0110.1-10.8l-.1.9z" className="st11" />
                    </g>
                    <g id="feuilles_26_">
                        <path
                            d="M1843.2 373.4a8 8 0 01-14.6 4.6c5.8-.8 10.2-5.7 10.4-11.6a7.8 7.8 0 014.2 7z"
                            className="st19"
                        />
                        <path
                            d="M1839.1 366.4a12.2 12.2 0 01-10.4 11.6 8 8 0 016.6-12.6c1.3 0 2.6.3 3.8 1z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_9_">
                    <g id="branches_21_">
                        <path d="M49.1 127.2h10.8V198H49.1z" className="st13" />
                        <path d="M59.4 126.9h7.7v43.8h-7.7z" className="st13" transform="rotate(23.4 63.2 148.9)" />
                        <path d="M58.3 173.3h3.9v14.5h-3.9z" className="st13" transform="rotate(52.4 60.3 180.6)" />
                        <path d="M42.6 150.3h7v23.5h-7z" className="st13" transform="rotate(135 46 162)" />
                    </g>
                    <g id="feuilles_34_">
                        <path
                            d="M78.8 81.8v24.3A24.3 24.3 0 0136 121.8a59.4 59.4 0 0042-46.5c.5 2 .8 4.2.8 6.5z"
                            className="st7"
                        />
                        <path
                            d="M77.9 75.3a58.9 58.9 0 01-42 46.5 24 24 0 01-5.8-15.7V81.8a24.3 24.3 0 0147.8-6.5z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_33_">
                        <path
                            d="M96.2 113.5a18.3 18.3 0 01-35.1 7.2 18.3 18.3 0 0124.4-23.8 18.3 18.3 0 0110.7 16.6z"
                            className="st7"
                        />
                        <path
                            d="M86.5 103.4A18.6 18.6 0 0161 120.7a18.3 18.3 0 0124.4-23.8c.7 2 1.1 4.2 1.1 6.5z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_32_">
                        <path
                            d="M45.5 146.2a15.3 15.3 0 01-27.9 8.7 23.4 23.4 0 0020-22.1c4.7 2.6 7.9 7.6 7.9 13.4z"
                            className="st7"
                        />
                        <path
                            d="M37.6 132.8c-.4 11.3-9 20.6-20 22.1a15.4 15.4 0 0112.6-24.1c2.7.1 5.2.8 7.4 2z"
                            className="st8"
                        />
                    </g>
                </g>
                <g id="arbre_8_">
                    <g id="branches_3_">
                        <path d="M206.7 143.8h11.6v66.5h-11.6z" className="st6" />
                        <path d="M197.1 150.1h7v31.3h-7z" className="st6" transform="rotate(-45 200.6 165.8)" />
                        <path d="M220.7 175.6h4.8v21.5h-4.8z" className="st6" transform="rotate(-135 223.2 186.4)" />
                    </g>
                    <g id="feuilles_37_">
                        <path
                            d="M238.1 125.7a28 28 0 01-53.8 10.9 28 28 0 0125.8-38.9 28 28 0 0128 28z"
                            className="st9"
                        />
                        <path
                            d="M223.4 110.2a28.6 28.6 0 01-39.1 26.5 28 28 0 0125.8-38.9c4.1 0 8 .9 11.4 2.4 1.3 3.1 1.9 6.4 1.9 10z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_36_">
                        <path
                            d="M201.5 148.3a17.4 17.4 0 01-31.3 10.5h.3a23.2 23.2 0 0023.2-23.2l-.1-2c4.8 3.2 7.9 8.5 7.9 14.7z"
                            className="st9"
                        />
                        <path
                            d="M193.7 135.7a23.2 23.2 0 01-23.2 23.2h-.3a17.4 17.4 0 0123.3-25.2l.2 2z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_35_">
                        <path
                            d="M246.5 174.3a12 12 0 01-21.9 6.9 18.4 18.4 0 0015.7-17.4 12 12 0 016.2 10.5z"
                            className="st9"
                        />
                        <path
                            d="M240.3 163.8c-.3 8.9-7 16.1-15.7 17.4a11.5 11.5 0 01-2.1-6.9 12 12 0 0117.8-10.5z"
                            className="st14"
                        />
                    </g>
                </g>
                <g id="arbre_40_">
                    <g id="branches_22_">
                        <path d="M1828.7 400.3h8.8v37.6h-8.8z" className="st6" />
                        <path d="M1837.8 414.2h2.2v11.7h-2.2z" className="st6" transform="rotate(37 1838.8 420)" />
                    </g>
                    <g id="feuilles_38_">
                        <path
                            d="M1852.9 401.2l-.5.6-1.1 1.2-.6.6-.9.8-.9.8-1.2.9a29 29 0 01-8.3 4.4l-1.1.3-.8.2-1.1.2-1.6.2h-2.7l-.6-.1-.6-.1-1.7-.4-.5-.1-.9-.3-1-.3-2.6-1-.5-.3-2.1-1.1-.9-.5-.3-.2-.5-.3-1.2-.9-.5-.4-.8-.6-.8-.7-.3-.3-.1-.1a30 30 0 0011.3-10l1.5-.2c6.1-1 11.7-3.9 16-8a31.3 31.3 0 008.7-15l1.4 1c7.3 6.4 6.3 19.6-2.2 29.7z"
                            className="st15"
                        />
                        <path
                            d="M1853.7 370.7a32.3 32.3 0 01-8.7 15c-4.3 4.2-9.9 7-16 8l-1.5.2c-2.1 3-4.7 5.7-7.7 7.8-1.2.8-2.4 1.6-3.6 2.2l-.5-.4-.8-.8-.9-.9a31.4 31.4 0 01-7.7-15.8c-.7-5.5.7-10.5 4.3-13.8a7 7 0 011-.8l.4-.3.2-.1.3-.2.3-.2.4-.2.2-.1.4-.2.6-.2.7-.3.7-.2.1-.9v-.1l.1-.8.1-.3.1-.7.1-.2.2-.8.3-.9.3-.9.7-1.8.2-.5.6-1.2.2-.4.3-.5.3-.5.1-.2.4-.6.1-.2.3-.5.3-.4.4-.6.4-.5.5-.6.3-.3.6-.5.5-.5.6-.5.1-.1.4-.3.4-.3.6-.4.1-.1.5-.3.8-.4.6-.3.8-.3.7-.2h.2l.7-.2.7-.2h.1l.7-.1.7-.1H1833.8l1.5.2h.1l.6.1.2.1.6.2h.1l.7.2.5.2h.2l.3.1.2.1.3.1.7.4.8.5.5.3.1 2.3c0 5.6-1.4 10.9-3.9 15.4 4.4-1.9 9-2.3 12.9-1.1l.6.2.6.2.5.2.4.2.4.2.4.2.6.2z"
                            className="st9"
                        />
                        <path
                            d="M1850.4 369.2a19 19 0 00-12.9 1.1 32 32 0 003.9-15.4l-.1-2.3 1.9 1.5.5.5.1.1.3.3.3.3.3.3.7.9.4.5.3.4.1.2.4.6.1.2.3.6.4.8.2.5.2.5a46.8 46.8 0 011.6 4.7l.1.6.1.4.1.6.1.4.1.8c.4.2.5.5.5.9z"
                            className="st15"
                        />
                    </g>
                </g>
                <g id="arbre_54_">
                    <g id="branches_23_">
                        <path d="M1139 448.2h9.5v40.5h-9.5z" className="st6" />
                        <path d="M1148 459.6h2.3v12.6h-2.3z" className="st6" transform="rotate(37 1149 465.8)" />
                        <path d="M1137 463h2.3v12.6h-2.3z" className="st6" transform="rotate(-19.2 1138.2 469.3)" />
                    </g>
                    <g id="feuilles_41_">
                        <path
                            d="M1140.5 460c0 3.1-2.5 5.6-5.6 5.6-1.9 0-3.6-.9-4.6-2.4 4-.6 7.1-4 7.3-8.1 1.7.9 2.9 2.8 2.9 4.9z"
                            className="st19"
                        />
                        <path d="M1137.6 455.1a8.6 8.6 0 01-7.3 8.1 5.6 5.6 0 017.3-8.1z" className="st11" />
                    </g>
                    <g id="feuilles_40_">
                        <path
                            d="M1165 449.1l-.6.7-1.2 1.3-.6.6-.9.9-.9.8-1.3 1a27.8 27.8 0 01-9 4.7l-1.2.3-.9.2-1.2.2-1.7.2-.8.1H1142.6l-.7-.1-.6-.1-1.8-.4a.8.8 0 01-.5-.2l-1-.3-1-.3-2.8-1.1-.6-.3-2.2-1.2-.9-.6-.3-.2-.5-.4-1.3-.9-.5-.4-.8-.7-.9-.8-.3-.3-.1-.1a32.7 32.7 0 0012.1-10.8l1.6-.2a32 32 0 0017.2-8.6 33.4 33.4 0 009.3-16.1l1.5 1.1c7.8 6.9 6.7 21.2-2.5 32z"
                            className="st19"
                        />
                        <path
                            d="M1165.9 416.2a33.4 33.4 0 01-9.3 16.1 31.7 31.7 0 01-18.8 8.8 34.5 34.5 0 01-12.1 10.8l-.5-.5-.8-.8-1-1c-4-4.4-6.7-9.5-7.8-14.4l-.5-2.7c-.8-5.9.7-11.4 4.6-14.9l1.1-.9.4-.3.2-.1.4-.2.3-.2.4-.2.2-.1.4-.2.6-.3.7-.3.8-.2.1-1v-.1l.1-.8.1-.4.1-.8.1-.3.2-.9.3-1 .3-1 .7-1.9.3-.6.6-1.3.2-.4.3-.5.3-.5.2-.3.4-.7.1-.2.4-.5.3-.4.5-.6.5-.5.6-.6.3-.3.6-.6.6-.5.6-.5.1-.1.4-.3.4-.3.7-.4.2-.1.5-.3.9-.5.7-.3h.1l.8-.3.7-.2.2-.1.8-.2.8-.2h.1l.8-.1.8-.1h2l1.6.2h.1l.6.1.3.1.6.2h.1l.8.3.5.2h.2l.4.2.2.1.3.1.7.4.9.5.5.4.1 2.5c0 6-1.5 11.7-4.2 16.6 4.8-2 9.7-2.5 13.8-1.2l.7.2.7.3.5.2.4.2.5.2.4.2c-.1.1.1.2.2.3z"
                            className="st11"
                        />
                        <path
                            d="M1162.3 414.6c-4.2-1.3-9.1-.8-13.8 1.2a34.4 34.4 0 004.2-16.6l-.1-2.5 2 1.6.6.5.1.1.3.3.3.4.3.3.8.9.4.5.3.5.1.2.4.7.1.2.4.6.5.9.3.5.3.5 1.3 3.2.2.8.2.9.2.7.1.5.1.7.1.4.1.9c.2.4.2.7.2 1.1z"
                            className="st19"
                        />
                    </g>
                    <g id="feuilles_39_">
                        <path
                            d="M1162.3 455.7c0 4.2-3.4 7.5-7.5 7.5a7.7 7.7 0 01-6.2-3.2c5.4-.8 9.6-5.3 9.8-10.9a7.5 7.5 0 013.9 6.6z"
                            className="st19"
                        />
                        <path
                            d="M1158.4 449.1a11.4 11.4 0 01-9.8 10.9 7.5 7.5 0 016.1-11.8c1.3 0 2.6.3 3.7.9z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_3_">
                    <g id="branches_24_">
                        <path d="M456.4 202.2h11.1v64.7h-11.1z" className="st6" />
                        <path d="M472 201.7h7v48.1h-7z" className="st6" transform="rotate(32.7 475.5 225.8)" />
                        <path d="M474.2 199h4v27.3h-4z" className="st6" transform="rotate(6.7 476.3 212.7)" />
                        <path d="M444.8 190.4h7v40.1h-7z" className="st6" transform="rotate(-37.6 448.3 210.5)" />
                    </g>
                    <g id="feuilles_44_">
                        <path
                            d="M449.7 186.2a18.3 18.3 0 01-32.9 11.1h.3a24.3 24.3 0 0024.3-24.3l-.1-2.1a18 18 0 018.4 15.3z"
                            className="st15"
                        />
                        <path
                            d="M441.5 173a24.3 24.3 0 01-24.3 24.3h-.3a18.3 18.3 0 0124.6-26.4v2.1z"
                            className="st9"
                        />
                    </g>
                    <g id="feuilles_43_">
                        <path
                            d="M508.7 190.7c0 10.9-8.8 19.7-19.7 19.7-8.2 0-15.2-5-18.2-12a19.7 19.7 0 0126.2-25.7c6.9 3.1 11.7 10 11.7 18z"
                            className="st16"
                        />
                        <path
                            d="M498.3 179.7a20.1 20.1 0 01-27.6 18.7 19.7 19.7 0 0126.2-25.7c1 2.2 1.4 4.6 1.4 7z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_42_">
                        <path
                            d="M494.4 176a31 31 0 01-55.6 18.8h.5a41.1 41.1 0 0041.1-41.1l-.2-3.6c8.5 5.5 14.2 15 14.2 25.9z"
                            className="st15"
                        />
                        <path d="M480.4 153.7a41.1 41.1 0 01-41.1 41.1h-.5a31 31 0 0141.6-44.8v3.7z" className="st9" />
                    </g>
                </g>
                <g id="arbre_53_">
                    <path id="branches_25_" d="M502.8 253.2h10.7v26.9h-10.7z" className="st2" />
                    <g id="feuilles_45_">
                        <path
                            d="M525.5 251c.1 2.9-1.7 5.3-3.9 5.3h-27.7c-.7 0-1.3-.2-1.9-.7a59.8 59.8 0 0027.5-55.2 252 252 0 016 50.6z"
                            className="st3"
                        />
                        <path
                            d="M519.8 205.9a60 60 0 01-27.8 49.7 5.6 5.6 0 01-2-4.6c.6-28.4 5.6-55.4 14.2-79.7a4.2 4.2 0 011.9-2.5c1.8-1.1 4.2-.3 5.2 2.5a232.2 232.2 0 018.3 29.1l.2 5.5z"
                            className="st4"
                        />
                        <g id="fruits">
                            <circle id="fruit_10_" cx="503.2" cy="198.1" r="1.4" className="st5" />
                            <circle id="fruit_11_" cx="517.4" cy="227.9" r="1.4" className="st5" />
                            <circle id="fruit_9_" cx="512.9" cy="248.4" r="1.4" className="st5" />
                            <circle id="fruit_8_" cx="509.5" cy="213.4" r="1.4" className="st5" />
                            <circle id="fruit_7_" cx="508.7" cy="183.3" r="1.4" className="st5" />
                            <circle id="fruit_6_" cx="498.3" cy="237.4" r="1.4" className="st5" />
                        </g>
                    </g>
                </g>
                <g id="cloture">
                    <path d="M118 181h2.2v17.4H118z" className="st29" transform="rotate(6 119.1 189.7)" />
                    <path d="M105.3 179.6h2.2V197h-2.2z" className="st29" transform="rotate(6 106.4 188.4)" />
                    <path d="M130.7 182.3h2.2v17.4h-2.2z" className="st29" transform="rotate(6 131.8 191)" />
                    <path d="M92.6 178.3h2.2v17.4h-2.2z" className="st29" transform="rotate(6 93.7 187)" />
                    <path d="M125.2 173.9h2.2v17.4h-2.2z" className="st188" transform="rotate(-84 126.3 182.6)" />
                    <path d="M112.6 174.9h2.2v17.4h-2.2z" className="st188" transform="rotate(-84 113.7 183.6)" />
                    <path d="M99.7 171h2.2v17.4h-2.2z" className="st188" transform="rotate(-84 100.8 179.7)" />
                    <path d="M124.8 182.1h2.2v17.4h-2.2z" className="st188" transform="rotate(-84 125.9 190.8)" />
                    <path d="M111.5 183.1h2.2v17.4h-2.2z" className="st188" transform="rotate(-84 112.6 191.8)" />
                    <path
                        d="M98.8 179.5h2.2v17.4h-2.2z"
                        className="st188"
                        transform="scale(.99995) rotate(-85 100 188.3)"
                    />
                    <circle cx="94.6" cy="179" r=".2" className="st98" />
                    <circle cx="107.5" cy="180.3" r=".2" className="st98" />
                    <circle cx="107.1" cy="183" r=".2" className="st98" />
                    <circle cx="120.1" cy="182" r=".2" className="st98" />
                    <circle cx="119.9" cy="184.3" r=".2" className="st98" />
                    <circle cx="132.7" cy="183.3" r=".2" className="st98" />
                    <circle cx="131.8" cy="191.4" r=".2" className="st98" />
                    <circle cx="119.1" cy="190.1" r=".2" className="st98" />
                    <circle cx="119" cy="192.5" r=".2" className="st98" />
                    <circle cx="106.5" cy="188.8" r=".2" className="st98" />
                    <circle cx="106.1" cy="191.1" r=".2" className="st98" />
                    <circle cx="93.7" cy="187.7" r=".2" className="st98" />
                </g>
                <g id="buisson_8_">
                    <path
                        d="M1126.8 481.1c-.3 1-.4 2-.3 3l-11.6-1.3-4.1-.5-4.5-.5c1.3-.4 2.5-.9 3.6-1.7l.2.5.2.1a17 17 0 0021.3-11l.4-1.4c1.8 2.3 2.9 5.2 2.8 8.2a6.8 6.8 0 00-8 4.6z"
                        className="st19"
                    />
                    <path
                        d="M1131.5 469.6a16.9 16.9 0 01-21.3 11l-.2-.1-.2-.5a10 10 0 01-3.6 1.7l-6.5-.7a8.2 8.2 0 0110.6-9.9c2.6-5.9 9.3-9 15.5-7a12 12 0 015.9 4.1l-.2 1.4z"
                        className="st11"
                    />
                    <path
                        d="M1152.8 483.6a8 8 0 01-2 3.3l-11-1.2-1.6-.2-4.3-.5a9 9 0 003.1-2.7l.4 1.6h.1c.8.3 1.7.4 2.5.5a11 11 0 0011-7.5l.3-.9c1.8 2 2.4 4.9 1.5 7.6z"
                        className="st19"
                    />
                    <path
                        d="M1151.2 476.9a11 11 0 01-11 7.5c-.8-.1-1.7-.2-2.5-.5h-.1l-.4-1.6a10 10 0 01-3.1 2.7l-2.5-.3-5-.6c-.1-1 0-2 .3-3a6.9 6.9 0 017.9-4.7l.8.2c.9.3 1.6.7 2.3 1.3a8.2 8.2 0 019.9-4.5c1.5.5 2.8 1.4 3.8 2.6l-.4.9z"
                        className="st11"
                    />
                </g>
                <g id="chemin_de_pierre">
                    <path
                        id="pierre_14_"
                        d="M1103.8 484.3l-8.8-1c-.9-.1-1.6-1-1.5-1.9l.1-.6a2.8 2.8 0 013-2.4l6 .7c1.9.2 3.2 1.9 3 3.8-.1.8-.9 1.5-1.8 1.4z"
                        className="st110"
                    />
                    <path
                        id="pierre_13_"
                        d="M1116.1 485.8l-7.7-.9c-.4 0-.6-.4-.6-.7l.2-1.4a2.8 2.8 0 013.2-2.5l3.7.4c1.3.2 2.2 1.3 2.1 2.6l-.2 2.1c-.2.2-.4.4-.7.4z"
                        className="st96"
                    />
                    <path
                        id="pierre_12_"
                        d="M1123.7 487.2l-3.5-.4c-.9-.1-1.6-1-1.5-1.9l.1-.6a2.8 2.8 0 013-2.4l.7.1c1.9.2 3.2 1.9 3 3.8-.1.9-.9 1.5-1.8 1.4z"
                        className="st95"
                    />
                    <path
                        id="pierre_10_"
                        d="M1137.8 489l-9.7-1.1c-.7-.1-1.2-.7-1.1-1.4l.2-1.5a2.3 2.3 0 012.5-2l7.6.9c1.2.1 2.1 1.3 2 2.5l-.2 1.6c-.1.6-.7 1.1-1.3 1z"
                        className="st107"
                    />
                    <path
                        id="pierre_11_"
                        d="M1154.5 490.9l-11.2-1.3c-.9-.1-1.6-1-1.5-1.9l.1-.6a2.8 2.8 0 013-2.4l8.4 1c1.9.2 3.2 1.9 3 3.8-.1.8-.9 1.5-1.8 1.4z"
                        className="st113"
                    />
                    <path
                        id="pierre_9_"
                        d="M1160.1 497.4l-1-4.9c-.1-.3.2-.7.5-.7l2.7-.5c.9-.2 1.7.4 1.9 1.2l.4 2.1c.3 1.3-.6 2.5-1.8 2.8l-2.1.4a.7.7 0 01-.6-.4z"
                        className="st117"
                    />
                    <path
                        id="pierre_8_"
                        d="M1162.8 504.4l-1-4.2c-.2-.8.3-1.6 1.1-1.8l1.1-.3c1.3-.3 2.6.5 2.9 1.8l.4 1.5c.4 1.7-.6 3.4-2.3 3.8l-.5.1c-.8.2-1.5-.2-1.7-.9z"
                        className="st123"
                    />
                    <path
                        id="pierre_7_"
                        d="M1228.3 497.2l-3.2 6.9c-.2.4-.6.5-1 .4l-.3-.2c-1.9-.9-2.8-3.2-1.9-5.1l1.1-2.3c.6-1.2 2-1.8 3.2-1.2l1.8.8c.4.1.5.4.3.7z"
                        className="st137"
                    />
                    <path
                        id="pierre_6_"
                        d="M1242 497.6l-8.8-.5c-.9 0-1.7-.9-1.6-1.8v-.6a2.6 2.6 0 012.8-2.5l6 .3c1.9.1 3.3 1.7 3.2 3.6 0 .9-.8 1.6-1.6 1.5z"
                        className="st171"
                    />
                    <path
                        id="pierre_5_"
                        d="M1247.4 493.2l4.1.2c.9 0 1.7.9 1.6 1.8v.6a2.6 2.6 0 01-2.8 2.5l-1.3-.1a3.4 3.4 0 01-3.2-3.6c0-.8.8-1.5 1.6-1.4z"
                        className="st173"
                    />
                    <path
                        id="pierre_4_"
                        d="M1256.8 493.6l7.3.4c1.8.1 3.1 1.6 3 3.4v.2c0 .9-.8 1.6-1.7 1.5l-7.1-.4a3.4 3.4 0 01-3.2-3.6c.1-.8.8-1.5 1.7-1.5z"
                        className="st115"
                    />
                    <path
                        id="pierre_3_"
                        d="M1281.1 494.9l5.4.3c2.4.1 4.3 2.2 4.1 4.6v.6l-11.3-.6c-.9 0-1.7-.8-1.6-1.8 0-1.8 1.6-3.2 3.4-3.1z"
                        className="st171"
                    />
                    <path
                        id="pierre_2_"
                        d="M1296.8 495.4l3.8.2c.9 0 1.6.8 1.6 1.7l-.1 2.7c0 .3-.3.6-.7.6l-5.1-.3c-1.3-.1-2.3-1.2-2.2-2.4-.1-1.4 1.2-2.5 2.7-2.5z"
                        className="st173"
                    />
                    <path
                        id="pierre_1_"
                        d="M1272.8 494.4h.3c1.8.1 3.1 1.6 3 3.4v.2c0 .9-.8 1.6-1.7 1.5l-2.9-.1a2 2 0 01-1.9-2.1c.2-1.7 1.6-3 3.2-2.9z"
                        className="st136"
                    />
                </g>
                <g id="arbre_2_">
                    <g id="branches_26_">
                        <path d="M874.4 362.4h10.4v71.5h-10.4z" className="st6" />
                        <path d="M887.3 383.2h3.3v22.4h-3.3z" className="st6" transform="rotate(52.4 889 394.5)" />
                        <path d="M885.7 354.2h2.6v32.3h-2.6z" className="st6" transform="rotate(30 887 370.3)" />
                        <path d="M868.3 373.9h3.7v21.3h-3.7z" className="st6" transform="rotate(-37.6 870.1 384.5)" />
                        <path d="M870.6 403h3.7v13.4h-3.7z" className="st6" transform="rotate(-60 872.5 409.7)" />
                    </g>
                    <g id="feuilles_48_">
                        <path d="M910 336.1a31.6 31.6 0 01-60.7 12.3 31.6 31.6 0 1160.7-12.3z" className="st15" />
                        <path
                            d="M893.3 318.5a32.1 32.1 0 01-44 29.8 31.6 31.6 0 0142-41.1c1.3 3.6 2 7.4 2 11.3z"
                            className="st9"
                        />
                    </g>
                    <g id="feuilles_47_">
                        <path
                            d="M872.6 367.7a17 17 0 01-30.7 10.4h.3a22.7 22.7 0 0022.7-22.7l-.1-2a17 17 0 017.8 14.3z"
                            className="st15"
                        />
                        <path d="M864.9 355.4a22.7 22.7 0 01-22.7 22.7h-.3a17 17 0 0122.9-24.7l.1 2z" className="st9" />
                    </g>
                    <g id="feuilles_46_">
                        <path
                            d="M920.9 379a15.9 15.9 0 01-28.5 9.6h.3c11.7 0 21.1-9.5 21.1-21.1l-.1-1.9c4.3 2.9 7.2 7.8 7.2 13.4z"
                            className="st15"
                        />
                        <path
                            d="M913.7 367.5c0 11.7-9.5 21.1-21.1 21.1h-.3a15.9 15.9 0 0121.3-22.9l.1 1.8z"
                            className="st9"
                        />
                    </g>
                </g>
                <g id="arbre">
                    <g id="branches_27_">
                        <path d="M1070.5 420.2h8.9v60.9h-8.9z" className="st6" />
                        <path d="M1083.6 417.6h5.9V447h-5.9z" className="st6" transform="rotate(52.4 1086.5 432.4)" />
                        <path d="M1045.2 417.1h4.5v28.7h-4.5z" className="st6" transform="rotate(-48.4 1047.5 431.5)" />
                        <path d="M1058.1 412.7h3.2v18.1h-3.2z" className="st6" transform="rotate(27.7 1059.6 421.7)" />
                        <path d="M1058.3 415.5h5.7v66.6h-5.7z" className="st6" transform="rotate(-17.3 1061 448.7)" />
                        <path d="M1056.5 441.6h1.8v11.7h-1.8z" className="st6" transform="rotate(-57.3 1057.4 447.4)" />
                        <path d="M1088.4 414.5h3.2v18.1h-3.2z" className="st6" transform="rotate(27.7 1090 423.5)" />
                    </g>
                    <g id="feuilles_52_">
                        <path d="M1071.6 402.3a18.5 18.5 0 01-35.5 7.2 18.5 18.5 0 1135.5-7.2z" className="st7" />
                        <path
                            d="M1061.9 392a18.8 18.8 0 01-25.8 17.5 18.5 18.5 0 0124.6-24.1c.8 2.1 1.2 4.3 1.2 6.6z"
                            className="st11"
                        />
                    </g>
                    <g id="feuilles_51_">
                        <path d="M1043.8 417.6a12.4 12.4 0 01-23.8 4.9 12.4 12.4 0 1123.8-4.9z" className="st7" />
                        <path
                            d="M1037.3 410.7a12.6 12.6 0 01-17.3 11.7 12.4 12.4 0 0116.4-16.1c.6 1.4.9 2.9.9 4.4z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_50_">
                        <path
                            d="M1105.4 392.8a30.5 30.5 0 01-55.5 17.5 46.2 46.2 0 0039.7-44.1 30.2 30.2 0 0115.8 26.6z"
                            className="st18"
                        />
                        <path d="M1089.6 366.1a46.5 46.5 0 01-39.7 44.1 30.4 30.4 0 0139.7-44.1z" className="st12" />
                    </g>
                    <g id="feuilles_49_">
                        <path
                            d="M1128.9 410.8a19.4 19.4 0 01-34.8 11.8h.3a25.7 25.7 0 0025.7-25.7l-.1-2.3c5.4 3.5 8.9 9.4 8.9 16.2z"
                            className="st18"
                        />
                        <path
                            d="M1120.2 396.9a25.7 25.7 0 01-25.7 25.7h-.3a19.4 19.4 0 0125.9-28l.1 2.3z"
                            className="st14"
                        />
                    </g>
                </g>
                <g id="buisson_15_">
                    <path
                        d="M218.2 205a10 10 0 01-11.5 8.3c-3.2-.5-5.8-2.5-7.3-5.2h.2a13.3 13.3 0 0015.3-11.1l.1-1.2c2.4 2.4 3.7 5.7 3.2 9.2z"
                        className="st11"
                    />
                    <path
                        d="M214.9 197.2c-1.2 7.3-8 12.2-15.3 11.1h-.2a10 10 0 0110.4-14.6c2 .3 3.8 1.2 5.2 2.5l-.1 1z"
                        className="st11"
                    />
                    <path
                        d="M235.1 204.1a13.7 13.7 0 01-15.8 11.3c-4.4-.7-8-3.4-10-7.1h.2c10 1.6 19.4-5.1 21-15.1l.2-1.6c3.4 3.1 5.2 7.7 4.4 12.5z"
                        className="st19"
                    />
                    <path
                        d="M230.6 193.3a18.3 18.3 0 01-21 15.1h-.2a13.6 13.6 0 0114.3-20c2.7.4 5.2 1.7 7.1 3.4l-.2 1.5z"
                        className="st11"
                    />
                    <g>
                        <path
                            d="M246.7 208.8a11.8 11.8 0 01-22 3.7h.2a15.6 15.6 0 0017.9-12.9l.2-1.4c2.8 2.6 4.4 6.5 3.7 10.6z"
                            className="st19"
                        />
                        <path
                            d="M242.8 199.6a15.6 15.6 0 01-17.9 12.9h-.2c-1.2-2.2-1.7-4.8-1.2-7.4a11.7 11.7 0 0119.4-6.8c.1.3 0 .8-.1 1.3z"
                            className="st11"
                        />
                    </g>
                </g>
                <g id="arbre_5_">
                    <g id="branches_2_">
                        <path d="M793.2 338.6h9.6v55.3h-9.6z" className="st6" />
                        <path d="M803.4 360.7h3v15.4h-3z" className="st6" transform="rotate(-135 804.9 368.4)" />
                        <path d="M807.4 320h4.3v34h-4.3z" className="st6" transform="rotate(34.3 809.5 337)" />
                        <path
                            d="M807.6 321.3h3.8v17h-3.8z"
                            className="st6"
                            transform="rotate(4.8 809.5 329.8) scale(.99996)"
                        />
                        <path
                            d="M809 335h2.6v11.7H809z"
                            className="st6"
                            transform="scale(-1) rotate(55.1 653.6 -1553.8)"
                        />
                        <path d="M783.7 331.3h6.3v36.2h-6.3z" className="st6" transform="rotate(-33.2 786.9 349.4)" />
                        <path d="M776.7 339.3h3.8v17h-3.8z" className="st6" transform="rotate(-69.5 778.6 347.8)" />
                    </g>
                    <g id="feuilles_58_">
                        <path
                            d="M785.6 323.1a14.5 14.5 0 01-26 8.8h.2c10.6 0 19.3-8.6 19.3-19.3l-.1-1.7c3.9 2.7 6.6 7.1 6.6 12.2z"
                            className="st15"
                        />
                        <path
                            d="M779 312.7c0 10.6-8.6 19.3-19.3 19.3h-.2a14.5 14.5 0 0119.4-21l.1 1.7z"
                            className="st9"
                        />
                    </g>
                    <g id="feuilles_57_">
                        <path d="M841.7 320.3a15.2 15.2 0 01-29.2 5.9 15.2 15.2 0 1129.2-5.9z" className="st16" />
                        <path
                            d="M833.7 311.9a15.4 15.4 0 01-21.1 14.3 15.2 15.2 0 0120.2-19.8c.6 1.7.9 3.5.9 5.5z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_56_">
                        <path
                            d="M830.5 308.6a32.6 32.6 0 01-59.3 18.7 49.6 49.6 0 0042.5-47.1 32.4 32.4 0 0116.8 28.4z"
                            className="st17"
                        />
                        <path d="M813.7 280.1a49.4 49.4 0 01-42.5 47.1 32.5 32.5 0 0142.5-47.1z" className="st14" />
                    </g>
                    <g id="feuilles_55_">
                        <path
                            d="M819.4 360.5a6.7 6.7 0 01-12 4.1h.1a9 9 0 008.9-8.9v-.8c1.8 1.2 3 3.3 3 5.6z"
                            className="st17"
                        />
                        <path
                            d="M816.4 355.7a9 9 0 01-8.9 8.9h-.1a6.3 6.3 0 01-1.4-4c0-3.7 3-6.7 6.7-6.7 1.3 0 2.6.4 3.6 1.1l.1.7z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_54_">
                        <path
                            d="M826.5 335.2a7.5 7.5 0 01-13.4 4.6h.1c5.5 0 9.9-4.4 9.9-9.9v-.9a7 7 0 013.4 6.2z"
                            className="st15"
                        />
                        <path
                            d="M823.2 329.8c0 5.5-4.4 9.9-9.9 9.9h-.1a7.5 7.5 0 0110.1-10.8l-.1.9z"
                            className="st14"
                        />
                    </g>
                    <g id="feuilles_53_">
                        <path
                            d="M774.4 342.1a8 8 0 01-14.6 4.6c5.8-.8 10.2-5.7 10.4-11.6a8 8 0 014.2 7z"
                            className="st17"
                        />
                        <path
                            d="M770.3 335.1a12.2 12.2 0 01-10.4 11.6 8 8 0 016.6-12.6c1.3 0 2.6.4 3.8 1z"
                            className="st14"
                        />
                    </g>
                </g>
                <g id="arbre_6_">
                    <g id="branches_1_">
                        <path d="M1472.6 431.9h11.6v66.5h-11.6z" className="st6" />
                        <path d="M1463 438.3h7v31.3h-7z" className="st6" transform="rotate(-45 1466.5 454)" />
                        <path d="M1486.6 463.7h4.8v21.5h-4.8z" className="st6" transform="rotate(-135 1489 474.5)" />
                    </g>
                    <g id="feuilles_64_">
                        <path
                            d="M1504 413.8a28 28 0 01-53.8 10.9 28 28 0 0125.8-38.9 28 28 0 0128 28z"
                            className="st11"
                        />
                        <path
                            d="M1489.3 398.3a28.6 28.6 0 01-39.1 26.5 28 28 0 0125.8-38.9c4.1 0 7.9.9 11.4 2.4 1.3 3.1 1.9 6.5 1.9 10z"
                            className="st12"
                        />
                    </g>
                    <g id="feuilles_63_">
                        <path
                            d="M1467.4 436.4a17.4 17.4 0 01-31.3 10.5h.3a23.2 23.2 0 0023.2-23.2l-.1-2c4.8 3.2 7.9 8.6 7.9 14.7z"
                            className="st7"
                        />
                        <path
                            d="M1459.6 423.8a23.2 23.2 0 01-23.2 23.2h-.3a17.4 17.4 0 0123.3-25.2l.2 2z"
                            className="st8"
                        />
                    </g>
                    <g id="feuilles_62_">
                        <path
                            d="M1512.4 462.5a12 12 0 01-21.9 6.9 18.4 18.4 0 0015.7-17.4c3.7 2 6.2 5.9 6.2 10.5z"
                            className="st18"
                        />
                        <path
                            d="M1506.2 452c-.3 8.9-7 16.1-15.7 17.4a11.5 11.5 0 01-2.1-6.9 12 12 0 0117.8-10.5z"
                            className="st12"
                        />
                    </g>
                </g>
                <g id="arbre_52_">
                    <g id="branches_29_">
                        <path d="M235.9 178.2h8.8v37.6h-8.8z" className="st6" />
                        <path d="M245 192.1h2.2v11.7H245z" className="st6" transform="rotate(37 246.1 198)" />
                    </g>
                    <g id="feuilles_65_">
                        <path
                            d="M260.1 179.1l-.5.6-1.1 1.2-.6.6-.9.8-.9.8-1.2.9a29 29 0 01-8.3 4.4l-1.1.3-.8.2-1.1.2-1.6.2h-2.7l-.6-.1-.6-.1-1.7-.4-.5-.1-.9-.3-1-.3-2.6-1-.5-.3-2.1-1.1-.9-.5-.3-.2-.5-.3-1.2-.9-.5-.4-.8-.6-.8-.7-.3-.3-.1-.1a30 30 0 0011.3-10l1.5-.2c6.1-1 11.7-3.9 16-8a31.3 31.3 0 008.7-15l1.4 1c7.3 6.3 6.3 19.6-2.2 29.7z"
                            className="st19"
                        />
                        <path
                            d="M260.9 148.5a32.3 32.3 0 01-8.7 15c-4.3 4.2-9.9 7-16 8l-1.5.2c-2.1 3-4.7 5.7-7.7 7.8-1.2.8-2.4 1.6-3.6 2.2l-.5-.4-.8-.8-.9-.9a31.4 31.4 0 01-7.7-15.8c-.7-5.5.7-10.5 4.3-13.8a7 7 0 011-.8l.4-.3.2-.1.3-.2.3-.2.4-.2.2-.1.4-.2.6-.2.7-.3.7-.2.1-.9v-.1l.1-.8.1-.3.1-.7.1-.2.2-.8.3-.9.3-.9.7-1.8.2-.5.6-1.2.2-.4.3-.5.3-.5.1-.2.4-.6.1-.2.3-.5.3-.4.4-.6.4-.5.5-.6.3-.3.6-.5.5-.5.6-.5.1-.1.4-.3.4-.3.6-.4.1-.1.5-.3.8-.4.6-.3.8-.3.7-.2h.2l.7-.2.7-.2h.1l.7-.1.7-.1H241l1.5.2h.1l.6.1.2.1.6.2h.1l.7.2.5.2h.2l.3.1.2.1.3.1.7.4.8.5.5.3.1 2.3c0 5.6-1.4 10.9-3.9 15.4 4.4-1.9 9-2.3 12.9-1.1l.6.2.6.2.5.2.4.2.4.2.4.2.6.2z"
                            className="st11"
                        />
                        <path
                            d="M257.6 147a19 19 0 00-12.9 1.1 32 32 0 003.9-15.4l-.1-2.3 1.9 1.5.5.5.1.1.3.3.3.3.3.3.7.9.4.5.3.4.1.2.4.6.1.2.3.6.4.8.2.5.2.5a46.8 46.8 0 011.6 4.7l.1.6.1.4.1.6.1.4.1.8c.4.2.5.5.5.9z"
                            className="st19"
                        />
                        <g id="fruits_9_">
                            <circle cx="243.5" cy="138.7" r="1.3" className="st189" />
                            <circle cx="258.4" cy="167.6" r="1.6" className="st190" />
                            <circle cx="231.9" cy="174.4" r="1.6" className="st190" />
                            <circle cx="249.5" cy="169.9" r="2.1" className="st191" />
                            <circle cx="237.7" cy="157" r=".8" className="st192" />
                            <circle cx="249.5" cy="158.9" r="1.3" className="st191" />
                            <circle cx="232.4" cy="143.4" r="1.5" className="st189" />
                            <circle cx="225" cy="162.3" r="1.5" className="st192" />
                        </g>
                    </g>
                </g>
                <g id="fleur_21_">
                    <ellipse
                        id="feuille_44_"
                        cx="450.7"
                        cy="250.1"
                        className="st11"
                        rx=".8"
                        ry="2.6"
                        transform="rotate(-17.3 450.7 250)"
                    />
                    <path id="tige_21_" d="M451.4 248.7h.3v5.3h-.3z" className="st7" />
                    <circle id="petales_43_" cx="451.5" cy="248.2" r="1.3" className="st193" />
                    <path id="petales_42_" d="M450.2 246h2.6v2.2h-2.6z" className="st193" />
                    <ellipse
                        id="feuille_43_"
                        cx="452.9"
                        cy="248.9"
                        className="st11"
                        rx="3.1"
                        ry=".6"
                        transform="rotate(-63 452.9 249)"
                    />
                </g>
                <g id="fleur_23_">
                    <path
                        id="tige_23_"
                        d="M1316.6 491.1h.4v6.5h-.4z"
                        className="st7"
                        transform="rotate(-180 1316.8 494.4)"
                    />
                    <circle id="petales_47_" cx="1316.8" cy="490.9" r="1.5" className="st193" />
                    <path
                        id="petales_46_"
                        d="M1315.3 488.4h3v2.6h-3z"
                        className="st193"
                        transform="rotate(-180 1316.8 489.6)"
                    />
                    <ellipse
                        id="feuille_48_"
                        cx="1315.5"
                        cy="492.1"
                        className="st11"
                        rx=".9"
                        ry="3.1"
                        transform="rotate(-24.1 1315.6 492.2)"
                    />
                    <ellipse
                        id="feuille_47_"
                        cx="1318.2"
                        cy="493.5"
                        className="st11"
                        rx="3"
                        ry=".8"
                        transform="rotate(-61.5 1318.2 493.4)"
                    />
                </g>
                <g id="fleur_22_">
                    <path id="tige_22_" d="M781.6 374.4h.3v5.9h-.3z" className="st7" />
                    <circle id="petales_45_" cx="781.7" cy="373.6" r="1.1" className="st193" />
                    <path id="petales_44_" d="M780.6 371.7h2.2v1.9h-2.2z" className="st193" />
                    <ellipse
                        id="feuille_46_"
                        cx="783.2"
                        cy="375.2"
                        className="st11"
                        rx="2.5"
                        ry=".6"
                        transform="rotate(-53.5 783.2 375.1)"
                    />
                    <ellipse
                        id="feuille_45_"
                        cx="780.4"
                        cy="376.3"
                        className="st11"
                        rx=".7"
                        ry="3"
                        transform="rotate(-25.2 780.4 376.3)"
                    />
                </g>
                <g id="fleur_20_">
                    <path
                        id="tige_20_"
                        d="M531.8 273.6h.3v5.3h-.3z"
                        className="st7"
                        transform="rotate(-180 532 276.3)"
                    />
                    <circle id="petales_41_" cx="532" cy="273.5" r="1.2" className="st141" />
                    <path
                        id="petales_40_"
                        d="M530.7 271.4h2.5v2.1h-2.5z"
                        className="st141"
                        transform="rotate(-180 532 272.4)"
                    />
                    <ellipse
                        id="feuille_42_"
                        cx="530.9"
                        cy="274.5"
                        className="st19"
                        rx=".7"
                        ry="2.5"
                        transform="rotate(-24.1 531 274.5)"
                    />
                    <ellipse
                        id="feuille_41_"
                        cx="533.1"
                        cy="275.5"
                        className="st19"
                        rx="2.5"
                        ry=".7"
                        transform="rotate(-61.5 533.2 275.5)"
                    />
                </g>
                <g id="fleur_19_">
                    <path id="tige_19_" d="M874.7 420.1h.2v5.1h-.2z" className="st7" />
                    <circle id="petales_39_" cx="874.8" cy="419.4" r="1" className="st141" />
                    <path id="petales_38_" d="M873.8 417.8h1.9v1.6h-1.9z" className="st141" />
                    <ellipse
                        id="feuille_40_"
                        cx="876.1"
                        cy="420.7"
                        className="st19"
                        rx="2.1"
                        ry=".5"
                        transform="rotate(-53.5 876 420.7)"
                    />
                    <ellipse
                        id="feuille_39_"
                        cx="873.7"
                        cy="421.7"
                        className="st19"
                        rx=".6"
                        ry="2.6"
                        transform="rotate(-25.2 873.6 421.7)"
                    />
                </g>
                <g id="fleur_18_">
                    <ellipse
                        id="feuille_38_"
                        cx="1447.1"
                        cy="492.9"
                        className="st11"
                        rx=".9"
                        ry="2.8"
                        transform="rotate(-17.3 1447.3 492.9)"
                    />
                    <path id="tige_18_" d="M1447.9 491.4h.3v5.8h-.3z" className="st7" />
                    <circle id="petales_37_" cx="1448" cy="490.8" r="1.4" className="st141" />
                    <path id="petales_36_" d="M1446.6 488.4h2.8v2.4h-2.8z" className="st141" />
                    <ellipse
                        id="feuille_37_"
                        cx="1449.5"
                        cy="491.6"
                        className="st11"
                        rx="3.3"
                        ry=".7"
                        transform="rotate(-63 1449.5 491.6)"
                    />
                </g>
                <g id="fleur_17_">
                    <path
                        id="tige_17_"
                        d="M1313.6 492.5h.3v5.5h-.3z"
                        className="st7"
                        transform="rotate(-180 1313.8 495.2)"
                    />
                    <circle id="petales_35_" cx="1313.8" cy="492.3" r="1.3" className="st194" />
                    <path
                        id="petales_34_"
                        d="M1312.5 490.2h2.5v2.2h-2.5z"
                        className="st194"
                        transform="rotate(-180 1313.8 491.2)"
                    />
                    <ellipse
                        id="feuille_36_"
                        cx="1312.7"
                        cy="493.3"
                        className="st19"
                        rx=".7"
                        ry="2.6"
                        transform="rotate(-24.1 1312.8 493.4)"
                    />
                    <ellipse
                        id="feuille_35_"
                        cx="1315"
                        cy="494.4"
                        className="st19"
                        rx="2.5"
                        ry=".7"
                        transform="rotate(-61.5 1315 494.4)"
                    />
                </g>
                <g id="fleur_16_">
                    <path id="tige_16_" d="M1308.7 491.4h.3v6.3h-.3z" className="st7" />
                    <circle id="petales_33_" cx="1308.9" cy="490.6" r="1.2" className="st194" />
                    <path id="petales_32_" d="M1307.7 488.6h2.4v2h-2.4z" className="st194" />
                    <ellipse
                        id="feuille_34_"
                        cx="1310.5"
                        cy="492.3"
                        className="st11"
                        rx="2.6"
                        ry=".6"
                        transform="rotate(-53.5 1310.5 492.3)"
                    />
                    <ellipse
                        id="feuille_33_"
                        cx="1307.5"
                        cy="493.5"
                        className="st11"
                        rx=".7"
                        ry="3.3"
                        transform="rotate(-25.2 1307.5 493.5)"
                    />
                </g>
                <g id="fleur_15_">
                    <ellipse
                        id="feuille_32_"
                        cx="1239.3"
                        cy="491.8"
                        className="st19"
                        rx=".5"
                        ry="1.7"
                        transform="rotate(-17.3 1239.4 491.8)"
                    />
                    <path id="tige_15_" d="M1239.7 490.8h.2v3.5h-.2z" className="st7" />
                    <circle id="petales_31_" cx="1239.8" cy="490.5" r=".9" className="st194" />
                    <path id="petales_30_" d="M1239 489h1.7v1.5h-1.7z" className="st194" />
                    <ellipse
                        id="feuille_31_"
                        cx="1240.7"
                        cy="491"
                        className="st19"
                        rx="2"
                        ry=".4"
                        transform="rotate(-63 1240.7 491)"
                    />
                </g>
                <g id="fleur_14_">
                    <path
                        id="tige_14_"
                        d="M1157.2 482.8h.3v4.8h-.3z"
                        className="st7"
                        transform="rotate(-180 1157.3 485.2)"
                    />
                    <circle id="petales_29_" cx="1157.3" cy="482.7" r="1.1" className="st33" />
                    <path
                        id="petales_28_"
                        d="M1156.2 480.8h2.2v1.9h-2.2z"
                        className="st33"
                        transform="rotate(-180 1157.3 481.7)"
                    />
                    <ellipse
                        id="feuille_30_"
                        cx="1156.4"
                        cy="483.6"
                        className="st19"
                        rx=".6"
                        ry="2.2"
                        transform="rotate(-24.1 1156.4 483.6)"
                    />
                    <ellipse
                        id="feuille_29_"
                        cx="1158.4"
                        cy="484.5"
                        className="st19"
                        rx="2.2"
                        ry=".6"
                        transform="rotate(-61.5 1158.4 484.5)"
                    />
                </g>
                <g id="fleur_13_">
                    <path id="tige_13_" d="M880.7 421.3h.3v5.9h-.3z" className="st7" />
                    <circle id="petales_27_" cx="880.9" cy="420.5" r="1.1" className="st33" />
                    <path id="petales_26_" d="M879.8 418.6h2.2v1.9h-2.2z" className="st33" />
                    <ellipse
                        id="feuille_26_"
                        cx="882.4"
                        cy="422.1"
                        className="st11"
                        rx="2.5"
                        ry=".6"
                        transform="rotate(-53.5 882.4 422)"
                    />
                    <ellipse
                        id="feuille_25_"
                        cx="879.6"
                        cy="423.2"
                        className="st11"
                        rx=".7"
                        ry="3"
                        transform="rotate(-25.2 879.5 423.2)"
                    />
                </g>
                <g id="fleur_12_">
                    <ellipse
                        id="feuille_24_"
                        cx="821.4"
                        cy="395.8"
                        className="st19"
                        rx=".8"
                        ry="2.7"
                        transform="rotate(-17.3 821.5 395.8)"
                    />
                    <path id="tige_12_" d="M822.1 394.3h.3v5.6h-.3z" className="st7" />
                    <circle id="petales_25_" cx="822.3" cy="393.8" r="1.4" className="st33" />
                    <path id="petales_24_" d="M820.9 391.5h2.7v2.3h-2.7z" className="st33" />
                    <ellipse
                        id="feuille_23_"
                        cx="823.8"
                        cy="394.6"
                        className="st19"
                        rx="3.2"
                        ry=".7"
                        transform="rotate(-63 823.7 394.6)"
                    />
                </g>
                <g id="fleur_26_">
                    <path
                        id="tige_26_"
                        d="M733.4 351.9h.3v5.7h-.3z"
                        className="st7"
                        transform="rotate(-180 733.6 354.8)"
                    />
                    <circle id="petales_53_" cx="733.5" cy="351.8" r="1.3" className="st33" />
                    <path
                        id="petales_52_"
                        d="M732.2 349.5h2.6v2.3h-2.6z"
                        className="st33"
                        transform="rotate(-180 733.5 350.6)"
                    />
                    <ellipse
                        id="feuille_54_"
                        cx="732.4"
                        cy="352.8"
                        className="st19"
                        rx=".8"
                        ry="2.7"
                        transform="rotate(-24.1 732.5 352.9)"
                    />
                    <ellipse
                        id="feuille_53_"
                        cx="734.8"
                        cy="354"
                        className="st19"
                        rx="2.6"
                        ry=".7"
                        transform="rotate(-61.5 734.8 354)"
                    />
                </g>
                <g id="fleur_24_">
                    <ellipse
                        id="feuille_50_"
                        cx="247"
                        cy="208.3"
                        className="st11"
                        rx=".8"
                        ry="2.6"
                        transform="rotate(-17.3 247 208.3)"
                    />
                    <path id="tige_24_" d="M247.6 207h.3v5.3h-.3z" className="st7" />
                    <circle id="petales_49_" cx="247.8" cy="206.5" r="1.3" className="st33" />
                    <path id="petales_48_" d="M246.5 204.3h2.5v2.2h-2.5z" className="st33" />
                    <ellipse
                        id="feuille_49_"
                        cx="249.1"
                        cy="207.2"
                        className="st11"
                        rx="3"
                        ry=".6"
                        transform="rotate(-63 249.1 207.2)"
                    />
                </g>
                <g id="fleur_29_">
                    <path
                        id="tige_29_"
                        d="M1615.4 478.9h.2v3.3h-.2z"
                        className="st7"
                        transform="rotate(-180 1615.5 480.5)"
                    />
                    <circle id="petales_59_" cx="1615.5" cy="478.8" r=".8" className="st33" />
                    <path
                        id="petales_58_"
                        d="M1614.7 477.5h1.5v1.3h-1.5z"
                        className="st33"
                        transform="rotate(-180 1615.5 478.1)"
                    />
                    <ellipse
                        id="feuille_60_"
                        cx="1614.8"
                        cy="479.4"
                        className="st11"
                        rx=".4"
                        ry="1.5"
                        transform="rotate(-24.1 1615 479.4)"
                    />
                    <ellipse
                        id="feuille_59_"
                        cx="1616.2"
                        cy="480"
                        className="st11"
                        rx="1.5"
                        ry=".4"
                        transform="rotate(-61.5 1616.2 480)"
                    />
                </g>
                <g id="fleur_28_">
                    <path id="tige_28_" d="M1106.4 475.6h.3v6.3h-.3z" className="st7" />
                    <circle id="petales_57_" cx="1106.5" cy="474.7" r="1.2" className="st33" />
                    <path id="petales_56_" d="M1105.3 472.7h2.4v2h-2.4z" className="st33" />
                    <ellipse
                        id="feuille_58_"
                        cx="1108.1"
                        cy="476.4"
                        className="st18"
                        rx="2.6"
                        ry=".6"
                        transform="rotate(-53.5 1108 476.4)"
                    />
                    <ellipse
                        id="feuille_57_"
                        cx="1105.1"
                        cy="477.6"
                        className="st18"
                        rx=".7"
                        ry="3.3"
                        transform="rotate(-25.2 1105 477.6)"
                    />
                </g>
                <g id="fleur_27_">
                    <ellipse
                        id="feuille_56_"
                        cx="119.7"
                        cy="194.7"
                        className="st19"
                        rx="1.9"
                        ry=".6"
                        transform="rotate(-72.7 119.6 194.7)"
                    />
                    <path
                        id="tige_27_"
                        d="M118.9 193.7h.2v3.9h-.2z"
                        className="st7"
                        transform="rotate(-180 119 195.6)"
                    />
                    <circle id="petales_55_" cx="119" cy="193.3" r=".9" className="st33" />
                    <path
                        id="petales_54_"
                        d="M118.1 191.7h1.9v1.6h-1.9z"
                        className="st33"
                        transform="rotate(-180 119 192.5)"
                    />
                    <ellipse
                        id="feuille_55_"
                        cx="118"
                        cy="193.9"
                        className="st19"
                        rx=".5"
                        ry="2.3"
                        transform="rotate(-27 118 193.9)"
                    />
                </g>
                <g id="fleur_32_">
                    <path
                        id="tige_32_"
                        d="M573.1 288.1h.2v4h-.2z"
                        className="st7"
                        transform="rotate(-180 573.2 290.2)"
                    />
                    <circle id="petales_65_" cx="573.2" cy="288" r=".9" className="st33" />
                    <path
                        id="petales_64_"
                        d="M572.3 286.4h1.9v1.6h-1.9z"
                        className="st33"
                        transform="rotate(-180 573.2 287.2)"
                    />
                    <ellipse
                        id="feuille_66_"
                        cx="572.4"
                        cy="288.8"
                        className="st19"
                        rx=".5"
                        ry="1.9"
                        transform="rotate(-24.1 572.5 288.8)"
                    />
                    <ellipse
                        id="feuille_65_"
                        cx="574.1"
                        cy="289.6"
                        className="st19"
                        rx="1.8"
                        ry=".5"
                        transform="rotate(-61.5 574.1 289.6)"
                    />
                </g>
                <g id="fleur_31_">
                    <path id="tige_31_" d="M1542.8 486.6h.2v4.3h-.2z" className="st7" />
                    <circle id="petales_63_" cx="1542.9" cy="486" r=".8" className="st33" />
                    <path id="petales_62_" d="M1542.1 484.6h1.6v1.4h-1.6z" className="st33" />
                    <ellipse
                        id="feuille_64_"
                        cx="1544"
                        cy="487.2"
                        className="st19"
                        rx="1.8"
                        ry=".4"
                        transform="rotate(-53.5 1544 487.1)"
                    />
                    <ellipse
                        id="feuille_63_"
                        cx="1542"
                        cy="488"
                        className="st19"
                        rx=".5"
                        ry="2.2"
                        transform="rotate(-25.2 1542 488)"
                    />
                </g>
                <g id="fleur_35_">
                    <path
                        id="tige_35_"
                        d="M1029.8 462.9h.4v6.5h-.4z"
                        className="st7"
                        transform="rotate(-180 1030 466.1)"
                    />
                    <circle id="petales_71_" cx="1030" cy="462.7" r="1.5" className="st193" />
                    <path
                        id="petales_70_"
                        d="M1028.5 460.1h3v2.6h-3z"
                        className="st193"
                        transform="rotate(-180 1030 461.4)"
                    />
                    <ellipse
                        id="feuille_72_"
                        cx="1028.7"
                        cy="463.8"
                        className="st11"
                        rx=".9"
                        ry="3.1"
                        transform="rotate(-24.1 1028.8 463.9)"
                    />
                    <ellipse
                        id="feuille_71_"
                        cx="1031.5"
                        cy="465.2"
                        className="st11"
                        rx="3"
                        ry=".8"
                        transform="rotate(-61.5 1031.5 465.2)"
                    />
                </g>
                <g id="fleur_34_">
                    <path
                        id="tige_34_"
                        d="M1000.1 457.6h.3v5.5h-.3z"
                        className="st7"
                        transform="rotate(-180 1000.2 460.4)"
                    />
                    <circle id="petales_69_" cx="1000.2" cy="457.5" r="1.3" className="st194" />
                    <path
                        id="petales_68_"
                        d="M999 455.3h2.5v2.2H999z"
                        className="st194"
                        transform="rotate(-180 1000.2 456.4)"
                    />
                    <ellipse
                        id="feuille_70_"
                        cx="999.2"
                        cy="458.5"
                        className="st19"
                        rx=".7"
                        ry="2.6"
                        transform="rotate(-24.1 999.2 458.5)"
                    />
                    <ellipse
                        id="feuille_69_"
                        cx="1001.4"
                        cy="459.6"
                        className="st19"
                        rx="2.5"
                        ry=".7"
                        transform="rotate(-61.5 1001.4 459.5)"
                    />
                </g>
                <g id="fleur_33_">
                    <path id="tige_33_" d="M996.7 456.2h.3v6.3h-.3z" className="st7" />
                    <circle id="petales_67_" cx="996.9" cy="455.4" r="1.2" className="st194" />
                    <path id="petales_66_" d="M995.7 453.4h2.4v2h-2.4z" className="st194" />
                    <ellipse
                        id="feuille_68_"
                        cx="998.5"
                        cy="457.1"
                        className="st11"
                        rx="2.6"
                        ry=".6"
                        transform="rotate(-53.5 998.5 457)"
                    />
                    <ellipse
                        id="feuille_67_"
                        cx="995.5"
                        cy="458.3"
                        className="st11"
                        rx=".7"
                        ry="3.3"
                        transform="rotate(-25.2 995.4 458.3)"
                    />
                </g>
                <g id="fleur_36_">
                    <path id="tige_36_" d="M1034.6 463.9h.3v6.3h-.3z" className="st7" />
                    <circle id="petales_73_" cx="1034.7" cy="463.1" r="1.2" className="st33" />
                    <path id="petales_72_" d="M1033.5 461h2.4v2h-2.4z" className="st33" />
                    <ellipse
                        id="feuille_74_"
                        cx="1036.3"
                        cy="464.7"
                        className="st18"
                        rx="2.6"
                        ry=".6"
                        transform="rotate(-53.5 1036.3 464.7)"
                    />
                    <ellipse
                        id="feuille_73_"
                        cx="1033.3"
                        cy="465.9"
                        className="st18"
                        rx=".7"
                        ry="3.3"
                        transform="rotate(-25.2 1033.3 466)"
                    />
                </g>
                <g id="fleur_38_">
                    <path id="tige_38_" d="M979.4 453.2h.2v5.1h-.2z" className="st7" />
                    <circle id="petales_77_" cx="979.5" cy="452.5" r="1" className="st141" />
                    <path id="petales_76_" d="M978.6 450.9h1.9v1.6h-1.9z" className="st141" />
                    <ellipse
                        id="feuille_78_"
                        cx="980.8"
                        cy="453.9"
                        className="st19"
                        rx="2.1"
                        ry=".5"
                        transform="rotate(-53.5 980.8 453.9)"
                    />
                    <ellipse
                        id="feuille_77_"
                        cx="978.4"
                        cy="454.8"
                        className="st19"
                        rx=".6"
                        ry="2.6"
                        transform="rotate(-25.2 978.4 454.9)"
                    />
                </g>
                <g id="fleur_37_">
                    <path id="tige_37_" d="M987 454h.3v5.9h-.3z" className="st7" />
                    <circle id="petales_75_" cx="987.1" cy="453.2" r="1.1" className="st33" />
                    <path id="petales_74_" d="M986 451.3h2.2v1.9H986z" className="st33" />
                    <ellipse
                        id="feuille_76_"
                        cx="988.6"
                        cy="454.8"
                        className="st11"
                        rx="2.5"
                        ry=".6"
                        transform="rotate(-53.5 988.6 454.7)"
                    />
                    <ellipse
                        id="feuille_75_"
                        cx="985.8"
                        cy="455.9"
                        className="st11"
                        rx=".7"
                        ry="3"
                        transform="rotate(-25.2 985.8 455.9)"
                    />
                </g>
                <g id="buisson_16_">
                    <path
                        d="M704.1 341.9c-2 4-6.9 5.6-10.9 3.6a8.2 8.2 0 01-4.3-5.8l.1.1c5.3 2.7 11.8.6 14.5-4.8l.4-.9a8.3 8.3 0 01.2 7.8z"
                        className="st11"
                    />
                    <path
                        d="M703.5 335a11 11 0 01-14.5 4.8l-.1-.1c-.3-1.7-.1-3.5.7-5.1a8.1 8.1 0 0114.3-.4l-.4.8z"
                        className="st11"
                    />
                    <path
                        d="M717.4 345.3a11 11 0 01-20.8-3.1l.2.1c7.3 3.7 16.2.8 19.8-6.4l.5-1.2a11 11 0 01.3 10.6z"
                        className="st19"
                    />
                    <path
                        d="M716.5 335.9a14.7 14.7 0 01-19.8 6.4l-.2-.1c-.4-2.3-.1-4.8 1-7a11 11 0 0114.9-4.8c2 1 3.6 2.5 4.6 4.3l-.5 1.2z"
                        className="st11"
                    />
                    <g>
                        <path
                            d="M725.1 351.7a9.5 9.5 0 01-12.7 4.2 9.4 9.4 0 01-5-6.7l.1.1c6.2 3.1 13.8.7 16.9-5.5l.5-1a9.3 9.3 0 01.2 8.9z"
                            className="st19"
                        />
                        <path
                            d="M724.4 343.7a12.5 12.5 0 01-16.9 5.5l-.1-.1a9.5 9.5 0 0113.6-10.2c1.7.9 3 2.2 3.9 3.7l-.5 1.1z"
                            className="st11"
                        />
                    </g>
                </g>
                <path
                    id="colline"
                    d="M0 813.3c.6 0 .3-566.2 0-624.3 292.9 15.6 512 52 842.1 220.5 73.2 37.4 186.3 64 314.8 77.8 12.6 1.3 4.5 36.4 35.5 37.3 30.1 1 26.2-31.8 38.1-31 243.1 16.2 522.3-11.7 689.4-96.4v416.1"
                    className="st75"
                />
            </g>
        </svg>
    );
}
