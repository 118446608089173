import React, { Component } from "react";
import SubmitCancelButtons from "../SubmitCancelButtons";
import TextareaAutosize from "react-autosize-textarea";

class EditableAuth extends Component {
    state = {
        //Hauteur du conteneur des bouttons submit et cancel
        buttons: false
    };

    // EVENTS //////////////////////////////////////////////////////////////////////////////////////////

    showButtons() {
        this.setState({ buttons: true });
    }

    hideButtons() {
        this.setState({ buttons: false });
    }

    // RENDER //////////////////////////////////////////////////////////////////////////////////////////

    render() {
        //La référence du tagname doit être en majuscules pour être traitée comme une variable
        const TagName = this.props.tagName;

        return (
            <TagName className={`EditableContainer ${this.props.className ? this.props.className : ""}`}>
                <TextareaAutosize
                    name={this.props.uid}
                    onChange={event => this.props.handleChange(event)}
                    onFocus={() => this.showButtons()}
                    placeholder="Champ vide"
                    value={this.props.currentText}
                    className=""
                ></TextareaAutosize>
                <SubmitCancelButtons
                    submit={() => {
                        this.hideButtons();
                        this.props.submit();
                    }}
                    cancel={() => {
                        this.hideButtons();
                        this.props.cancel();
                    }}
                    visible={this.state.buttons}
                />
            </TagName>
        );
    }
}

// EXPORT ET PROPTYPES //////////////////////////////////////////////////////////////////////////////////////////

export default EditableAuth;
