import * as app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
    apiKey: "AIzaSyDiJ20nhZEegjwwvYcVRlwv_ICpvZdiC84",
    authDomain: "le-moulin-des-desniers-63.firebaseapp.com",
    databaseURL: "https://le-moulin-des-desniers-63.firebaseio.com",
    projectId: "le-moulin-des-desniers-63",
    storageBucket: "le-moulin-des-desniers-63.appspot.com",
    messagingSenderId: "432676082535",
    appId: "1:432676082535:web:689affeeda43429c6c95c2",
    measurementId: "G-MF2TJ58VXF"
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = () => app.storage();
    }

    // *** Auth API ***
    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
        this.auth.signOut();
    };
}

export default Firebase;
