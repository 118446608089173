import React, { Component } from "react";
import { withFirebase } from "../Firebase";

import "./style.css";

class Loader extends Component {
    state = {
        loaderStyle: {
            opacity: 1
        },
        srcTest: ""
    };

    collectionRef = this.props.firebase.db
        .collection("images-tables")
        .doc("accueil")
        .collection("table");

    componentDidMount() {
        this.loadTestFile
            .then(downloadURL => {
                var img = new Image();
                img.onload = () => {
                    this.setState({
                        loaderStyle: {
                            opacity: 0,
                            display: "none",
                            width: "0px",
                            height: "0px"
                        }
                    });
                };
                img.src = downloadURL;
            })
            .catch(error => {
                console.log(error);
            });
    }

    loadTestFile = new Promise((resolve, reject) => {
        this.collectionRef
            .get()
            .then(async snapshot => {
                let downloadURL = null;

                //Creation du tableau d'images à partir de la base de données
                const image = snapshot.docs[0];

                if (image.exists) {
                    const data = image.data();

                    //Récupération de l'URL de download de la source de l'image

                    if (data.fileRef) {
                        await this.props.firebase.db
                            .collection("files")
                            .doc(data.fileRef)
                            .get()
                            .then(file => {
                                if (file.data()) {
                                    downloadURL = file.data().downloadURL;
                                } else {
                                    downloadURL = null;
                                    console.log("Erreur de chargement : données introuvables");
                                }
                            })
                            .catch(error => {
                                resolve(downloadURL);
                                console.log("Erreur de chargement : fichier introuvable");
                            });
                    }
                } else {
                    console.log("Erreur de chargement : image introuvable");
                    resolve(downloadURL);
                }

                resolve(downloadURL);
            })
            .catch(error => {
                resolve(null);
                console.log(`Impossible de trouver le tableau d'image ${this.props.uid} : ${error}`);
            });
    });

    render() {
        return (
            <div className="Loader" style={this.state.loaderStyle}>
                <p>Chargement...</p>
            </div>
        );
    }
}

export default withFirebase(Loader);
