import React, { Component } from "react";
import missingImage from "../../assets/illustrations/missingImage.jpg";
import move from "../../assets/icons/move.svg";
import crop from "../../assets/icons/crop.svg";
import remove from "../../assets/icons/bin.svg";
import picture from "../../assets/icons/picture.svg";
import BubbleButton from "../BubbleButton";
import "./style.css";

class FramedImageAuth extends Component {
	constructor(props) {
		super(props);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.crop = this.crop.bind(this);
		this.move = this.move.bind(this);
	}

	icons = {
		move: { backgroundImage: `url(${move})` },
		crop: { backgroundImage: `url(${crop})` },
		picture: { backgroundImage: `url(${picture})` },
		remove: { backgroundImage: `url(${remove})` },
	};

	state = {
		action: "none",
		frameRef: React.createRef(),
		imageRef: React.createRef(),
		width: 0,
		height: 0,
		mouseClick: {
			//La position du composant au moment du clic de la souris
			left: undefined,
			top: undefined,
			//La position du clic de la souris
			x: undefined,
			y: undefined,
			//L'offset au moment du clic de la souris
			offsetX: undefined,
			offsetY: undefined,
		},
	};

	componentDidMount() {
		this.props.sizing();
		this.props.getAuthUser(this.props.authUser);
	}

	// HANDLE MOUSE EVENTS ///////////////////////////////////////////////////////////////////////

	handleMouseDown(e) {
		if (this.state.action === "crop" || this.state.action === "move") {
			if (this.state.action === "crop") {
				window.addEventListener("mousemove", this.crop);
				window.addEventListener("mouseup", this.handleMouseUp);
			}

			if (this.state.action === "move") {
				window.addEventListener("mousemove", this.move);
				window.addEventListener("mouseup", this.handleMouseUp);
			}

			this.setState({
				mouseClick: {
					left: this.props.frameReactRef.current.getBoundingClientRect().left,
					top: this.props.frameReactRef.current.getBoundingClientRect().top,
					x: e.pageX,
					y: e.pageY,
					offsetX: this.props.offsetX,
					offsetY: this.props.offsetY,
				},
			});

			this.props.changesInProgress(true);
		}
	}

	move(e) {
		const mouse = {
			x: e.pageX,
			y: e.pageY,
		};

		//L'écart dû à un changement de position
		const deltaLeft = this.props.frameReactRef.current.getBoundingClientRect().left - this.state.mouseClick.left;
		const deltaTop = this.props.frameReactRef.current.getBoundingClientRect().top - this.state.mouseClick.top;

		const left = mouse.x - this.state.mouseClick.x - deltaLeft;
		const top = mouse.y - this.state.mouseClick.y - deltaTop;

		this.props.changePosition(this.props.indexInTable, left, top);
	}

	crop(e) {
		let offsetX = this.state.mouseClick.offsetX || 0;
		let offsetY = this.state.mouseClick.offsetY || 0;

		const frameRef = this.props.frameReactRef.current;
		const imageRef = this.props.imageReactRef.current;

		const frameRect = frameRef.getBoundingClientRect();
		const imageRect = imageRef.getBoundingClientRect();

		const image = {
			width: imageRect.width,
			height: imageRect.height,
			ratio: imageRect.width / imageRect.height,
			x: imageRect.left,
			y: imageRect.top + window.scrollY,
		};

		const frame = {
			width: frameRect.width,
			height: frameRect.height,
			ratio: frameRect.width / frameRect.height,
			x: frameRect.left,
			y: frameRect.top + window.scrollY,
		};

		const mouse = {
			x: e.pageX,
			y: e.pageY,
		};

		if (frame.ratio > image.ratio) {
			//Deplacement horizontal
			const newOffsetY = offsetY + (mouse.y - this.state.mouseClick.y);

			if (newOffsetY <= 0 && newOffsetY >= -(image.height - frame.height)) {
				this.props.changeOffsets(this.props.offsetX, newOffsetY);
			}
		} else {
			//Deplacement horizontal
			const newOffsetX = offsetX + (mouse.x - this.state.mouseClick.x);

			if (newOffsetX <= 0 && newOffsetX >= -(image.width - frame.width)) {
				this.props.changeOffsets(newOffsetX, this.props.offsetY);
			}
		}
	}

	handleMouseUp() {
		if (this.state.action === "crop") {
			window.removeEventListener("mousemove", this.crop);
			window.removeEventListener("mouseup", this.handleMouseUp);
		}

		if (this.state.action === "move") {
			window.removeEventListener("mousemove", this.move);
			window.removeEventListener("mouseup", this.handleMouseUp);
			this.props.changePosition(this.props.indexInTable, 0, 0);
		}
	}

	// RENDER ///////////////////////////////////////////////////////////////////////

	render() {
		const action = this.props.isDoingAnAction === this.props.indexInTable ? this.state.action : "none";
		//Si une aciton était en cours mais qu'une autre framedImage a commencé une aciton
		//On quitte l'action sur cette framedImage
		return (
			<div
				className={`Frame ${action}`}
				ref={this.props.frameReactRef}
				style={{ order: this.props.order }}
				draggable={false}
			>
				<div
					ref={this.props.framedImageReactRef}
					className={`FramedImage`}
					onMouseDown={this.handleMouseDown.bind(this)}
					style={{ left: this.props.left, top: this.props.top }}
					draggable={false}
				>
					{action === "none" && !this.props.isUploading && (
						<div className="FramedImageButtonsContainer" draggable={false}>
							<div draggable={false}>
								<button
									style={this.icons.picture}
									className="FramedImageButton"
									onClick={() => this.props.uploading()}
								/>
								<button
									style={this.icons.crop}
									onClick={() => {
										this.setState({ action: "crop" });
										this.props.doingAnAction();
									}}
									className="FramedImageButton"
								/>
							</div>
							{(this.props.canMove || this.props.canRemove) && (
								<div draggable={false}>
									{this.props.canMove && (
										<button
											style={this.icons.move}
											onClick={() => {
												this.setState({ action: "move" });
												this.props.doingAnAction();
											}}
											className="FramedImageButton"
										/>
									)}
									{this.props.canRemove && (
										<button
											style={this.icons.remove}
											className="FramedImageButton"
											onClick={() => this.props.removeFrame()}
										/>
									)}
								</div>
							)}
						</div>
					)}

					<div className="SecondFramedImageButtonsContainer">
						<BubbleButton className="back" onClick={() => this.setState({ action: "none" })}>
							Retour
						</BubbleButton>
					</div>

					<img
						onLoad={this.props.sizing}
						style={{
							left: `${this.props.offsetX}px`,
							top: `${this.props.offsetY}px`,
							width: this.props.width,
							height: this.props.height,
						}}
						ref={this.props.imageReactRef}
						src={this.props.downloadURL ? this.props.downloadURL : missingImage}
						alt="moulin"
						draggable={false}
					/>
				</div>
			</div>
		);
	}
}

export default FramedImageAuth;
