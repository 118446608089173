import React, { Component } from "react";
import missingImage from "../../assets/illustrations/missingImage.jpg";
import "./style.css";

class FramedImage extends Component {
    state = {
        style: {
            flexBasis: this.props.width
        }
    };

    render() {
        return (
            <div className="FramedImage" style={this.state.style}>
                <img
                    src={this.props.ref ? this.props.ref : missingImage}
                    alt="moulin"
                />
            </div>
        );
    }
}

export default FramedImage;
