import React from "react";
import BubbleButton from "../BubbleButton";
import "./style.css";

function SubmitCancelButtons({ submit, cancel, visible }) {
	return (
		<span className={`submitCancelButtons ${visible ? "displayed" : "hidden"}`}>
			<BubbleButton onClick={() => submit()} className="submitButton">
				Enregistrer
			</BubbleButton>
			<BubbleButton onClick={() => cancel()} className="cancelButton">
				Annuler
			</BubbleButton>
		</span>
	);
}

export default SubmitCancelButtons;
